#quali-header
  background: $grey
  .headerImage
    background: url(../img/headerQuali.jpg) center no-repeat
    background-size: cover

  .headerText
    max-width: 750px
    margin: 30px auto
    text-align: center
    padding: 30px


.quali-gleitsicht
  margin-top: 30px
  padding: 20px
  .content
    max-width: 750px
    margin: 0 auto
    text-align: center
.soft-row
  display: flex
  justify-content: center
  flex-wrap: wrap
  max-width: 850px
  margin: 0 auto

  .gleitsichtGlas
    text-align: center
    max-width: 190px
    min-width: 150px
    width: 40%
    margin: 10px
    img
      max-width: 100%
    h4
      margin-top: -15px
      line-height: 1.4
.gl-logos
  margin: 60px auto  
  align-items: center
  max-width: 750px
  div
    width: 30%
    text-align: center
    margin: 10px
    min-width: 200px
    &:nth-child(1)
      img
        width: 167px
        height: 33px


#gl-tool
  margin: 0 auto
  max-width: 1000px
  height: 150vw
  position: relative
  max-height: 900px
  iframe
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
 