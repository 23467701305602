

.datenschutz {
  .app-content {  padding: 50px 10%!important;
    h1 {
      font-family: 'Soho W02 Bold'!important;
      font-size: 2em!important;
      margin-bottom: 20px;
    }
    h2 {
      font-family: 'Soho W02 Bold'!important;
      font-size: 1.6em!important;
      margin-bottom: 20px;
    }
    h3 {
      font-family: 'Soho Gothic W02 Bold'!important;
      font-size: 1.2em!important;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
    }
    li {
      list-style: disc;
      margin-left: 20px;
      margin-bottom: 10px;
    }
    a {
      color: black;
    }}



}

#webhint {
 display:none
}
.cc_banner-wrapper {
 z-index: 999999999999999!important;
 .cc_container {
     background: #000;
     color: #fff;
     font-family: 'Ubuntu', sans-serif;
     -webkit-box-sizing: border-box;
             box-sizing: border-box;
     font-size: 14px;
     line-height: 1.4!important;
     .cc_btn {
       background-color: #F5821F;
-webkit-transition: background 200ms ease-in-out,color 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
transition: background 200ms ease-in-out,color 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
-webkit-transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
border-radius: 5px;
-webkit-border-radius: 5px;
color: white;
max-width: 140px;
     }
 }
}

#datenschutz {
  padding: 40px;
  max-width: 1000px;
  margin: 0 auto;

    h3 {
      line-height: 1.4!important;
    }
  
}
