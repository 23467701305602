.header
	position: sticky
	width: 100%
	top: 0
	background: #333
	z-index: 9999999999999999999999999999999999999
	height: 60px
	@media (min-width: 1400px)
		// height: 80px
	box-shadow: 0 0 10px rgba(black, 0.3)

	.header-inner
		height: 100%
		max-width: 1600px
		margin: 0 auto
		padding: 0 20px
		display: flex
		flex-wrap: nowrap
		align-items: center
		justify-content: space-between

		#logo
			line-height: 1
			height: 24px

			svg
				height: 24px
				width: auto

		.burger-menu
			display: none
			width: 36px
			height: 36px
			cursor: pointer
			border-radius: 50%
			border: 2px solid white
			position: relative
			transition: all 300ms ease

			&:hover
				border: 2px solid $a-color

				#burger-bar
					background: $a-color

			#burger-bar
				width: 20px
				height: 2px
				background: white
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%, -50%)
				transition: all 300ms ease

				&:before
					content: ""
					width: 20px
					height: 2px
					background: inherit
					position: absolute
					top: -6px
					transition: all 300ms ease

				&:after
					content: ""
					width: 20px
					height: 2px
					background: inherit
					position: absolute
					top: 6px
					transition: all 300ms ease

		.burger-menu.active
			#burger-bar
				width: 0
				left: 6px

				&:before
					top: 0
					transform: rotate(45deg)

				&:after
					top: 0
					transform: rotate(-45deg)


		.menu
			z-index: 99
			margin: 0
			padding: 0
			height: 100%

			li
				font-size: 15px
				list-style-type: none
				position: relative
				height: 60px
				// @media (min-width: 1400px)
				// 	height: 80px
				display: flex
				align-items: center

				*
					font-size: 15px

				&.has-submenu
					> a
						@media (min-width: 1401px)
							&:after
								content: ''
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.256' height='7.128' viewBox='0 0 12.256 7.128'%3E%3Cpath id='Pfad_50672' data-name='Pfad 50672' d='M277.75,85.653l4.714,4.714,4.714-4.714' transform='translate(-276.335 -84.239)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A")
								width: 12px
								height: 7px
								display: inline-block
								position: relative
								background-size: cover
								background-repeat: no-repeat
								top: -2px
								margin-left: 5px

				&:hover
					@media (min-width: 1401px)
						.sub
							background: $theme-color
							color: white
							cursor: pointer
							pointer-events: all
						.submenu
							display: block
							z-index: 9
							background: $theme-color
							padding: 0

				li
					padding: 0

					a
						display: block
						padding: 0 30px
						color: #333

						&:hover
							background: lighten( $theme-color, 10% )
							color: #ffffff

		.mobile-menu
			display: none

		.menu
			// position: absolute
			right: 0
			display: flex

		li
			list-style-type: none
			height: 100%
			position: relative

			.submenu-toggle
				display: none

			a
				display: block
				font-family: 'Open Sans', sans-serif
				color: white
				padding: 0 12px
				line-height: 60px
				@media (min-width: 1400px)
					font-size: 18px
					line-height: 60px
				height: 100%

				text-decoration: none
				text-transform: uppercase
				transition: all 300ms ease

				&#menuShop
					@media (min-width: 1400px)
						margin-left: 20px
						background-color: $theme-color
						font-weight: 700
						padding: 0 21px

				&:hover
					background: $theme-color
					color: #ffffff

			.active
				background: $theme-color !important
				color: #ffffff


			.submenu
				top: 60px
				// @media (min-width: 1400px)
				// 	top: 80px
				@media (min-width: 1401px)
					position: absolute
					left: 0
					display: none
					background: $theme-color
					box-shadow: 0 0 10px rgba(black, 0.3)
					li
						height: auto

						a
							text-align: left
							width: 100%
							color: #ffffff
							line-height: 1
							padding: 10px 20px
							text-transform: none

							&.active
								// color: #000000
								font-weight: 600
								background: darken( $theme-color, 5%)!important

					&:hover
						display: block
						z-index: 9
						height: auto

@media (max-width: 1400px)
	.header
		.header-inner
			.burger-menu
				display: block

			.menu
				display: none

			.menu.active
				box-shadow: 0 0 10px rgba(black, 0.3)
				display: flex
				flex-direction: column
				width: 300px
				height: calc(100vh - 60px)
				max-height: calc(100vh - 60px)
				overflow-y: auto
				position: absolute
				background: white
				right: 0
				top: 60px

				li
					height: auto
					flex-direction: column

					.submenu
						display: none

					&.has-submenu
						position: relative

						.submenu-toggle
							display: flex
							align-items: center
							justify-content: center
							position: absolute
							width: 40px
							height: 40px
							cursor: pointer
							min-height: 20px
							background: none
							border: none
							margin: 0
							padding: 0
							right: 10px
							top: 10px
							border-left: 1px solid rgba(black, 0.15)
							border-radius: 0

							img
								display: block
								width: 10px

						&.open
							.submenu-toggle
								img
									transform: rotate(90deg)

							.submenu
								display: block
								width: 100%
								background: #efefef

					a
						color: $dark-grey

						&:hover
							color: white
					a
						width: 100%
						border-bottom: 1px solid rgba(black, 0.1)

@media (min-width: 768px)
	#sticky
		display: flex
		flex-direction: column

		.sticky
			position: fixed
			top: 100px
			z-index: 99
			right: 0
			border-radius: 7px 0 0 7px
			transition: all 300ms ease

			&:first-child
				top: 100px

			&:nth-child(2)
				top: 170px

			&:nth-child(3)
				top: 240px

		a
			background: $theme-color
			padding: 10px
			margin: 10px 0
			// margin-right: -100px
			width: 150px
			display: flex
			justify-content: center
			align-items: center

			&:hover
				margin-right: 0

		i
			font-size: 2rem
			color: white

			&.fa-calendar-alt
				padding: 0 5px

		span
			color: white
			padding-left: 10px
			text-align: center

@media (max-width: 767px)
	#sticky
		display: flex
		position: fixed
		bottom: 0
		z-index: 99
		align-items: center
		justify-content: center
		width: 100%

		.sticky
			border-radius: 7px 7px 0 0
			transition: all 300ms ease
			margin: 0 5px

		a
			background: #F5821F
			padding: 10px
			margin: 10px 0

			display: flex
			justify-content: center
			align-items: center

		i
			font-size: 2rem
			color: white

			&.fa-calendar-alt
				padding: 0 5px

		span
			display: none

.sternegeben
	#sticky
		.sticky
			background: $dark-grey !important