.slick-dots
	bottom: 45%
	left: 0
	li
		display: block
		margin-bottom: 10px
		button:before
			font-size: 10px

	
	
.content, section
	max-width: 2400px
	margin: 0 auto
#homeslider
	width: 100%
	max-width: 2400px
	margin: 0 auto
	.slick-dots
		bottom: 45%
		left: 0
		li
			display: block
			margin-bottom: 10px
			button:before
				font-size: 10px
	.short-cuts
		a
			transition: all 300ms ease
			width: 130px
			height: 130px
			position: relative
			display: block
			top: 0
			&:hover
				top: -10px
		@media (max-width: 768px)
			display: none
	.main-slider
		position: relative
		width: 100%
		height: 50vw
		max-height: 850px
		overflow: hidden
		.slideHome
			position: absolute
			width: 100%
			height: 100%
			left: 0
			top: 0

			img
				position: absolute
				width: 100%
				left: 50%
				top: 0
				transform: translate(-50%)
				height: 100%
				object-fit: cover
				object-position: center 35%
		.subtitle
			color: white
			display: block
			background: #333
			margin: 0!important
			padding: 10px
			text-align: center
			font-size: 0.825em
			position: absolute
			bottom: 0
			width: 100%
		@media (max-width: 650px)
			.subtitle
				display: none
			.slick-dots
				bottom: 26%

				