
.modal
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: rgba(0,0,0,0.7)
    z-index: 9999999999
    padding: 30px
    overflow-y: auto
    display: none   
    flex-direction: column
    justify-content: center
    &.active
        display: flex
    &--content
        width: 100%
        display: block
        margin: 0 auto
        max-width: 1000px
        background: white
        position: relative
        padding: 40px
        overflow-y: auto
        ul 
            margin-left: 15px
    .lupe
        position: absolute
        width: 40px
        height: 40px
        top: 0
        right: 0
        cursor: pointer
        padding: 10px
        background: #f5811f
        transition: all 100ms ease
        &:hover
            padding: 7px

      