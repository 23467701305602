.brillen
  #brillen, #sonnenbrillen
    margin-bottom: 60px
  #brillen
    border-bottom: 1px solid #ccc
  h2
    margin-bottom: 30px
  .slick-slide
    img
      max-width: 400px
      margin: 30px auto
  .modal
    .slick-slide
      img
        max-width: 90%

  #markenlogos
    display: flex
    align-items: center
    // justify-content: center
    // flex-wrap: wrap
    // div
    //   width: 120px
    //   height: 60px
    //   margin: 10px 15px
    // @media (max-width: 600px)
    //   div
    //     width: 80px
    //     height: 40px
    //     margin: 10px
    //   @for $i from 17 through 30
    //     .logo-#{$i}
    //     //   display: none
    .markenlogo
        min-width: 10px
            
    .slick-arrow
        margin: 0!important
    .slick-list
        position: relative
        &:before
            content: ""
            position: absolute
            left: 0
            top: 0
            width: 50px
            height: 100%
            background: linear-gradient(to right, white, transparent)
            z-index: 1
        &:after
            content: ""
            position: absolute
            right: 0
            top: 0
            width: 50px
            height: 100%
            background: linear-gradient(to right, transparent, white)
            z-index: 1

 
@for $i from 1 through 30
  .logo-#{$i}
    background: url(../img/logos/logo-#{$i}.png) no-repeat center
    background-size: contain

  