.toggle
  background: white
  padding: 20px
  .soft-row
    display: flex
    justify-content: center
    flex-wrap: wrap
    max-width: 650px
    margin: 0 auto
    dl
      border: 1px solid $grey
      margin-bottom: 20px
      dt
        font-weight: bold
        font-size: 1.2em
        padding: 15px 20px
        background: $grey
        position: relative
        text-transform: uppercase
        cursor: pointer
        transition: all 300ms ease
        &:hover
          background: darken($grey, 4%)
        &:after
          content: "+"
          position: absolute
          width: 30px
          height: 30px
          background: $button-orange
          right: 20px
          top: 50%
          transform: translateY(-50%)
          color: white
          border-radius: 50%
          text-align: center
          line-height: 30px
          font-weight: 300
          font-size: 1.4em
      dd
        height: 0
        overflow: hidden
        transition: all 300ms ease
        padding: 0px 20px
        ul 
          margin-left: 20px
        
      &.toggleActive
        transition: all 300ms ease
        dt
          background: white
          &:after
            content: "–"
            line-height: 26px
        dd
          height: 100%
          padding: 10px 20px