#online-tools
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	gap: 0 50px

	.tool
		flex: 1 0 0
		margin: 10px
		min-width: 168px
		display: block
		@media(max-width: 1017px)
			min-width: 160px
		@media(max-width: 975px)
			min-width: 220px

		a.inner
			color: $body-font-color
			transition: all 300ms ease

			&:hover
				img
					top: -10px

			h4
				margin-top: 20px
				font-family: 'Open Sans', sans-serif
				font-weight: 600
				font-size: 18px
				line-height: 28px
				color: #F5821F
				margin-bottom: 20px

			p
				margin: 10px 0 0 0 !important
				font-family: 'Open Sans', sans-serif
				font-size: 14px

			img
				width: 206px
				border: 1px solid grey
				border-radius: 50%
				position: relative
				top: 0
				transition: all 300ms ease

#terminvereinbarung
	text-align: center

	.section-inner
		padding: 60px 20px 0 20px !important

	iframe
		margin: 0 auto
		width: 100%
		max-width: 1000px
		height: 900px
		margin-bottom: 30px

.spacer
	height: 50px

#wett-sehtest
	text-align: center

	iframe
		margin-bottom: 30px

	.section-inner
		padding: 60px 20px 0 20px !important
// @media (max-width: 768px)
//	 #wett-sehtest
//		 display: none

#glas-simulator
	text-align: center

	iframe
		margin-bottom: 30px

	.section-inner
		padding: 60px 20px 0 20px !important

#glasdicken-simulator
	text-align: center

	iframe
		margin-bottom: 30px

	.section-inner
		padding: 60px 20px 0 20px !important

#toenungs-simulator

	text-align: center

	iframe
		margin: 0 0 30px 0
		width: 100%
		background: white
		min-height: 800px

	.section-inner
		padding: 60px 20px 0 20px !important

#brille-oder-linse

	text-align: center

	iframe
		margin-bottom: 30px
	// max-width: 1000px

	.section-inner
		padding: 60px 20px 0 20px !important

.online-tool--section
	padding: 40px 15px
	text-align: center

	iframe, #kl-app
		width: 100%
		max-width: 1200px
		margin: 0 auto

	#kl-app
		max-width: 1200px

	&:nth-child(odd)
		background: #efefef
		