.bg {
  background: transparent url("../img/online-tools/linse/bg.jpg") 0 0 / cover no-repeat;
}

#kl-app {
	max-width: 1200px;
  #kl-people {
    background-image: url('../img/online-tools/linse/img-startseite.png');
    width: 100%;
  }
  margin: 0 auto 30px auto;
  overflow: hidden;
  padding: 0 1.5625rem;
  padding: 0;
  ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
  }
  /* change colours to suit your needs */
  mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
  }
  del {
    text-decoration: line-through;
  }
  abbr[title],
  dfn[title] {
    border-bottom: 0.0625rem dotted;
    cursor: help;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* change border colour to suit your needs */
  hr {
    display: block;
    height: 0.0625rem;
    border: 0;
    border-top: 0.0625rem solid #cccccc;
    margin: 1em 0;
    padding: 0;
  }
  input,
  select {
    vertical-align: middle;
  }
  /* END RESET */
  /* soft Grid */
  * {
    box-sizing: border-box;
  }
  .soft-main-wrapper {
    width: 100%;
    max-width: 65.250rem;
    margin: 0 auto;
    padding: 0 0.625rem;
  }
  @media screen and (max-width: 65.25em) {
    .soft-main-wrapper {
      padding: 0 1.250rem;
    }
  }
  .soft-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-flex-wrap: nowrap; -ms-flex-wrap: wrap; flex-wrap: wrap; */
    // margin-left: -1.5625rem;
    // margin-right: -1.5625rem;
	max-width: 100%;
  }
  .soft-row .soft-row:first-of-type {
    margin-top: -1.5625rem;
  }
  .soft-row .soft-row:last-of-type {
    margin-bottom: -1.5625rem;
  }
  /*Items*/
  [class*="soft-col"] {
    flex: 1 0 auto;
    padding: 1.5625rem;
  }
  .soft-col-1 {
    width: 10%;
  }
  .soft-col-2 {
    width: 20%;
  }
  .soft-col-3 {
    width: 25%;
  }
  .soft-col-4 {
    width: 30%;
  }
  .soft-col-5 {
    width: 33.333333333%;
  }
  .soft-col-6 {
    width: 40%;
  }
  .soft-col-7 {
    width: 50%;
  }
  .soft-col-8 {
    width: 60%;
  }
  .soft-col-9 {
    width: 66.666666666%;
  }
  .soft-col-10 {
    width: 70%;
  }
  .soft-col-11 {
    width: 75%;
  }
  .soft-col-12 {
    width: 80%;
  }
  .soft-col-13 {
    width: 90%;
  }
  .soft-col-14 {
    width: 100%;
  }
  h1,
  h5,
  button {
    font-weight: bold;
  }
  h1 {
    font-size: 3.2rem;
    line-height: 1.2;
  }
  h2 {
    font-size: 2.25rem;
    line-height: 1.4;
  }
  h3 {
    font-size: 1.625rem;
    line-height: 1.4;
  }
  h5,
  h6,
  button,
  label {
    font-size: 1.25rem;
  }
  p {
    color: #5d5d5d;
    // font-size: 0.875rem;
    line-height: 1.4;
  }
  label {
    color: #5d5d5d;
  }
  h1,
  h2,
  h3 {
    color: $h1-color;
  }
  hr {
    border-top-color: #5d5d5d;
    margin: 0.5em 0;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .hidden {
    display: none;
  }
  .img-wrapper {
    overflow: hidden;
    height: 37.5rem;
  }
  .q-img {
    margin: -1.5625rem;
    margin-left: 0;
  }
  .q-img div {
    height: 37.5rem;
    background-position: center;
    background-repeat: no-repeat;
  }
  /*.q-img img { position: relative; left: -23.75rem; max-width: none;}*/
  .start>.soft-row {
    flex-wrap: wrap;
    max-width: 1200px!important;
    margin: 0 auto!important;
  }
  .start .img-wrapper {
    width: 40.75rem;
  }
  .start .q-img {
    margin: -1.5625rem;
  }
  .start .q-img div {
    background-size: contain;
    background-position: bottom right;
  }
  @media screen and (max-width: 81.875em) {
    .start .q-img div {
      background-size: contain;
      background-position: right;
    }
  }
  @media screen and (max-width: 40.75em) {
    .start .img-wrapper {
      width: 100%;
      height: 22.5rem !important;
    }
    .start .soft-row .img-wrapper {
      height: 22.5rem !important;
    }
    .q-img div {
      height: 22.5rem !important;
    }
  }
  .start p {
    font-size: 1.625rem;
    line-height: 2.25rem;
    padding: 0.9375rem 0;
  }
  @media screen and (max-width: 28.125em) {
    html,
    body {
      font-size: 12px;
    }
    h1 {
      font-size: 2em
    }
    h2 {
      font-size: 1.4em
    }
    
  }
  .btn {
    padding: 0.3125rem 0.625rem;
    cursor: pointer;
    margin: 0.3125rem 0;
  }
  .btn.blue {
    border: 0.0625rem solid $h1-color;
    background-color: $h1-color;
    color: #ffffff;
  }
  .btn.white {
    border: 0.0625rem solid $h1-color;
    background-color: #ffffff;
    color: $h1-color;
  }
  .btn.prev {
    margin-right: 0.625rem;
  }
  .btn:hover,
  .btn.active {
    border-color: #6BA7DB;
    background-color: #6BA7DB;
    color: #ffffff;
  }
  .nav-wrapper [class*="soft-col"] {
    position: relative;
  }
  .nav-wrapper .nav {
    position: absolute;
    bottom: 3.125rem;
    left: 1.5625rem;
  }
  @media screen and (max-width: 47.5em) {
    .img-wrapper {
      display: none;
    }
    .start .img-wrapper {
      display: block;
    }
    .nav-wrapper .nav {
      position: static;
      padding-top: 0.9375rem;
    }
  }
  .question-wrapper {
	> .col-8 {
		padding: 1.5625rem
	}
  }
  .question h2 {
    font-size: 1.25rem;
    line-height: 1;
    font-weight: normal;
  }
  /*.q-img { background-image: url(../img/frage1.jpg); }*/
  .answer,
  h3 {
    padding: 1.25rem 0;
  }
  /* Radio buttons */
  .checkbox input[type=radio] {
    display: none;
  }
  .checkbox label {
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: 35px;
  }
  .checkbox label:before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    left: 0;
    top: 0;
    border: 0.125rem solid $h1-color;
  }
  input[type=radio]:checked+label:after {
    content: "";
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    margin-right: 0.625rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $h1-color;
  }
  /* ENDE Radio buttons*/
  /* Ergebnis */
  .result-wrapper {
    position: relative;
    margin: 2.5rem 0;
  }
  .result-wrapper .bar {
    position: relative;
    width: 100%;
    height: 1.25rem;
    background-image: linear-gradient(90deg, $h1-color 5%, #aadee5 30%, #5dd6ac 95%);
  }
  .result-wrapper .marker {
    position: absolute;
    transform: translate(0, -115%);
  }
  .result-wrapper .m50 {
    left: 50%;
    transform: translate(-50%, -115%);
  }
  .result-wrapper .m100 {
    right: 0;
  }
  .result-wrapper .marker p {
    color: $h1-color;
  }
  .result-wrapper .tile {
    position: absolute;
    width: 3.125rem;
    height: 3.75rem;
    top: -0.375rem;
    left: 50%;
    border: 0.375rem solid $h1-color;
    border-radius: 0.375rem;
    transform: translateX(-50%);
  }
  .result-wrapper .tile .sub {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 1.25rem);
    bottom: 0;
    left: 0;
    background-color: $h1-color;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
  }
  .result-wrapper .tile .sub::after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: $h1-color;
    border-width: 0.375rem;
    margin-left: -0.375rem;
  }
  .result-wrapper .tile .sub p {
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin: 0 !important
  }
  /* Ende Ergebnis */
  .lastrow {
    justify-content: space-between;
  }
}