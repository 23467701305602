@import 'includes/vars'
@import 'includes/fontimport'
@import 'includes/fonts'
@import 'includes/button'
@import 'includes/menu'
@import 'includes/homeslider'
@import 'includes/slick'
@import 'includes/slick_base'
@import 'includes/brillen'
@import 'includes/brands'
@import 'includes/news'
@import 'includes/tools'
@import 'includes/brille-linse'
@import 'includes/footer'
@import 'includes/impressum'
@import 'includes/datenschutz'
@import 'includes/locations'
@import 'includes/forsa'
@import 'includes/service'
@import 'includes/kontaktlinsen'
@import 'includes/gleitsicht'
@import 'includes/qualitaet'
@import 'includes/zitat'
@import 'includes/BU'
@import 'includes/mitarbeiter'
@import 'includes/standort'
@import 'includes/sonnenbrillen'
@import 'includes/sternegeben'
@import 'includes/arbeitsplatzbrillen'
@import 'includes/toggle'
@import 'includes/sehtraining'
@import 'includes/hoerakustik'
@import 'includes/modal-corona'
@import 'includes/3Dview'
@import 'includes/cookie'
@import 'includes/miyosmart'
@import 'includes/marken'
@import 'includes/index'
@import 'includes/parallax'

.small--heading
	font-family: 'Montserrat', sans-serif


#gluecklich
	background: $dark-grey url('../img/bg_home.jpg')
	background-size: cover
	background-attachment: fixed
	background-position-x: right
	//background-position-y: -100px
	position: relative
	@media (max-width: 1000px)
		background: $dark-grey

	h1, p
		color: white

	p
		max-width: 750px
		margin: 20px auto !important

	.slick-prev:before, .slick-next:before
		color: white

*
	box-sizing: border-box
	font-size: 14px
	outline: none
	padding: 0
	margin: 0

a
	text-decoration: none


body
	padding: 0 0 0 0
	margin: 0 0 0 0
	font-family: $body-font-family
	font-size: $body-font-size
	color: $body-font-color
	font-weight: $light
	line-height: 23px


img
	width: 100%

.textCenter
	max-width: 750px
	text-align: center
	margin: 0 auto
	padding: 30px


.greifswald-intro
	margin-top: 57px
	width: 100%
	position: relative
	height: 550px
	background: url("../partials/greifswald/img/header.jpg") center top 40%
	background-size: cover


.greifswald-team
	background-color: #EFEFEF
	text-align: center

	h2
		font-size: 2.2em

	h5
		font-size: 2.2em

.team-slider-greifswald
	font-size: 1.5em
	line-height: 1.5


.orange
	background: $button-orange

.grey
	background: $button-grey


.home-slider
	margin-top: 57px
	border-bottom: 10px solid #F5821F
	position: relative

	.slick-dotted.slick-slider
		margin-bottom: 0


@media (min-width: 1000px)
	.home-slider
		border-bottom: 10px solid #F5821F
		position: relative

		.slick-dotted.slick-slider
			margin-bottom: 0

		.slick-dots
			bottom: 25px

		.slick-slide img
			width: 100%

	.short-cuts
		display: none


@media only screen and (min-width: 768px)
	.short-cuts
		display: block
		position: absolute
		bottom: 10%
		width: 100%
		z-index: 9
	.short-cuts-inner
		display: flex
		align-items: center
		justify-content: center

	.shortcut
		padding: 2%


.brillen-slider
	max-width: 600px
	width: 100%
	margin: 0 auto


.team-slider
	max-width: 600px
	width: 100%
	margin: 0 auto


.center
	text-align: center


.short-cuts-inner
	max-width: 770px
	width: 100%
	margin: 0 auto


.section-grey
	background: $grey
	overflow: hidden


.section-inner
	max-width: $content-width
	margin: 0 auto
	padding: 6vw 30px
	width: 100%

	.logo
		width: 150px !important
		margin-top: 30px

	.location-inner
		max-width: 1200px
		margin: 0 auto
		width: 100%

.frank_borstel
	text-align: right !important
	right: 60px
	position: absolute
	bottom: 30px
	@media(max-width: 1000px)
		display: none

.stralsund-intro
	margin-top: 57px
	width: 100%
	position: relative
	height: 550px
	background: url("../partials/stralsund/img/header.jpg") center top 40%
	background-size: cover


.stralsund-location
	position: relative
	height: 470px
	margin-top: -12px


.stralsund-team
	background-color: #EFEFEF
	text-align: center

	h2
		margin-top: -20px
		font-size: 2.2em

	h5
		font-size: 2.2em

.team-slider-stralsund
	font-size: 1.5em
	line-height: 1.5


	h2
		margin-top: -20px
		font-size: 2.2em

	h5
		font-size: 2.2em


.grimmen-intro
	margin-top: 57px
	width: 100%
	position: relative
	height: 550px
	background: url("../partials/grimmen/img/header.jpg") center top 40%
	background-size: cover


.grimmen-location
	position: relative
	height: 470px
	margin-top: -12px


.grimmen-team
	background-color: #EFEFEF
	text-align: center

	h2
		margin-top: -20px
		font-size: 2.2em

	h5
		font-size: 2.2em

.team-slider-grimmen
	font-size: 1.5em
	line-height: 1.5


.news-text

	padding-top: 100px
	width: 35%
	margin: 0 auto


.service-intro
	margin-top: 57px
	width: 100%
	position: relative
	height: 550px
	background: url("../partials/service/img/Service_D05506-360.jpg") center center
	background-size: cover


.service-info
	background-color: #EFEFEF
	text-align: center


.service-icons1
	display: flex
	flex-wrap: wrap
	align-content: space-around
	height: 250px
	margin-top: 100px

	.one
		width: 28%
		text-align: center

		.icon-one
			position: relative
			width: 150px
			height: 150px
			background: url("../partials/service/img/icon1.png")
			background-size: cover
			margin: 0 auto

	.two
		width: 28%
		text-align: center

		.icon-two
			position: relative
			width: 150px
			height: 150px
			background: url("../partials/service/img/icon2.png")
			background-size: cover
			margin: 0 auto

	.three
		width: 28%
		text-align: center

		.icon-three
			position: relative
			width: 150px
			height: 150px
			background: url("../partials/service/img/icon3.png")
			background-size: cover
			margin: 0 auto

.service-icons2
	display: flex
	flex-wrap: wrap
	align-content: space-around
	height: 250px
	margin-top: 100px

	.four
		width: 28%
		text-align: center

		.icon-four
			position: relative
			width: 150px
			height: 150px
			background: url("../partials/service/img/icon4.png")
			background-size: cover
			margin: 0 auto

	.five
		width: 28%
		text-align: center

		.icon-five
			position: relative
			width: 150px
			height: 150px
			background: url("../partials/service/img/icon5.png")
			background-size: cover
			margin: 0 auto

	.six
		width: 28%
		text-align: center

		.icon-six
			position: relative
			width: 150px
			height: 150px
			background: url("../partials/service/img/icon6.png")
			background-size: cover
			margin: 0 auto


.people .section-inner
	width: 100%
	max-width: 100%
	margin: 0 auto
	padding: 0 0
	line-height: 0


/* --------------- IMAGE POPUP -----------------*/


.slider-nav img
	max-width: 100%

.slider-for img
	width: 100%


.slider-nav slick-slide:hover
	cursor: pointer


.slick-prev, .slick-next
	position: absolute
	top: 50%
	margin-top: -30px
	background-color: transparent
	border: none
	padding: 0
	font-size: 0

	&:before
		background-repeat: no-repeat
		background-size: 100% auto
		content: ""
		display: block
		height: 60px
		opacity: 1
		width: 30px

	&:hover:before
		opacity: .8

.modal.in .modal-dialog
	width: 90%

.modal.in
	display: flex !important
	align-items: center

#myModal
	padding: 0 !important
	align-items: center


.modal-content
	background-color: white
	border: 0 none
	border-radius: 0
	margin-left: 1px

	.modal-body
		padding: 0

		iframe
			margin-bottom: -5px


	.modal-header
		border: 0 none
		height: 0
		min-height: 0
		padding: 0

		.close
			background-color: #000000 !important
			border: 2px solid #ffffff !important
			border-radius: 13px
			color: #ffffff
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
			font-size: 20px
			font-weight: 700
			height: 26px
			opacity: 1
			padding-bottom: 0
			position: absolute
			right: -13px
			text-shadow: none
			top: -13px
			width: 26px
			z-index: 1

.soft-row
	&.wide
		max-width: 100% !important

.gap-1
	gap: 10px

.grid-4
	display: grid
	grid-template-columns: repeat(1, 1fr)
	@media (min-width: 640px)
		grid-template-columns: repeat(2, 1fr)
	@media (min-width: 960px)
		grid-template-columns: repeat(4, 1fr)

.standort--info
	flex: 1 0 0
	border-radius: 15px
	background: white
	overflow: hidden
	display: grid
	grid-template-columns: 1fr
	grid-template-rows: auto 1fr
	@media (max-width: 959px)
		grid-template-columns: 100px 1fr
	@media (min-width: 640px) AND (max-width: 849px)
		grid-template-columns: 1fr

	.standort--content
		padding: 15px
		text-align: left

	.standort--header
		background: $theme-color

		img
			@media (min-width: 640px) AND (max-width: 849px)
				max-width: 50%
			@media (min-width: 960px)
				max-width: 70%

	p
		margin: 0 !important
		padding: 0

	strong
		font-weight: 900


.heading--special
	color: $theme-color
	font-size: 3rem
	@media (max-width: 650px)
		font-size: 1.6em


.ticker
	font-size: 1.6em
	@media (max-width: 650px)
		font-size: 1.4em
	font-weight: bold
	background: $theme-color
	color: white
	padding: 10px 0
	border-radius: 5px
	margin: 2em 0
	display: flex

	.highlight

		padding: 0
		margin: 0 !important
		white-space: nowrap
		animation: move infinite 10s linear
		position: relative
		display: inline-block

@keyframes move
	0%
		transform: translateX(0%)
	100%
		transform: translateX(-50%)

.flex--row
	@media (min-width: 960px)
		display: flex
		position: relative

	img
		display: block
		width: 100%
		height: auto

	.bg-column
		overflow: hidden
		width: 100%
		height: 100%
		position: absolute
		z-index: 1
		top: 0
		left: 0

.flex--50
	flex: 0 0 50%
	position: relative
	display: grid
	place-items: center

	&.bg
		min-height: 80vw
		height: auto!important
		@media (min-width: 960px)
			min-height: 35vw
		// @media (min-width: 1300px)
		// 	height: 90vh

.order--1
	order: 1

.order--2
	order: 2

.align--center
	align-items: center

.align--end
	align-items: stretch
	@media (min-width: 1400px)
		align-items: stretch

@media (min-width: 1400px)
	.flex--l-0
		flex: 0 0 auto

@media (min-width: 2500px)
	.large--absolute
		z-index: 1
		position: absolute
		left: 50%
		transform: translateX(-50%)
		max-width: 1200px

.container--boxed
	padding: 30px
	max-width: 1000px
	@media (min-width: 960px)
		padding: 30px 40px
	@media (min-width: 1400px)
		padding: 60px 80px
	@media (min-width: 2000px)
		max-width: 1200px

@media (min-width: 690px)
	.vc_col-sm-4
		width: 33.33333333%

@media (min-width: 1300px)
	.vc_col-lg-offset-4
		margin-left: 33.33333333%

@media (min-width: 1000px)
	.vc_col-md-offset-3
		margin-left: 25%


.clr--orange
	color: $theme-color

.intro__logo
	max-width: 400px
	margin-bottom: 60px

.slider-auge, .slider-ohr
	max-width: 1600px
	margin: 0 auto
	.slick-next, .slick-prev
		width: 40px
		height: 40px
		margin: 0
		transform: translateY(-50%)
		background: rgba(white, 0.8)
		border-radius: 50%
		&:before
			content: ""!important
			background-size: 50%!important
			background-repeat: no-repeat
			background-position: center
			width: 40px
			height: 40px

	.slick-next
		right: 10px
		&:before
			background-image: url("../img/next.svg")
	.slick-prev
		left: 10px
		&:before
			background-image: url("../img/prev.svg")
.header__image
	width: 100%
	height: auto
	max-height: 700px
	object-fit: cover

.container	
	width: 100%
	max-width: 1600px
	padding: 5vw 20px

	p
		max-width: 80ch
		font-size: 18px
		*
			font-size: inherit