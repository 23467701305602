#footer
  .container 
    max-width: 1200px
    margin: 0 auto
    display: flex
    padding: 0 20px
    @media (max-width: 585px)
      flex-direction: column
    .greenwebsite 
      img
        max-width: 200px
  .footer
    display: flex
    justify-content: space-between
    align-items: center
    // div
    //   margin: 0 auto
    a
      display: inline-block
      margin: 10px
      text-decoration: none
      color: $body-font-color

      img
        width: auto
        padding-right: 0!important

    .main-footer
      padding: 30px 30px
      text-align: center
  .fab 
    font-size: 1.2rem

@media (max-width: 768px)
  #footer
    padding-bottom: 40px
  .spacer
    height: 40px
  

      