.people
  .section-inner
    .subtitle2
      color: white
      display: block
      background: #333
      margin: 0!important
      padding: 10px
      text-align: center
      font-size: 0.825em
      width: 100%
    @media (max-width: 650px)
      .subtitle2
        display: none
      .slick-dots
        bottom: 26%

.headerImage
  position: relative
  .subtitle3
    color: white
    display: block
    background: #333
    margin: 0!important
    padding: 10px
    text-align: center
    font-size: 0.825em
    position: absolute
    width: 100%
    bottom: 0
  @media (max-width: 650px)
    .subtitle3
      display: none
    .slick-dots
      bottom: 26%
