#marken
	.section-inner
		max-width: 1280px

		.slider-nav-marken
			.slick-list
				padding: 0 !important

				.slick-track
					.slide
						height: auto
						@media (min-width: 1000px)
							display: flex
							align-items: flex-end
							gap: 0 100px

						.preview-img
							flex: 0 0 50%

							img
								height: auto
								display: block

						.inner-text
							h3
								@media (max-width: 999px)
									margin-top: 25px
								text-align: left
								color: #F5821F
								font-weight: 600
								font-size: 35px
								line-height: 47px
								font-family: 'Open Sans', sans-serif

								& + div
									p
										font-family: 'Open Sans', sans-serif
										text-align: left
										font-size: 18px
										line-height: 28px
										letter-spacing: 0
										color: #5A6749

							.pagination
								position: relative
								max-width: 100px

								.pagingInfo
									font-family: 'Open Sans', sans-serif
									font-size: 18px
									line-height: 28px
									letter-spacing: 0
									color: #5A6749
									font-weight: 400

								.slick-prev
									left: 0
									transform: translateY(-50%)
									margin-top: unset
									margin-bottom: 0

									&:before
										content: '' !important
										background-image: url("data:image/svg+xml,%3Csvg id='Page-1' xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Icon-Set' transform='translate(0)'%3E%3Cpath id='arrow-right-circle' d='M316.277,1102.12l5.656-5.66a1,1,0,0,1,1.414,1.42l-4.121,4.12h10.586a1,1,0,0,1,0,2H319.226l4.121,4.12a1,1,0,1,1-1.414,1.42l-5.656-5.66a1.619,1.619,0,0,1,0-1.76Z' transform='translate(-316 -1096.167)' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A")
										width: 15px
										height: 14px
										background-color: transparent
										display: block
										background-repeat: no-repeat
										background-size: cover

								.slick-next
									right: 0
									transform: translateY(-50%)
									margin-top: unset
									margin-bottom: 0

									&:before
										content: '' !important
										background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Page-1' transform='translate(-8 -9.167)'%3E%3Cg id='Icon-Set' transform='translate(-308 -1087)'%3E%3Cpath id='arrow-right-circle' d='M330.535,1102.12l-5.656-5.66a1,1,0,0,0-1.414,1.42l4.121,4.12H317a1,1,0,0,0,0,2h10.586l-4.121,4.12a1,1,0,0,0,1.414,1.42l5.656-5.66a1.619,1.619,0,0,0,0-1.76Z' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
										width: 15px
										height: 14px
										background-color: transparent
										display: block
										background-repeat: no-repeat
										background-size: cover

							.inner-button
								text-align: left

#marken-header
	height: 0
	padding-bottom: 56.25%	
	// max-height: calc(100vh - 60px)
	width: 100%
	display: block
	background-size: cover
	background-position: center
	max-width: 100%
	position: relative
	.header__img
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%
		object-fit: cover
		max-width: 100%
		object-position: bottom center

	.section-inner
		max-width: 1600px
		position: absolute
		padding: 0 20px
		height: 100%

	img
		position: absolute
		bottom: 30px
		height: auto
		max-width: 30vw
		z-index: 1
		width: auto

.flex--box,
.box

	.flex--50.bg
		height: 50vh
		@media (min-width: 1300px)
			height: 93vh

	.small--heading
		font-size: 20px
		line-height: 27px
		color: #F5821F
		font-weight: 400
		letter-spacing: 0
		padding-bottom: 0
		margin-bottom: 10px !important
		// font-family: 'Open Sans', sans-serif
		font-family: 'Montserrat', sans-serif


	h1
		font-size: 28px
		line-height: 38px
		@media (min-width: 1000px)
			font-size: 45px
			line-height: 55px
		text-transform: none
		font-weight: 600
		color: #333333
		// font-family: 'Open Sans', sans-serif

	h2
		color: #000000
		font-weight: 600
		font-size: 28px
		line-height: 38px
		text-transform: none
		@media (min-width: 1000px)
			font-size: 45px
			line-height: 55px
		//	 font-family: 'Open Sans', sans-serif

	p
		color: #000000
		font-weight: 400
		font-size: 18px
		line-height: 28px
		font-family: 'Open Sans', sans-serif

		strong
			color: #000000
			font-size: 18px
			line-height: 28px
			font-family: 'Open Sans', sans-serif

#editorial-marken
	.content
		background-color: #FDF1D5

#content-2
	.content
		background-color: #333333

		h2,
		p,
		p strong
			color: #ffffff

#brillen,
#brillen-2
	.section-inner
		max-width: 1320px

		.brillen-inner
			display: grid
			grid-template-columns: 1fr 1fr 1fr
			gap: 16px 16px

			img
				height: auto

#content-3
	.content
		background-color: #F5821F

		h2,
		p,
		p strong
			color: #ffffff

#content-4
	.content
		background-color: #FDE5D2


.brandfilter
	margin-top: 5vw
	position: relative
	width: 100%
	max-width: 600px
	input
		font-family: inherit
		padding: 15px 50px 15px 20px
		font-size: 18px
		width: 100%
		border: 1px solid #707070
	img
		position: absolute
		width: 27px
		height: 27px
		right: 20px
		top: 50%
		transform: translateY(-50%)
		pointer-events: none
.brillengrid
	display: grid
	flex-wrap: wrap
	grid-auto-flow: row dense
	grid-template-columns: 1fr 1fr
	grid-template-rows: 1fr
	@media (min-width: 768px)
		grid-template-columns: 1fr 1fr 1fr 1fr
	gap: 20px
	margin-top: 40px
	.brand
		padding: 30px
		display: flex
		align-items: center
		justify-content: center
		border: 1px solid #707070
		aspect-ratio: 1/1
		&.brand__img
			display: grid
			grid-template-columns: 1fr
			@media (min-width: 540px)
				grid-template-columns: 1fr 1fr
			grid-column: span 2
			aspect-ratio: unset
			padding: 0
			> img
				width: 100%
				height: 100%
				object-fit: cover
				object-position: bottom right
			.logo__container
				aspect-ratio: 2/1
				@media (min-width: 540px)
					aspect-ratio: 1/0.97
				padding: 20px
				display: flex
				flex-direction: column
				align-items: center
				justify-content: center
				img
					width: 100%
					height: auto
					max-height: 100px
					object-fit: contain
					object-position: center

			
			// @for $i from 1 through 20
			// 	&--#{$i}
			// 		grid-row: #{$i * 1}
			// 		grid-column-start: #{$i % 3 + 1}