#usercentrics-button .uc-btn-new
    line-height: 1
    font-size: 16px

#usercentrics-button
    font-size: 14px
    .uc-banner-text
        color: $dark-grey!important
    button
        margin-bottom: 0
#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header
    background-color: $button-grey!important

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header .uc-info-modal-search-bar input
    background-color: #fff!important
    color: black!important
    &::placeholder
        color: black!important
#usercentrics-button #uc-center-modal .uc-powered-by-footer
    display: none!important

#usercentrics-button .uc-powered-by-footer
    display: none!important
#usercentrics-button .uc-save-settings-and-close-button
    color: $button-grey!important

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-info-modal-sidebar .uc-powered-by
    display: none!important

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-info-modal-sidebar .uc-info-modal-sidebar-nav
    background: $grey

#uc-btn-close-main-corner-modal svg 
    use, #fingerprint
        fill: $theme-color!important

#uc-btn-deny-banner
    margin-top: 10px
    background-color: $grey!important
#uc-btn-more-info-banner
    margin-top: 10px
    background: rgba(white, 0.3)!important
    font-size: 12px!important

#usercentrics-button .uc-banner-content
    background: white!important
#usercentrics-button .uc-banner-links
    *
        font-size: 12px!important
#usercentrics-button
    .uc-btn
        box-shadow: none!important