#service-header
	background: $grey
	.headerImage
		background: url(../img/headerService.jpg) center no-repeat
		background-size: cover

	.headerText
		max-width: 750px
		margin: 30px auto
		text-align: center
		padding: 30px

#service-punkte
	background: white
	padding: 20px
	.soft-row
		display: flex
		justify-content: center
		flex-wrap: wrap
		max-width: 850px
		margin: 0 auto

	.iconWrapper
		text-align: center
		margin: 20px
		min-width: 20%
		max-width: 100%
		strong
			margin: 10px 0 0 0!important
			display: block
		p
			margin: 0!important
			max-width: 240px
			font-size: 0.925em
		.icon
			width: 114px
			height: 114px
			border-radius: 50%
			background: $button-orange
			margin: 0 auto
			overflow: hidden
			position: relative
			img
				width: 80%
				height: 80%
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
			&:first-child
				.icon
					img
						top: 0
						transform: translate(-50%, 0)
			&:nth-child(2)
				.icon
					img
						top: auto
						bottom: -0px
						transform: translate(-30%, 0)
			&:nth-child(3)
				.icon
					img
						width: 50%
						height: 50%
			&:nth-child(4)
				.icon
					img
						transform: translate(-45%, -55%)
			&:nth-child(5)
				.icon
					img
						width: 70%
						height: 70%
			&:nth-child(6)
				.icon
					img
						width: 50%
						height: 50%

		@media (max-width: 650px)
			max-width: 100%
			min-width: 100%
			margin: 10px 0px
			display: flex
			justify-content: flex-start
			.icon
				width: 80px
				height: 80px
				min-width: 80px
				min-height: 80px
				margin: 0 20px 0 0

			strong
				line-height: 1.4
				text-align: left

			p
				max-width: none
				font-size: 0.925em
				text-align: left
				max-width: none

#werkstatt
	margin-bottom: 80px
	#werkstattSlider
		max-width: 750px
		margin: 0 auto
	.slider-nav img
		max-width: 100%

	.slider-for img
		width: 100%


	.slider-nav slick-slide:hover
		cursor: pointer

	.slick-prev, .slick-next
		position: absolute
		top: 50%
		margin-top: 0px
		background-color: transparent
		border: none
		padding: 0
		font-size: 0
		&:before
			background-repeat: no-repeat
			background-size: 100% auto
			content: ""
			display: block
			height: 60px
			opacity: 1
			width: 30px

		&:hover:before
			opacity: .8
