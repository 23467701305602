@charset "UTF-8";
/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v24-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v24-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v24-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v24-latin-300.woff") format("woff"), url("../fonts/montserrat-v24-latin-300.ttf") format("truetype"), url("../fonts/montserrat-v24-latin-300.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v24-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v24-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v24-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v24-latin-regular.woff") format("woff"), url("../fonts/montserrat-v24-latin-regular.ttf") format("truetype"), url("../fonts/montserrat-v24-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v24-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v24-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v24-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v24-latin-700.woff") format("woff"), url("../fonts/montserrat-v24-latin-700.ttf") format("truetype"), url("../fonts/montserrat-v24-latin-700.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans-v29-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v29-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v29-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v29-latin-300.woff") format("woff"), url("../fonts/open-sans-v29-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v29-latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v29-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v29-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v29-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v29-latin-regular.woff") format("woff"), url("../fonts/open-sans-v29-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v29-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans-v35-latin-600.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/open-sans-v35-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v35-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v35-latin-600.woff") format("woff"), url("../fonts/open-sans-v35-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v35-latin-600.svg#OpenSans") format("svg");
  /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-v29-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v29-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v29-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v29-latin-700.woff") format("woff"), url("../fonts/open-sans-v29-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v29-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */
}
/* poppins-300 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/poppins-v20-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/poppins-v20-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v20-latin-300.woff2") format("woff2"), url("../fonts/poppins-v20-latin-300.woff") format("woff"), url("../fonts/poppins-v20-latin-300.ttf") format("truetype"), url("../fonts/poppins-v20-latin-300.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/poppins-v20-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/poppins-v20-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-v20-latin-700.woff2") format("woff2"), url("../fonts/poppins-v20-latin-700.woff") format("woff"), url("../fonts/poppins-v20-latin-700.ttf") format("truetype"), url("../fonts/poppins-v20-latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */
}
p {
  margin: 20px 0 20px !important;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

h1, h2 {
  text-transform: uppercase;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
}

h1 {
  font-size: 2.2em;
  font-weight: 700;
}

h2 {
  font-size: 1.9em;
  font-weight: 700;
}

h3 {
  font-size: 2.2em;
  color: #3D3D3D;
  font-weight: 500;
}

h4 {
  font-size: 14px;
  color: #3D3D3D;
}

a {
  color: #F5821F;
}

@media (max-width: 650px) {
  h1, h2 {
    font-size: 1.6em;
  }
}
.small--heading {
  font-size: 1.6rem;
}

.button, button {
  margin-bottom: 50px;
  font-weight: 700;
  padding: 10px 60px 10px 60px;
  transition: all 300ms ease;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  border: none;
  outline: none;
  text-decoration: none;
  background: #F5821F;
  display: inline-block;
}
.button:hover, button:hover {
  background: #d7690a;
}
.button a, button a {
  color: white;
  text-decoration: none;
}

.termin-button {
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 9999;
  width: 160px;
  transition: all 300ms ease;
}
.termin-button:hover {
  width: 200px;
}
.termin-button a {
  background: #F5821F;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  background-position: center left;
  transition: all 300ms ease;
  position: relative;
  display: block;
  line-height: 48px;
  text-align: center;
}
.termin-button a:before {
  content: "";
  position: absolute;
  border: 24px solid #F5821F;
  border-left: 19px solid transparent;
  left: -42px;
  transition: all 300ms ease;
  top: 0;
}
.termin-button a:hover {
  background: #d7690a;
}
.termin-button a:hover:before {
  border: 24px solid #d7690a;
  border-left: 19px solid transparent;
}

.events-button {
  position: absolute;
  top: 140px;
  right: 0;
  z-index: 9999;
  transition: all 300ms ease;
  width: 90px;
  background: #F5821F;
}
.events-button:hover {
  background: #d7690a;
  width: 200px;
}
.events-button:hover #event-dropdown {
  position: absolute;
  height: 300%;
  left: -40px;
}
.events-button:hover:before {
  border: 24px solid #d7690a;
  border-left: 19px solid transparent;
}
.events-button #event-dropdown {
  width: 240px;
  height: 0;
  position: absolute;
  overflow: hidden;
  left: -40px;
  transition: all 300ms ease;
}
.events-button #event-dropdown li {
  list-style: none;
  margin: 0;
}
.events-button #event-dropdown li a {
  border-bottom: 1px solid #efefef;
  background: white;
  padding: 10px;
  color: #3D3D3D;
  transition: all 300ms ease;
  width: 100%;
  height: 100%;
  display: block;
}
.events-button #event-dropdown li a:hover {
  border-bottom: 1px solid transparent;
  background: #F5821F;
  color: white;
}
.events-button .sticky {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  background-position: center left;
  transition: all 300ms ease;
  position: relative;
  display: block;
  line-height: 48px;
  transition: all 300ms ease;
  text-align: center;
  margin-right: 24px;
}
.events-button:before {
  content: "";
  position: absolute;
  border: 24px solid #F5821F;
  border-left: 19px solid transparent;
  left: -42px;
  transition: all 300ms ease;
  top: 0;
}

@media (max-width: 768px) {
  .termin-button {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    top: auto;
  }
  .termin-button:hover {
    width: 100%;
  }
  .termin-button a {
    text-align: center;
    width: 100%;
    background: #F5821F;
    display: block;
    padding: 10px 0;
    line-height: 1.5;
  }

  .events-button {
    position: fixed;
    bottom: 0px;
    width: 50%;
    right: 0;
    top: auto;
  }
  .events-button:hover {
    width: 50%;
  }
  .events-button:before {
    content: none;
  }
  .events-button #event-dropdown {
    display: none;
  }
  .events-button .sticky {
    text-align: center;
    width: 100%;
    display: block;
    padding: 10px 0;
    line-height: 1.5;
  }
}
.btn_orange {
  background: #F5821F;
  cursor: pointer;
  color: white;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: bold;
  margin-top: 20px;
  display: inline-block;
  width: auto;
  transition: all 300ms ease;
}
.btn_orange:hover {
  background: #f69037;
  padding: 10px 30px;
}

.controllers .btn {
  padding: 10px 30px;
  margin: 20px 0;
}

.header {
  position: sticky;
  width: 100%;
  top: 0;
  background: #333;
  z-index: 9999999999999998000000000000000000000;
  height: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.header .header-inner {
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.header .header-inner #logo {
  line-height: 1;
  height: 24px;
}
.header .header-inner #logo svg {
  height: 24px;
  width: auto;
}
.header .header-inner .burger-menu {
  display: none;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
  transition: all 300ms ease;
}
.header .header-inner .burger-menu:hover {
  border: 2px solid #F5821F;
}
.header .header-inner .burger-menu:hover #burger-bar {
  background: #F5821F;
}
.header .header-inner .burger-menu #burger-bar {
  width: 20px;
  height: 2px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 300ms ease;
}
.header .header-inner .burger-menu #burger-bar:before {
  content: "";
  width: 20px;
  height: 2px;
  background: inherit;
  position: absolute;
  top: -6px;
  transition: all 300ms ease;
}
.header .header-inner .burger-menu #burger-bar:after {
  content: "";
  width: 20px;
  height: 2px;
  background: inherit;
  position: absolute;
  top: 6px;
  transition: all 300ms ease;
}
.header .header-inner .burger-menu.active #burger-bar {
  width: 0;
  left: 6px;
}
.header .header-inner .burger-menu.active #burger-bar:before {
  top: 0;
  transform: rotate(45deg);
}
.header .header-inner .burger-menu.active #burger-bar:after {
  top: 0;
  transform: rotate(-45deg);
}
.header .header-inner .menu {
  z-index: 99;
  margin: 0;
  padding: 0;
  height: 100%;
}
.header .header-inner .menu li {
  font-size: 15px;
  list-style-type: none;
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
}
.header .header-inner .menu li * {
  font-size: 15px;
}
@media (min-width: 1401px) {
  .header .header-inner .menu li.has-submenu > a:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.256' height='7.128' viewBox='0 0 12.256 7.128'%3E%3Cpath id='Pfad_50672' data-name='Pfad 50672' d='M277.75,85.653l4.714,4.714,4.714-4.714' transform='translate(-276.335 -84.239)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
    width: 12px;
    height: 7px;
    display: inline-block;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    top: -2px;
    margin-left: 5px;
  }
}
@media (min-width: 1401px) {
  .header .header-inner .menu li:hover .sub {
    background: #F5821F;
    color: white;
    cursor: pointer;
    pointer-events: all;
  }
  .header .header-inner .menu li:hover .submenu {
    display: block;
    z-index: 9;
    background: #F5821F;
    padding: 0;
  }
}
.header .header-inner .menu li li {
  padding: 0;
}
.header .header-inner .menu li li a {
  display: block;
  padding: 0 30px;
  color: #333;
}
.header .header-inner .menu li li a:hover {
  background: #f79d50;
  color: #ffffff;
}
.header .header-inner .mobile-menu {
  display: none;
}
.header .header-inner .menu {
  right: 0;
  display: flex;
}
.header .header-inner li {
  list-style-type: none;
  height: 100%;
  position: relative;
}
.header .header-inner li .submenu-toggle {
  display: none;
}
.header .header-inner li a {
  display: block;
  font-family: "Open Sans", sans-serif;
  color: white;
  padding: 0 12px;
  line-height: 60px;
  height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms ease;
}
@media (min-width: 1400px) {
  .header .header-inner li a {
    font-size: 18px;
    line-height: 60px;
  }
}
@media (min-width: 1400px) {
  .header .header-inner li a#menuShop {
    margin-left: 20px;
    background-color: #F5821F;
    font-weight: 700;
    padding: 0 21px;
  }
}
.header .header-inner li a:hover {
  background: #F5821F;
  color: #ffffff;
}
.header .header-inner li .active {
  background: #F5821F !important;
  color: #ffffff;
}
.header .header-inner li .submenu {
  top: 60px;
}
@media (min-width: 1401px) {
  .header .header-inner li .submenu {
    position: absolute;
    left: 0;
    display: none;
    background: #F5821F;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .header .header-inner li .submenu li {
    height: auto;
  }
  .header .header-inner li .submenu li a {
    text-align: left;
    width: 100%;
    color: #ffffff;
    line-height: 1;
    padding: 10px 20px;
    text-transform: none;
  }
  .header .header-inner li .submenu li a.active {
    font-weight: 600;
    background: #f0750b !important;
  }
  .header .header-inner li .submenu:hover {
    display: block;
    z-index: 9;
    height: auto;
  }
}

@media (max-width: 1400px) {
  .header .header-inner .burger-menu {
    display: block;
  }
  .header .header-inner .menu {
    display: none;
  }
  .header .header-inner .menu.active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    width: 300px;
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    position: absolute;
    background: white;
    right: 0;
    top: 60px;
  }
  .header .header-inner .menu.active li {
    height: auto;
    flex-direction: column;
  }
  .header .header-inner .menu.active li .submenu {
    display: none;
  }
  .header .header-inner .menu.active li.has-submenu {
    position: relative;
  }
  .header .header-inner .menu.active li.has-submenu .submenu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    min-height: 20px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    right: 10px;
    top: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
  }
  .header .header-inner .menu.active li.has-submenu .submenu-toggle img {
    display: block;
    width: 10px;
  }
  .header .header-inner .menu.active li.has-submenu.open .submenu-toggle img {
    transform: rotate(90deg);
  }
  .header .header-inner .menu.active li.has-submenu.open .submenu {
    display: block;
    width: 100%;
    background: #efefef;
  }
  .header .header-inner .menu.active li a {
    color: #333;
  }
  .header .header-inner .menu.active li a:hover {
    color: white;
  }
  .header .header-inner .menu.active li a {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 768px) {
  #sticky {
    display: flex;
    flex-direction: column;
  }
  #sticky .sticky {
    position: fixed;
    top: 100px;
    z-index: 99;
    right: 0;
    border-radius: 7px 0 0 7px;
    transition: all 300ms ease;
  }
  #sticky .sticky:first-child {
    top: 100px;
  }
  #sticky .sticky:nth-child(2) {
    top: 170px;
  }
  #sticky .sticky:nth-child(3) {
    top: 240px;
  }
  #sticky a {
    background: #F5821F;
    padding: 10px;
    margin: 10px 0;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #sticky a:hover {
    margin-right: 0;
  }
  #sticky i {
    font-size: 2rem;
    color: white;
  }
  #sticky i.fa-calendar-alt {
    padding: 0 5px;
  }
  #sticky span {
    color: white;
    padding-left: 10px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  #sticky {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 99;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  #sticky .sticky {
    border-radius: 7px 7px 0 0;
    transition: all 300ms ease;
    margin: 0 5px;
  }
  #sticky a {
    background: #F5821F;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #sticky i {
    font-size: 2rem;
    color: white;
  }
  #sticky i.fa-calendar-alt {
    padding: 0 5px;
  }
  #sticky span {
    display: none;
  }
}
.sternegeben #sticky .sticky {
  background: #333 !important;
}

.slick-dots {
  bottom: 45%;
  left: 0;
}
.slick-dots li {
  display: block;
  margin-bottom: 10px;
}
.slick-dots li button:before {
  font-size: 10px;
}

.content, section {
  max-width: 2400px;
  margin: 0 auto;
}

#homeslider {
  width: 100%;
  max-width: 2400px;
  margin: 0 auto;
}
#homeslider .slick-dots {
  bottom: 45%;
  left: 0;
}
#homeslider .slick-dots li {
  display: block;
  margin-bottom: 10px;
}
#homeslider .slick-dots li button:before {
  font-size: 10px;
}
#homeslider .short-cuts a {
  transition: all 300ms ease;
  width: 130px;
  height: 130px;
  position: relative;
  display: block;
  top: 0;
}
#homeslider .short-cuts a:hover {
  top: -10px;
}
@media (max-width: 768px) {
  #homeslider .short-cuts {
    display: none;
  }
}
#homeslider .main-slider {
  position: relative;
  width: 100%;
  height: 50vw;
  max-height: 850px;
  overflow: hidden;
}
#homeslider .main-slider .slideHome {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
#homeslider .main-slider .slideHome img {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  height: 100%;
  object-fit: cover;
  object-position: center 35%;
}
#homeslider .main-slider .subtitle {
  color: white;
  display: block;
  background: #333;
  margin: 0 !important;
  padding: 10px;
  text-align: center;
  font-size: 0.825em;
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media (max-width: 650px) {
  #homeslider .main-slider .subtitle {
    display: none;
  }
  #homeslider .main-slider .slick-dots {
    bottom: 26%;
  }
}

.slick-prev, .slick-next {
  z-index: 9;
}

.slick-next {
  right: -15px;
}
.slick-next:before {
  content: "→" !important;
}

.slick-prev:before {
  content: "←" !important;
}

.image-slider .slick-list,
.image-slider-akustik .slick-list {
  width: 100%;
  margin: 0 auto;
}
.image-slider .slick-slide img,
.image-slider-akustik .slick-slide img {
  padding: 0;
  object-fit: cover;
  height: 400px;
  object-position: center;
}
.image-slider img.lupe,
.image-slider-akustik img.lupe {
  position: absolute;
  width: 40px;
  height: 40px !important;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  background: #f5811f;
  transition: all 100ms ease;
}
.image-slider .slick-slide p,
.image-slider-akustik .slick-slide p {
  font-size: 14px;
}
.image-slider .slick-slide:hover img.lupe,
.image-slider-akustik .slick-slide:hover img.lupe {
  padding: 7px;
}

#news-ozeaneum button {
  margin-top: 0;
}
#news-ozeaneum .slick-track {
  display: flex;
  align-items: center;
}
#news-ozeaneum .slick-track .slider-container {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}
@media (max-width: 768px) {
  #news-ozeaneum .slick-track .slider-container {
    height: auto;
  }
}
#news-ozeaneum .slick-track .slider-container img, #news-ozeaneum .slick-track .slider-container video {
  height: 400px;
  width: auto;
  max-width: 100%;
}
@media (max-width: 768px) {
  #news-ozeaneum .slick-track .slider-container img, #news-ozeaneum .slick-track .slider-container video {
    height: auto;
  }
}
#news-ozeaneum .slick-next {
  right: -15px;
}
@media (max-width: 768px) {
  #news-ozeaneum .slick-next {
    right: 5px;
  }
}
#news-ozeaneum .slick-next:before {
  content: "→" !important;
}
@media (max-width: 768px) {
  #news-ozeaneum .slick-prev {
    left: -5px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../slick/fonts/slick.eot");
  src: url("../slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../slick/fonts/slick.woff") format("woff"), url("../slick/fonts/slick.ttf") format("truetype"), url("../slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover, .slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #3D3D3D;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: #F5821F;
}

.brillen #brillen, .brillen #sonnenbrillen {
  margin-bottom: 60px;
}
.brillen #brillen {
  border-bottom: 1px solid #ccc;
}
.brillen h2 {
  margin-bottom: 30px;
}
.brillen .slick-slide img {
  max-width: 400px;
  margin: 30px auto;
}
.brillen .modal .slick-slide img {
  max-width: 90%;
}
.brillen #markenlogos {
  display: flex;
  align-items: center;
}
.brillen #markenlogos .markenlogo {
  min-width: 10px;
}
.brillen #markenlogos .slick-arrow {
  margin: 0 !important;
}
.brillen #markenlogos .slick-list {
  position: relative;
}
.brillen #markenlogos .slick-list:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 100%;
  background: linear-gradient(to right, white, transparent);
  z-index: 1;
}
.brillen #markenlogos .slick-list:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  background: linear-gradient(to right, transparent, white);
  z-index: 1;
}

.logo-1 {
  background: url(../img/logos/logo-1.png) no-repeat center;
  background-size: contain;
}

.logo-2 {
  background: url(../img/logos/logo-2.png) no-repeat center;
  background-size: contain;
}

.logo-3 {
  background: url(../img/logos/logo-3.png) no-repeat center;
  background-size: contain;
}

.logo-4 {
  background: url(../img/logos/logo-4.png) no-repeat center;
  background-size: contain;
}

.logo-5 {
  background: url(../img/logos/logo-5.png) no-repeat center;
  background-size: contain;
}

.logo-6 {
  background: url(../img/logos/logo-6.png) no-repeat center;
  background-size: contain;
}

.logo-7 {
  background: url(../img/logos/logo-7.png) no-repeat center;
  background-size: contain;
}

.logo-8 {
  background: url(../img/logos/logo-8.png) no-repeat center;
  background-size: contain;
}

.logo-9 {
  background: url(../img/logos/logo-9.png) no-repeat center;
  background-size: contain;
}

.logo-10 {
  background: url(../img/logos/logo-10.png) no-repeat center;
  background-size: contain;
}

.logo-11 {
  background: url(../img/logos/logo-11.png) no-repeat center;
  background-size: contain;
}

.logo-12 {
  background: url(../img/logos/logo-12.png) no-repeat center;
  background-size: contain;
}

.logo-13 {
  background: url(../img/logos/logo-13.png) no-repeat center;
  background-size: contain;
}

.logo-14 {
  background: url(../img/logos/logo-14.png) no-repeat center;
  background-size: contain;
}

.logo-15 {
  background: url(../img/logos/logo-15.png) no-repeat center;
  background-size: contain;
}

.logo-16 {
  background: url(../img/logos/logo-16.png) no-repeat center;
  background-size: contain;
}

.logo-17 {
  background: url(../img/logos/logo-17.png) no-repeat center;
  background-size: contain;
}

.logo-18 {
  background: url(../img/logos/logo-18.png) no-repeat center;
  background-size: contain;
}

.logo-19 {
  background: url(../img/logos/logo-19.png) no-repeat center;
  background-size: contain;
}

.logo-20 {
  background: url(../img/logos/logo-20.png) no-repeat center;
  background-size: contain;
}

.logo-21 {
  background: url(../img/logos/logo-21.png) no-repeat center;
  background-size: contain;
}

.logo-22 {
  background: url(../img/logos/logo-22.png) no-repeat center;
  background-size: contain;
}

.logo-23 {
  background: url(../img/logos/logo-23.png) no-repeat center;
  background-size: contain;
}

.logo-24 {
  background: url(../img/logos/logo-24.png) no-repeat center;
  background-size: contain;
}

.logo-25 {
  background: url(../img/logos/logo-25.png) no-repeat center;
  background-size: contain;
}

.logo-26 {
  background: url(../img/logos/logo-26.png) no-repeat center;
  background-size: contain;
}

.logo-27 {
  background: url(../img/logos/logo-27.png) no-repeat center;
  background-size: contain;
}

.logo-28 {
  background: url(../img/logos/logo-28.png) no-repeat center;
  background-size: contain;
}

.logo-29 {
  background: url(../img/logos/logo-29.png) no-repeat center;
  background-size: contain;
}

.logo-30 {
  background: url(../img/logos/logo-30.png) no-repeat center;
  background-size: contain;
}

.only-mobile {
  display: none;
}

@media (max-width: 668px) {
  .only-mobile {
    display: block;
  }

  .only-desktop {
    display: none;
  }
}
#news .slick-dots {
  display: none !important;
}
#news .section-inner {
  max-width: 1280px;
}
#news h2 {
  margin-bottom: 60px;
}
#news .news-content .slick-list {
  padding: 0 !important;
}
@media (min-width: 1000px) {
  #news .news-content .inner {
    display: flex;
    align-items: center;
    gap: 0 100px;
  }
}
#news .news-content .inner .preview-img {
  flex: 0 0 50%;
}
#news .news-content .inner .text .pagination {
  position: relative;
  max-width: 100px;
}
#news .news-content .inner .text .pagination .pagingInfoNews {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  color: #5A6749;
  font-weight: 400;
}
#news .news-content .inner .text .pagination .slick-prev {
  left: 0;
  transform: translateY(-50%);
  margin-top: unset;
  margin-bottom: 0;
}
#news .news-content .inner .text .pagination .slick-prev:before {
  content: "" !important;
  background-image: url("data:image/svg+xml,%3Csvg id='Page-1' xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Icon-Set' transform='translate(0)'%3E%3Cpath id='arrow-right-circle' d='M316.277,1102.12l5.656-5.66a1,1,0,0,1,1.414,1.42l-4.121,4.12h10.586a1,1,0,0,1,0,2H319.226l4.121,4.12a1,1,0,1,1-1.414,1.42l-5.656-5.66a1.619,1.619,0,0,1,0-1.76Z' transform='translate(-316 -1096.167)' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A");
  width: 15px;
  height: 14px;
  background-color: transparent;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}
#news .news-content .inner .text .pagination .slick-next {
  right: 0;
  transform: translateY(-50%);
  margin-top: unset;
  margin-bottom: 0;
}
#news .news-content .inner .text .pagination .slick-next:before {
  content: "" !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Page-1' transform='translate(-8 -9.167)'%3E%3Cg id='Icon-Set' transform='translate(-308 -1087)'%3E%3Cpath id='arrow-right-circle' d='M330.535,1102.12l-5.656-5.66a1,1,0,0,0-1.414,1.42l4.121,4.12H317a1,1,0,0,0,0,2h10.586l-4.121,4.12a1,1,0,0,0,1.414,1.42l5.656-5.66a1.619,1.619,0,0,0,0-1.76Z' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  width: 15px;
  height: 14px;
  background-color: transparent;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}
#news .news-content .inner p, #news .news-content .inner ul {
  color: black !important;
}
#news .news-content .inner ul {
  text-align: left;
  max-width: 90%;
}
#news .news-content .inner ul li {
  margin-left: 40px;
}
#news .news-content .inner .soft-row {
  align-items: center;
  background: #F5821F;
  position: relative;
  justify-content: flex-start;
  min-height: 56px;
}
#news .news-content .inner .soft-row .col-2 {
  flex: 2 0 0;
  text-align: right;
  max-width: calc(100% - 90px);
}
#news .news-content .inner .soft-row .col-1 {
  background: #333;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex: 1 0 0;
  right: 10px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #news .news-content .inner .soft-row .col-1 {
    top: -20px;
  }
}
#news .news-content .inner .soft-row .col-1 strong {
  font-size: 1.2rem;
  color: white;
  max-width: 90%;
  line-height: 1.1;
}
#news .news-content .inner .soft-row .col-1 span {
  font-size: 0.8rem;
}
#news .news-content .inner #news-1 img {
  object-fit: cover;
  height: 100%;
  object-position: top right;
  display: block;
}
#news .news-content .inner #news-2 {
  background: url(../img/news/golfturnier.jpg) center no-repeat;
  background-size: cover;
}
#news .news-content .inner #news-3 {
  background-color: #0c1754;
}
#news .news-content .inner #news-3 img {
  object-fit: cover;
  height: 100%;
  display: block;
}
#news .news-content .inner #news-4 {
  background: url(../img/news/Team.jpg) center no-repeat;
  background-size: cover;
}
#news .news-content .inner #news-5 {
  background: url(../img/news/BH-HST_MSM_Isabelle.jpg) top no-repeat;
  background-size: cover;
}
#news .news-content .inner h3 {
  margin: 0 0 25px;
  text-align: left;
  font-size: 35px;
  font-weight: 600;
  line-height: 47px;
  color: #F5821F;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 999px) {
  #news .news-content .inner h3 {
    margin-top: 25px;
  }
}
#news .news-content .inner h4 {
  font-size: 1em;
  margin: 0;
  font-weight: 200;
  background: #F5821F;
  display: block;
  color: white;
  padding: 5px;
  position: relative;
  z-index: 1;
}
#news .news-content .inner .btn_orange {
  margin-top: 0;
  margin-bottom: 25px;
}
#news .news-content .inner p {
  font-family: "Open Sans", sans-serif;
  padding: 0;
  color: #5A6749 !important;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  margin: 0 !important;
  font-weight: 400;
}
#news .news-content .inner p strong {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

#news-container .water--content {
  background: url(../img/hausmesse-meer.jpg) bottom center no-repeat;
  background-size: cover;
  padding: 20px;
}
#news-container .water--content h3 {
  background: none !important;
  font-weight: bold !important;
}
#news-container .water--content .termine--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
#news-container .water--content .termine--container .termin {
  width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#news-container .water--content .termine--container .termin--image {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 160px;
  height: 130px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
#news-container .water--content .termine--container .termin--image img {
  display: block;
  height: auto;
  max-height: 100%;
}
#news-container .water--content .termine--container .termin--datum {
  background: #F5821F;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 10px 20px;
  width: 100%;
}
#news-container .water--content .termine--container .termin--ort {
  width: 100%;
  font-size: 1.2rem;
  padding: 10px 20px;
  background: white;
}
#news-container .muster--content {
  background: url(../img/muster-hausmesse.jpg) center no-repeat;
  background-size: cover;
  padding: 20px;
}
#news-container .muster--content .stoerer {
  width: 250px;
  height: 250px;
}
#news-container .muster--content small {
  display: block;
  font-size: 0.825rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.4 !important;
}
#news-container .muster--content h2, #news-container .muster--content h1, #news-container .muster--content h3 {
  background: none !important;
  padding: 0 !important;
}
#news-container .muster--content h2 {
  color: #F5821F !important;
  font-size: 1.4rem !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}
#news-container .muster--content h3 {
  font-size: 1.2rem !important;
  margin-top: 20px !important;
}
#news-container .news--image img {
  display: block;
}
#news-container .section-inner {
  padding: 0px !important;
  margin: 0 auto !important;
}
#news-container .news-content #news-1 {
  background: url(../img/news/ozeaneum_neu.jpg) top no-repeat;
  background-size: cover;
  height: 30vw;
  max-height: 500px;
  min-height: 200px;
}
#news-container .news-content #news-2 {
  background: url(../img/news/golfturnier.jpg) center no-repeat;
  background-size: cover;
  height: 30vw;
  max-height: 500px;
  min-height: 200px;
}
#news-container .news-content h3 {
  margin: 0;
  font-size: 1.6em;
  line-height: 1.4;
  background: #efefef;
  padding: 5px;
}
#news-container .news-content h4 {
  font-size: 1em;
  margin: 0;
  font-weight: 200;
  background: #F5821F;
  display: block;
  color: white;
  padding: 5px;
}
#news-container .news-content p {
  padding: 20px;
  margin: 0 !important;
}
#news-container hr {
  margin: 30px auto;
  border: none;
  height: 1px;
  background: #999;
}

#online-tools {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 50px;
}
#online-tools .tool {
  flex: 1 0 0;
  margin: 10px;
  min-width: 168px;
  display: block;
}
@media (max-width: 1017px) {
  #online-tools .tool {
    min-width: 160px;
  }
}
@media (max-width: 975px) {
  #online-tools .tool {
    min-width: 220px;
  }
}
#online-tools .tool a.inner {
  color: #3D3D3D;
  transition: all 300ms ease;
}
#online-tools .tool a.inner:hover img {
  top: -10px;
}
#online-tools .tool a.inner h4 {
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #F5821F;
  margin-bottom: 20px;
}
#online-tools .tool a.inner p {
  margin: 10px 0 0 0 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
#online-tools .tool a.inner img {
  width: 206px;
  border: 1px solid grey;
  border-radius: 50%;
  position: relative;
  top: 0;
  transition: all 300ms ease;
}

#terminvereinbarung {
  text-align: center;
}
#terminvereinbarung .section-inner {
  padding: 60px 20px 0 20px !important;
}
#terminvereinbarung iframe {
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  height: 900px;
  margin-bottom: 30px;
}

.spacer {
  height: 50px;
}

#wett-sehtest {
  text-align: center;
}
#wett-sehtest iframe {
  margin-bottom: 30px;
}
#wett-sehtest .section-inner {
  padding: 60px 20px 0 20px !important;
}

#glas-simulator {
  text-align: center;
}
#glas-simulator iframe {
  margin-bottom: 30px;
}
#glas-simulator .section-inner {
  padding: 60px 20px 0 20px !important;
}

#glasdicken-simulator {
  text-align: center;
}
#glasdicken-simulator iframe {
  margin-bottom: 30px;
}
#glasdicken-simulator .section-inner {
  padding: 60px 20px 0 20px !important;
}

#toenungs-simulator {
  text-align: center;
}
#toenungs-simulator iframe {
  margin: 0 0 30px 0;
  width: 100%;
  background: white;
  min-height: 800px;
}
#toenungs-simulator .section-inner {
  padding: 60px 20px 0 20px !important;
}

#brille-oder-linse {
  text-align: center;
}
#brille-oder-linse iframe {
  margin-bottom: 30px;
}
#brille-oder-linse .section-inner {
  padding: 60px 20px 0 20px !important;
}

.online-tool--section {
  padding: 40px 15px;
  text-align: center;
}
.online-tool--section iframe, .online-tool--section #kl-app {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.online-tool--section #kl-app {
  max-width: 1200px;
}
.online-tool--section:nth-child(odd) {
  background: #efefef;
}

.bg {
  background: transparent url("../img/online-tools/linse/bg.jpg") 0 0/cover no-repeat;
}

#kl-app {
  max-width: 1200px;
  margin: 0 auto 30px auto;
  overflow: hidden;
  padding: 0 1.5625rem;
  padding: 0;
  /* change colours to suit your needs */
  /* change border colour to suit your needs */
  /* END RESET */
  /* soft Grid */
  /*Items*/
  /*.q-img img { position: relative; left: -23.75rem; max-width: none;}*/
  /*.q-img { background-image: url(../img/frage1.jpg); }*/
  /* Radio buttons */
  /* ENDE Radio buttons*/
  /* Ergebnis */
  /* Ende Ergebnis */
}
#kl-app #kl-people {
  background-image: url("../img/online-tools/linse/img-startseite.png");
  width: 100%;
}
#kl-app ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
#kl-app mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
#kl-app del {
  text-decoration: line-through;
}
#kl-app abbr[title],
#kl-app dfn[title] {
  border-bottom: 0.0625rem dotted;
  cursor: help;
}
#kl-app table {
  border-collapse: collapse;
  border-spacing: 0;
}
#kl-app hr {
  display: block;
  height: 0.0625rem;
  border: 0;
  border-top: 0.0625rem solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
#kl-app input,
#kl-app select {
  vertical-align: middle;
}
#kl-app * {
  box-sizing: border-box;
}
#kl-app .soft-main-wrapper {
  width: 100%;
  max-width: 65.25rem;
  margin: 0 auto;
  padding: 0 0.625rem;
}
@media screen and (max-width: 65.25em) {
  #kl-app .soft-main-wrapper {
    padding: 0 1.25rem;
  }
}
#kl-app .soft-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-flex-wrap: nowrap; -ms-flex-wrap: wrap; flex-wrap: wrap; */
  max-width: 100%;
}
#kl-app .soft-row .soft-row:first-of-type {
  margin-top: -1.5625rem;
}
#kl-app .soft-row .soft-row:last-of-type {
  margin-bottom: -1.5625rem;
}
#kl-app [class*=soft-col] {
  flex: 1 0 auto;
  padding: 1.5625rem;
}
#kl-app .soft-col-1 {
  width: 10%;
}
#kl-app .soft-col-2 {
  width: 20%;
}
#kl-app .soft-col-3 {
  width: 25%;
}
#kl-app .soft-col-4 {
  width: 30%;
}
#kl-app .soft-col-5 {
  width: 33.333333333%;
}
#kl-app .soft-col-6 {
  width: 40%;
}
#kl-app .soft-col-7 {
  width: 50%;
}
#kl-app .soft-col-8 {
  width: 60%;
}
#kl-app .soft-col-9 {
  width: 66.666666666%;
}
#kl-app .soft-col-10 {
  width: 70%;
}
#kl-app .soft-col-11 {
  width: 75%;
}
#kl-app .soft-col-12 {
  width: 80%;
}
#kl-app .soft-col-13 {
  width: 90%;
}
#kl-app .soft-col-14 {
  width: 100%;
}
#kl-app h1,
#kl-app h5,
#kl-app button {
  font-weight: bold;
}
#kl-app h1 {
  font-size: 3.2rem;
  line-height: 1.2;
}
#kl-app h2 {
  font-size: 2.25rem;
  line-height: 1.4;
}
#kl-app h3 {
  font-size: 1.625rem;
  line-height: 1.4;
}
#kl-app h5,
#kl-app h6,
#kl-app button,
#kl-app label {
  font-size: 1.25rem;
}
#kl-app p {
  color: #5d5d5d;
  line-height: 1.4;
}
#kl-app label {
  color: #5d5d5d;
}
#kl-app h1,
#kl-app h2,
#kl-app h3 {
  color: #3D3D3D;
}
#kl-app hr {
  border-top-color: #5d5d5d;
  margin: 0.5em 0;
}
#kl-app img {
  display: block;
  max-width: 100%;
  height: auto;
}
#kl-app .hidden {
  display: none;
}
#kl-app .img-wrapper {
  overflow: hidden;
  height: 37.5rem;
}
#kl-app .q-img {
  margin: -1.5625rem;
  margin-left: 0;
}
#kl-app .q-img div {
  height: 37.5rem;
  background-position: center;
  background-repeat: no-repeat;
}
#kl-app .start > .soft-row {
  flex-wrap: wrap;
  max-width: 1200px !important;
  margin: 0 auto !important;
}
#kl-app .start .img-wrapper {
  width: 40.75rem;
}
#kl-app .start .q-img {
  margin: -1.5625rem;
}
#kl-app .start .q-img div {
  background-size: contain;
  background-position: bottom right;
}
@media screen and (max-width: 81.875em) {
  #kl-app .start .q-img div {
    background-size: contain;
    background-position: right;
  }
}
@media screen and (max-width: 40.75em) {
  #kl-app .start .img-wrapper {
    width: 100%;
    height: 22.5rem !important;
  }
  #kl-app .start .soft-row .img-wrapper {
    height: 22.5rem !important;
  }
  #kl-app .q-img div {
    height: 22.5rem !important;
  }
}
#kl-app .start p {
  font-size: 1.625rem;
  line-height: 2.25rem;
  padding: 0.9375rem 0;
}
@media screen and (max-width: 28.125em) {
  #kl-app html,
#kl-app body {
    font-size: 12px;
  }
  #kl-app h1 {
    font-size: 2em;
  }
  #kl-app h2 {
    font-size: 1.4em;
  }
}
#kl-app .btn {
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  margin: 0.3125rem 0;
}
#kl-app .btn.blue {
  border: 0.0625rem solid #3D3D3D;
  background-color: #3D3D3D;
  color: #ffffff;
}
#kl-app .btn.white {
  border: 0.0625rem solid #3D3D3D;
  background-color: #ffffff;
  color: #3D3D3D;
}
#kl-app .btn.prev {
  margin-right: 0.625rem;
}
#kl-app .btn:hover,
#kl-app .btn.active {
  border-color: #6BA7DB;
  background-color: #6BA7DB;
  color: #ffffff;
}
#kl-app .nav-wrapper [class*=soft-col] {
  position: relative;
}
#kl-app .nav-wrapper .nav {
  position: absolute;
  bottom: 3.125rem;
  left: 1.5625rem;
}
@media screen and (max-width: 47.5em) {
  #kl-app .img-wrapper {
    display: none;
  }
  #kl-app .start .img-wrapper {
    display: block;
  }
  #kl-app .nav-wrapper .nav {
    position: static;
    padding-top: 0.9375rem;
  }
}
#kl-app .question-wrapper > .col-8 {
  padding: 1.5625rem;
}
#kl-app .question h2 {
  font-size: 1.25rem;
  line-height: 1;
  font-weight: normal;
}
#kl-app .answer,
#kl-app h3 {
  padding: 1.25rem 0;
}
#kl-app .checkbox input[type=radio] {
  display: none;
}
#kl-app .checkbox label {
  display: block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
}
#kl-app .checkbox label:before {
  content: "";
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 0;
  top: 0;
  border: 0.125rem solid #3D3D3D;
}
#kl-app input[type=radio]:checked + label:after {
  content: "";
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #3D3D3D;
}
#kl-app .result-wrapper {
  position: relative;
  margin: 2.5rem 0;
}
#kl-app .result-wrapper .bar {
  position: relative;
  width: 100%;
  height: 1.25rem;
  background-image: linear-gradient(90deg, #3D3D3D 5%, #aadee5 30%, #5dd6ac 95%);
}
#kl-app .result-wrapper .marker {
  position: absolute;
  transform: translate(0, -115%);
}
#kl-app .result-wrapper .m50 {
  left: 50%;
  transform: translate(-50%, -115%);
}
#kl-app .result-wrapper .m100 {
  right: 0;
}
#kl-app .result-wrapper .marker p {
  color: #3D3D3D;
}
#kl-app .result-wrapper .tile {
  position: absolute;
  width: 3.125rem;
  height: 3.75rem;
  top: -0.375rem;
  left: 50%;
  border: 0.375rem solid #3D3D3D;
  border-radius: 0.375rem;
  transform: translateX(-50%);
}
#kl-app .result-wrapper .tile .sub {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 1.25rem);
  bottom: 0;
  left: 0;
  background-color: #3D3D3D;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
}
#kl-app .result-wrapper .tile .sub::after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-bottom-color: #3D3D3D;
  border-width: 0.375rem;
  margin-left: -0.375rem;
}
#kl-app .result-wrapper .tile .sub p {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin: 0 !important;
}
#kl-app .lastrow {
  justify-content: space-between;
}

#footer .container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 20px;
}
@media (max-width: 585px) {
  #footer .container {
    flex-direction: column;
  }
}
#footer .container .greenwebsite img {
  max-width: 200px;
}
#footer .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#footer .footer a {
  display: inline-block;
  margin: 10px;
  text-decoration: none;
  color: #3D3D3D;
}
#footer .footer a img {
  width: auto;
  padding-right: 0 !important;
}
#footer .footer .main-footer {
  padding: 30px 30px;
  text-align: center;
}
#footer .fab {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  #footer {
    padding-bottom: 40px;
  }

  .spacer {
    height: 40px;
  }
}
#impressum {
  max-width: 1000px;
  margin: 80px auto;
  padding: 30px;
}

.datenschutz .app-content {
  padding: 50px 10% !important;
}
.datenschutz .app-content h1 {
  font-family: "Soho W02 Bold" !important;
  font-size: 2em !important;
  margin-bottom: 20px;
}
.datenschutz .app-content h2 {
  font-family: "Soho W02 Bold" !important;
  font-size: 1.6em !important;
  margin-bottom: 20px;
}
.datenschutz .app-content h3 {
  font-family: "Soho Gothic W02 Bold" !important;
  font-size: 1.2em !important;
  margin-bottom: 20px;
}
.datenschutz .app-content p {
  margin-bottom: 30px;
}
.datenschutz .app-content li {
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 10px;
}
.datenschutz .app-content a {
  color: black;
}

#webhint {
  display: none;
}

.cc_banner-wrapper {
  z-index: 999999999999999 !important;
}
.cc_banner-wrapper .cc_container {
  background: #000;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4 !important;
}
.cc_banner-wrapper .cc_container .cc_btn {
  background-color: #F5821F;
  -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, -webkit-box-shadow 200ms ease-in-out;
  transition: background 200ms ease-in-out, color 200ms ease-in-out, -webkit-box-shadow 200ms ease-in-out;
  transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
  transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out, -webkit-box-shadow 200ms ease-in-out;
  -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  color: white;
  max-width: 140px;
}

#datenschutz {
  padding: 40px;
  max-width: 1000px;
  margin: 0 auto;
}
#datenschutz h3 {
  line-height: 1.4 !important;
}

.locationHeader .location {
  background: #efefef;
  position: relative;
  height: 50vw;
  max-height: 650px;
  min-height: 250px;
  width: 100%;
}
.locationHeader .location .image-location {
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #F5821F;
}
.locationHeader .location .image-location.stralsund.contain {
  background-size: contain;
  background-color: #F5821F;
}
@media (max-width: 1860px) {
  .locationHeader .location .image-location.stralsund.contain {
    background-size: cover;
    background-position: center bottom;
  }
}
@media (max-width: 1620px) {
  .locationHeader .location .image-location.stralsund.contain {
    background-position: left calc(100% - 25px);
    background-size: cover;
  }
}
.locationHeader .location .image-location.demmin1 {
  background: url(../img/demmin/demmin-1.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.demmin2 {
  background: url(../img/demmin/demmin-2.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.demmin3 {
  background: url(../img/demmin/demmin-3.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.demmin4 {
  background: url(../img/demmin/demmin-4.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.demmin5 {
  background: url(../img/demmin/demmin-5.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.demmin6 {
  background: url(../img/demmin/demmin-6.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.greifswald1 {
  background: url(../img/greifswald/greifswald-1.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.greifswald2 {
  background: url(../img/greifswald/greifswald-2.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.greifswald3 {
  background: url(../img/greifswald/greifswald-3.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.greifswald4 {
  background: url(../img/greifswald/greifswald-4.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.greifswald5 {
  background: url(../img/greifswald/greifswald-5.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.greifswald6 {
  background: url(../img/greifswald/greifswald-6.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.grimmen1 {
  background: url(../img/grimmen/grimmen-1.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.grimmen2 {
  background: url(../img/grimmen/grimmen-2.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.grimmen3 {
  background: url(../img/grimmen/grimmen-3.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.grimmen4 {
  background: url(../img/grimmen/grimmen-4.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.grimmen5 {
  background: url(../img/grimmen/grimmen-6.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.grimmen6 {
  background: url(../img/grimmen/grimmen-5.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.stralsund1 {
  background: url(../img/stralsund/stralsund-1.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.stralsund2 {
  background: url(../img/stralsund/stralsund-2.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.stralsund3 {
  background: url(../img/stralsund/stralsund-3.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.stralsund4 {
  background: url(../img/stralsund/stralsund-4.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.stralsund5 {
  background: url(../img/stralsund/stralsund-5.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location.stralsund6 {
  background: url(../img/stralsund/stralsund-6.jpg) center;
  background-size: cover;
}
.locationHeader .location .image-location .subtitle {
  color: white;
  display: block;
  background: #333;
  margin: 0 !important;
  padding: 10px;
  text-align: center;
  font-size: 0.825em;
  position: absolute;
  width: 100%;
  bottom: 0;
}
@media (max-width: 650px) {
  .locationHeader .location .image-location .subtitle .subtitle {
    display: none;
  }
  .locationHeader .location .image-location .subtitle .slick-dots {
    bottom: 26%;
  }
}
.locationHeader .text-location {
  width: 100%;
  z-index: 20;
  background: white;
  position: relative;
  padding: 20px;
  margin: 0;
  text-align: center;
}
.locationHeader .text-location h1, .locationHeader .text-location h2 {
  margin-bottom: 20px;
}
.locationHeader .text-location h2 {
  font-size: 18px;
}
.locationHeader .text-location h3 {
  font-size: 14px;
  margin: 0;
}
.locationHeader .text-location .corona-hinweis {
  max-width: 500px;
  margin: 20px auto !important;
}
.locationHeader .text-location p {
  margin: 0 20px 20px 0 !important;
}
.locationHeader .text-location button {
  margin-bottom: 0;
}

.corona-hinweis {
  max-width: 500px;
  margin: 20px auto !important;
}

.closed-info {
  font-weight: bold !important;
  padding: 10px 20px;
  border: 2px solid #F5821F;
  display: inline-block;
  position: relative;
}
.closed-info:before {
  content: "";
  position: absolute;
  width: calc(100% + 11px);
  height: calc(100% + 11px);
  border: inherit;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.team {
  text-align: center;
}
.team h2 {
  font-size: 2.2em;
  line-height: 1.2;
  margin: 0;
}
@media (max-width: 650px) {
  .team h2 {
    font-size: 1.6em;
  }
}
.team h3 {
  font-size: 2.2em;
  line-height: 1.2;
  text-transform: none;
  margin: 0;
  font-weight: 200;
  margin-bottom: 40px;
}
@media (max-width: 650px) {
  .team h3 {
    font-size: 1.6em;
  }
}

.team-slider {
  font-size: 1.5em;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .location-inner {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  .image-location {
    flex: 1;
    position: relative;
  }
}
@media only screen and (max-width: 768px) {
  .location {
    display: block;
    height: auto;
  }
  .location .image-location {
    width: 100%;
    height: 180px;
    background-position: center;
  }

  iframe {
    height: 300px;
  }
}
.back-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  width: 200px;
  margin: 10px auto;
  text-decoration: none;
}
.back-top:hover #arrow {
  top: -10px;
}
.back-top #arrow {
  position: relative;
  max-width: 20px;
  top: 0;
  transition: all 300ms ease;
}
.back-top p {
  margin: 10px !important;
  color: #3D3D3D;
}

@media only screen and (max-width: 768px) {
  .location .text-location {
    border: 10px solid #efefef;
    position: relative;
    left: 0;
    top: 50%;
    bottom: 0;
    right: 0;
    width: 100%;
  }
}
.standort-optik-akustik .softrow,
.standort-optik .softrow {
  display: flex;
  flex-wrap: wrap;
}
.standort-optik-akustik .softrow .col-1,
.standort-optik .softrow .col-1 {
  flex: 1 0 0;
  margin: 10px;
  min-width: 300px;
  max-width: 100%;
}
@media (max-width: 480px) {
  .standort-optik-akustik .softrow .col-1,
.standort-optik .softrow .col-1 {
    min-width: 100%;
    margin: 10px 0;
  }
}
.standort-optik-akustik .hoeren-sehen .image-wrapper,
.standort-optik .hoeren-sehen .image-wrapper {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.standort-optik-akustik .hoeren-sehen .image-wrapper i,
.standort-optik .hoeren-sehen .image-wrapper i {
  font-size: 4rem;
  color: white;
}
.standort-optik-akustik .hoeren-sehen strong,
.standort-optik .hoeren-sehen strong {
  font-size: 1em;
  margin: 0;
  font-weight: 200;
  background: #F5821F;
  display: block;
  color: #fff;
  padding: 5px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.standort-optik-akustik .oeffnungszeiten .softrow,
.standort-optik .oeffnungszeiten .softrow {
  margin-top: 20px;
}
.standort-optik-akustik .oeffnungszeiten .softrow .col-1,
.standort-optik .oeffnungszeiten .softrow .col-1 {
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.standort-optik-akustik .terminvereinbarung img,
.standort-optik .terminvereinbarung img {
  margin-top: 20px;
}
.standort-optik-akustik .h3, .standort-optik-akustik h3,
.standort-optik .h3,
.standort-optik h3 {
  font-size: 1.9rem;
  line-height: 1.2;
  text-transform: none;
  margin: 0;
  font-weight: 200;
}
@media (max-width: 650px) {
  .standort-optik-akustik .h3, .standort-optik-akustik h3,
.standort-optik .h3,
.standort-optik h3 {
    font-size: 1.6rem;
  }
}
.standort-optik-akustik h2,
.standort-optik h2 {
  font-size: 2.2rem;
  line-height: 1.2;
  margin: 0;
}
@media (max-width: 650px) {
  .standort-optik-akustik h2,
.standort-optik h2 {
    font-size: 1.6rem;
  }
}
.standort-optik-akustik .button,
.standort-optik .button {
  margin-bottom: 0;
}

.standort-optik .softrow {
  display: flex;
}
.standort-optik .softrow .col-1 {
  flex: 1 0 0;
  margin: 10px auto;
  min-width: 300px;
  max-width: 50%;
}
@media (max-width: 699px) {
  .standort-optik .softrow .col-1 {
    flex-basis: 100%;
  }
}
@media (max-width: 480px) {
  .standort-optik .softrow .col-1 {
    min-width: 100%;
    margin: 10px 0;
  }
}

#forsa-header img {
  max-height: 700px;
  object-fit: cover;
  object-position: bottom center;
}

.centered__content {
  text-align: center;
}

.grey__row {
  background: #EFEFEF;
}
@media (min-width: 840px) {
  .grey__row .soft-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.grey__row .soft-row .col-2 {
  flex: 1 0 65%;
  order: 1;
}
.grey__row .soft-row .col-1 {
  flex: 1 0 25%;
  order: 2;
}

.checklist.forsalist {
  margin: 2rem 0;
}
.checklist.forsalist li {
  margin-left: 0;
  list-style-type: none;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0;
  padding-left: 30px;
  position: relative;
}
.checklist.forsalist li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  background: url("../img/hoerakustik/icn-check.svg") left center no-repeat;
  background-size: contain;
}

.stoerer-rot {
  background: #AD2421;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 180px;
  height: 180px;
  min-width: 180px;
  margin: 0 0 20px auto;
}
@media (min-width: 1040px) {
  .stoerer-rot {
    margin: 20px;
    padding: 20px;
    width: 220px;
    height: 220px;
    min-width: 220px;
  }
}
.stoerer-rot p {
  color: white;
  text-align: center;
  font-weight: 400;
  line-height: 1.4;
  font-size: 18px;
}
@media (min-width: 1040px) {
  .stoerer-rot p {
    font-size: 23px;
  }
}

.hoergeraete__row {
  margin-top: 40px;
}
@media (min-width: 740px) {
  .hoergeraete__row {
    display: flex;
    justify-content: space-between;
  }
}
.hoergeraete__row .col {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hoergeraete__row .col img {
  max-width: 300px;
}

#service-header {
  background: #EFEFEF;
}
#service-header .headerImage {
  background: url(../img/headerService.jpg) center no-repeat;
  background-size: cover;
}
#service-header .headerText {
  max-width: 750px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

#service-punkte {
  background: white;
  padding: 20px;
}
#service-punkte .soft-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 850px;
  margin: 0 auto;
}
#service-punkte .iconWrapper {
  text-align: center;
  margin: 20px;
  min-width: 20%;
  max-width: 100%;
}
#service-punkte .iconWrapper strong {
  margin: 10px 0 0 0 !important;
  display: block;
}
#service-punkte .iconWrapper p {
  margin: 0 !important;
  max-width: 240px;
  font-size: 0.925em;
}
#service-punkte .iconWrapper .icon {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background: #F5821F;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
#service-punkte .iconWrapper .icon img {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#service-punkte .iconWrapper .icon:first-child .icon img {
  top: 0;
  transform: translate(-50%, 0);
}
#service-punkte .iconWrapper .icon:nth-child(2) .icon img {
  top: auto;
  bottom: 0px;
  transform: translate(-30%, 0);
}
#service-punkte .iconWrapper .icon:nth-child(3) .icon img {
  width: 50%;
  height: 50%;
}
#service-punkte .iconWrapper .icon:nth-child(4) .icon img {
  transform: translate(-45%, -55%);
}
#service-punkte .iconWrapper .icon:nth-child(5) .icon img {
  width: 70%;
  height: 70%;
}
#service-punkte .iconWrapper .icon:nth-child(6) .icon img {
  width: 50%;
  height: 50%;
}
@media (max-width: 650px) {
  #service-punkte .iconWrapper {
    max-width: 100%;
    min-width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: flex-start;
  }
  #service-punkte .iconWrapper .icon {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    margin: 0 20px 0 0;
  }
  #service-punkte .iconWrapper strong {
    line-height: 1.4;
    text-align: left;
  }
  #service-punkte .iconWrapper p {
    max-width: none;
    font-size: 0.925em;
    text-align: left;
    max-width: none;
  }
}

#werkstatt {
  margin-bottom: 80px;
}
#werkstatt #werkstattSlider {
  max-width: 750px;
  margin: 0 auto;
}
#werkstatt .slider-nav img {
  max-width: 100%;
}
#werkstatt .slider-for img {
  width: 100%;
}
#werkstatt .slider-nav slick-slide:hover {
  cursor: pointer;
}
#werkstatt .slick-prev, #werkstatt .slick-next {
  position: absolute;
  top: 50%;
  margin-top: 0px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
}
#werkstatt .slick-prev:before, #werkstatt .slick-next:before {
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 60px;
  opacity: 1;
  width: 30px;
}
#werkstatt .slick-prev:hover:before, #werkstatt .slick-next:hover:before {
  opacity: 0.8;
}

#kontaktlinsen-header {
  background: #EFEFEF;
}
#kontaktlinsen-header .headerImage {
  width: 100%;
  height: 50vw;
  min-height: 300px;
  max-height: 500px;
  background: url(../img/headerKontaktlinsen.jpg) center no-repeat;
  background-size: cover;
}
#kontaktlinsen-header .headerText {
  max-width: 750px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

#kontaktlinsen-toggle {
  background: white;
  padding: 20px;
}
#kontaktlinsen-toggle .toggle {
  padding: 0;
}
#kontaktlinsen-toggle .soft-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 650px;
  margin: 0 auto;
}
#kontaktlinsen-toggle .soft-row dl {
  border: 1px solid #EFEFEF;
  margin-bottom: 20px;
}
#kontaktlinsen-toggle .soft-row dl dt {
  font-weight: bold;
  font-size: 1.2em;
  padding: 15px 20px;
  background: #EFEFEF;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 300ms ease;
}
#kontaktlinsen-toggle .soft-row dl dt:hover {
  background: #e5e5e5;
}
#kontaktlinsen-toggle .soft-row dl dt:after {
  content: "+";
  position: absolute;
  width: 30px;
  height: 30px;
  background: #F5821F;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-weight: 300;
  font-size: 1.4em;
}
#kontaktlinsen-toggle .soft-row dl dd {
  height: 0;
  overflow: hidden;
  transition: all 300ms ease;
  padding: 0px 20px;
}
#kontaktlinsen-toggle .soft-row dl.toggleActive {
  transition: all 300ms ease;
}
#kontaktlinsen-toggle .soft-row dl.toggleActive dt {
  background: white;
}
#kontaktlinsen-toggle .soft-row dl.toggleActive dt:after {
  content: "–";
  line-height: 26px;
}
#kontaktlinsen-toggle .soft-row dl.toggleActive dd {
  height: 100%;
  padding: 10px 20px;
}
#kontaktlinsen-toggle .kontaktlinsenImg {
  margin: 0 auto;
  max-width: 650px;
}
#kontaktlinsen-toggle .kontaktlinsenImg img {
  max-width: 650px;
}

#kontaktlinsen-partner .soft-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 650px;
  margin: 0 auto;
}
#kontaktlinsen-partner .soft-row .kl-partner {
  margin: 20px;
  max-width: 180px;
  min-width: 150px;
  flex: 1 0 0;
  height: 90px;
  background: red;
}
#kontaktlinsen-partner .soft-row #kl1 {
  background: url(../img/kl-logos/bl.svg) center no-repeat;
  background-size: contain;
}
#kontaktlinsen-partner .soft-row #kl2 {
  background: url(../img/kl-logos/alcon.svg) center no-repeat;
  background-size: contain;
}
#kontaktlinsen-partner .soft-row #kl3 {
  background: url(../img/kl-logos/coopervision.jpg) center no-repeat;
  background-size: contain;
}
#kontaktlinsen-partner .soft-row #kl4 {
  background: url(../img/kl-logos/jenalens.png) center no-repeat;
  background-size: contain;
}
#kontaktlinsen-partner .soft-row #kl5 {
  background: url(../img/kl-logos/galifa.png) center no-repeat;
  background-size: contain;
}
#kontaktlinsen-partner .soft-row #kl6 {
  background: url(../img/kl-logos/sauflon.png) center no-repeat;
  background-size: contain;
}
#kontaktlinsen-partner .soft-row #kl7 {
  background: url(../img/kl-logos/hecht.png) center no-repeat;
  background-size: contain;
}
#kontaktlinsen-partner .soft-row #kl8 {
  background: url(../img/kl-logos/johnsonjohnson.svg) center no-repeat;
  background-size: contain;
}

#mitglied {
  margin: 100px 0;
}
#mitglied p {
  text-align: center;
}
#mitglied img {
  width: 100px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: block;
}

.info--box {
  background: white;
  margin-bottom: 2rem;
  padding: 1rem;
  padding-top: 2rem;
  border-top: 4px solid #F5821F;
  border-radius: 5px;
}
.info--box h3 {
  text-transform: uppercase;
  font-weight: bold;
}

.headerImage {
  width: 100%;
  height: 50vw;
  min-height: 300px;
  max-height: 500px;
  background: url(../img/headerGleitsicht.jpg) center 40% no-repeat;
  background-size: cover;
}
@media (min-width: 1800px) {
  .headerImage {
    max-height: 800px;
  }
}

#gleitsicht-header {
  background: #EFEFEF;
}
#gleitsicht-header .headerText {
  max-width: 750px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

.stichpunkt {
  font-weight: 900;
  font-size: 1rem;
}

#quali-header {
  background: #EFEFEF;
}
#quali-header .headerImage {
  background: url(../img/headerQuali.jpg) center no-repeat;
  background-size: cover;
}
#quali-header .headerText {
  max-width: 750px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

.quali-gleitsicht {
  margin-top: 30px;
  padding: 20px;
}
.quali-gleitsicht .content {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}

.soft-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 850px;
  margin: 0 auto;
}
.soft-row .gleitsichtGlas {
  text-align: center;
  max-width: 190px;
  min-width: 150px;
  width: 40%;
  margin: 10px;
}
.soft-row .gleitsichtGlas img {
  max-width: 100%;
}
.soft-row .gleitsichtGlas h4 {
  margin-top: -15px;
  line-height: 1.4;
}

.gl-logos {
  margin: 60px auto;
  align-items: center;
  max-width: 750px;
}
.gl-logos div {
  width: 30%;
  text-align: center;
  margin: 10px;
  min-width: 200px;
}
.gl-logos div:nth-child(1) img {
  width: 167px;
  height: 33px;
}

#gl-tool {
  margin: 0 auto;
  max-width: 1000px;
  height: 150vw;
  position: relative;
  max-height: 900px;
}
#gl-tool iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#zitat {
  margin: 60px auto;
  background: #EFEFEF;
  padding: 90px 30px;
}
#zitat .zitat {
  max-width: 500px;
  margin: 0 auto;
  background: #333333;
  padding: 20px;
  position: relative;
}
#zitat .zitat:before {
  content: "";
  background: white url(../img/mitarbeiter/Zitat-8.png) center no-repeat;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 2px solid #F5821F;
}
#zitat .zitat .soft-row {
  display: flex;
  justify-content: center;
  flex-wrap: no-wrap;
  align-items: center;
}
#zitat .zitat .soft-row .image {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background: white;
  border: 1px solid white;
  margin-right: 20px;
  margin: 0 auto;
}
#zitat .zitat .soft-row .image.zimmermann {
  background: url(../img/mitarbeiter/jan-zimmermann.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.alexa_zimmermann {
  background: url(../img/mitarbeiter/alexa-zimmermann.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.matthes {
  background: url(../img/mitarbeiter/elisa-matthes.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.moltzahn {
  background: url(../img/mitarbeiter/tina-moltzahn.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.herzig {
  background: url(../img/mitarbeiter/angela-herzig.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.schmidt {
  background: url(../img/mitarbeiter/anne-schmidt.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.korn {
  background: url(../img/mitarbeiter/carola-korn.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.urgast {
  background: url(../img/mitarbeiter/maren-urgast.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.odebrecht {
  background: url(../img/mitarbeiter/claudia-odebrecht.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.schur {
  background: url(../img/mitarbeiter/stefan-schur.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.senfft {
  background: url(../img/mitarbeiter/jana-senfft-mueller.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.mockschan {
  background: url(../img/mitarbeiter/andrea-mockschan.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .image.klockow {
  background: url(../img/mitarbeiter/karsten-klockow.jpg) center;
  background-size: cover;
}
#zitat .zitat .soft-row .textWrapper {
  max-width: 200px;
  margin: 0 auto;
}
#zitat .zitat .soft-row .textWrapper p {
  color: white;
  font-size: 0.925em;
}
@media (max-width: 650px) {
  #zitat .zitat .soft-row {
    padding-top: 40px;
    display: block;
    text-align: center;
  }
  #zitat .zitat .soft-row .image {
    margin: 0 auto;
  }
}

.people .section-inner .subtitle2 {
  color: white;
  display: block;
  background: #333;
  margin: 0 !important;
  padding: 10px;
  text-align: center;
  font-size: 0.825em;
  width: 100%;
}
@media (max-width: 650px) {
  .people .section-inner .subtitle2 {
    display: none;
  }
  .people .section-inner .slick-dots {
    bottom: 26%;
  }
}

.headerImage {
  position: relative;
}
.headerImage .subtitle3 {
  color: white;
  display: block;
  background: #333;
  margin: 0 !important;
  padding: 10px;
  text-align: center;
  font-size: 0.825em;
  position: absolute;
  width: 100%;
  bottom: 0;
}
@media (max-width: 650px) {
  .headerImage .subtitle3 {
    display: none;
  }
  .headerImage .slick-dots {
    bottom: 26%;
  }
}

.teamContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.teamContainer .mitarbeiter {
  width: 280px;
  max-width: 100%;
  margin: 10px;
  cursor: pointer;
}
.teamContainer .mitarbeiter:not(.hasContent) .lupe {
  display: none;
}
@media (max-width: 650px) {
  .teamContainer .mitarbeiter {
    width: auto;
    flex: 1 0 0;
    min-width: 150px;
  }
}
.teamContainer .mitarbeiter .lupe {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  background: #f5811f;
  transition: all 100ms ease;
}
.teamContainer .mitarbeiter.hasContent .lupe {
  display: block;
}
.teamContainer .mitarbeiter .bild {
  transition: all 300ms ease;
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid #cdcdcd;
  transform: rotateY(0deg);
  z-index: 0;
}
.teamContainer .mitarbeiter .bild .imageMitarbeiter {
  height: 0;
  padding-bottom: 100%;
  width: auto;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  background-size: cover !important;
}
.teamContainer .mitarbeiter .bild .bild-name {
  background: #333333;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  min-height: 68px;
  transition: all 300ms ease;
}
.teamContainer .mitarbeiter .bild .bild-name p {
  color: white;
  padding: 0;
  margin: 0 !important;
  font-size: 0.85em;
  line-height: 1.2;
}
.teamContainer .mitarbeiter .bild .bild-name strong {
  font-size: 1.2em;
}
.teamContainer .mitarbeiter:hover .lupe {
  padding: 7px;
}
.teamContainer .mitarbeiter:hover .bild-name {
  padding: 30px 10px !important;
}

#overlay, #overlay-audio, #overlay-video {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 100000000000000030000000;
}
#overlay.active, #overlay-audio.active, #overlay-video.active {
  display: block;
}
#overlay .popupContent, #overlay-audio .popupContent, #overlay-video .popupContent {
  position: fixed;
  left: 30px;
  top: 30px;
  bottom: 30px;
  right: 30px;
  background: white;
  z-index: 999999999999999;
  display: flex;
  flex-wrap: wrap;
  display: none;
}
#overlay .popupContent.active, #overlay-audio .popupContent.active, #overlay-video .popupContent.active {
  display: flex;
}
#overlay .popupContent .popupImage, #overlay-audio .popupContent .popupImage, #overlay-video .popupContent .popupImage {
  width: 50%;
  height: 100%;
}
#overlay .popupContent img, #overlay-audio .popupContent img, #overlay-video .popupContent img {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 14px;
  cursor: pointer;
  padding: 10px;
  background: #f5811f;
  transition: all 100ms ease;
}
#overlay .popupContent img:hover, #overlay-audio .popupContent img:hover, #overlay-video .popupContent img:hover {
  padding: 7px;
}
#overlay .popupContent .popupText, #overlay-audio .popupContent .popupText, #overlay-video .popupContent .popupText {
  background: white;
  z-index: 99999999999;
  padding: 5%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 50%;
  position: relative;
}
#overlay .popupContent .popupText h2, #overlay-audio .popupContent .popupText h2, #overlay-video .popupContent .popupText h2 {
  font-size: 2.5em;
  text-transform: none;
}
#overlay .popupContent .popupText h3, #overlay-audio .popupContent .popupText h3, #overlay-video .popupContent .popupText h3 {
  font-size: 2em;
  line-height: 1.2em;
}
#overlay .popupContent .popupText p strong, #overlay-audio .popupContent .popupText p strong, #overlay-video .popupContent .popupText p strong {
  font-size: 2em;
}
#overlay .popupContent .popupText .telefon, #overlay-audio .popupContent .popupText .telefon, #overlay-video .popupContent .popupText .telefon {
  position: relative;
  margin-left: 30px !important;
  display: block;
}
#overlay .popupContent .popupText .telefon:before, #overlay-audio .popupContent .popupText .telefon:before, #overlay-video .popupContent .popupText .telefon:before {
  content: "";
  background: url("../img/mitarbeiter/Telefon.png") center no-repeat;
  width: 24px;
  height: 20px;
  background-size: contain;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
#overlay .popupContent .popupText .telefon:hover, #overlay-audio .popupContent .popupText .telefon:hover, #overlay-video .popupContent .popupText .telefon:hover {
  color: #F5821F;
}
#overlay .popupContent .popupText .email, #overlay-audio .popupContent .popupText .email, #overlay-video .popupContent .popupText .email {
  position: relative;
  margin-left: 30px !important;
  display: block;
}
#overlay .popupContent .popupText .email:before, #overlay-audio .popupContent .popupText .email:before, #overlay-video .popupContent .popupText .email:before {
  content: "";
  background: url("../img/mitarbeiter/Email-8.png") center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
#overlay .popupContent .popupText .email:hover, #overlay-audio .popupContent .popupText .email:hover, #overlay-video .popupContent .popupText .email:hover {
  color: #F5821F;
}
@media (max-width: 1000px) {
  #overlay .popupContent .popupImage, #overlay .popupContent .popupText, #overlay-audio .popupContent .popupImage, #overlay-audio .popupContent .popupText, #overlay-video .popupContent .popupImage, #overlay-video .popupContent .popupText {
    width: 100%;
    height: 50%;
  }
}

#zitat {
  display: none;
}

#overlay-audio.audio-popup.active, #overlay-video.video-popup.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#overlay-audio.audio-popup .popupContent, #overlay-video.video-popup .popupContent {
  display: block !important;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
}
#overlay-audio.audio-popup .popupText, #overlay-video.video-popup .popupText {
  overflow-y: hidden;
  width: 100%;
  text-align: center;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#overlay-audio.audio-popup .popupText audio, #overlay-audio.audio-popup .popupText h3, #overlay-video.video-popup .popupText audio, #overlay-video.video-popup .popupText h3 {
  margin-top: 30px;
}
#overlay-audio.audio-popup .popupText video, #overlay-video.video-popup .popupText video {
  padding: 20px;
  max-height: 60vh;
  width: 100%;
}
#overlay-audio.audio-popup .popupText p, #overlay-video.video-popup .popupText p {
  margin-top: 10px;
  font-size: 0.9rem;
}
#overlay-audio.audio-popup .popupText a, #overlay-video.video-popup .popupText a {
  word-break: break-word;
  font-size: 0.9rem;
}

.standorteContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.standorteContainer .standort {
  width: 400px;
  max-width: 100%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 150px;
  max-height: 350px;
  height: 20vw;
  background-size: cover;
  background-position: center;
  transition: all 300ms ease;
}
.standorteContainer .standort h3 {
  background: #F5821F;
  transition: all 300ms ease;
  color: white;
  font-size: 1em;
  width: 100%;
  padding: 5px 20px;
}
.standorteContainer .standort:hover h3 {
  padding: 10px 20px;
}
.standorteContainer .standorte {
  width: 400px;
  max-width: 100%;
  margin: 10px;
  display: block;
}
.standorteContainer .standorte .bild {
  transition: all 300ms ease;
  position: relative;
  border-bottom: 2px solid #cdcdcd;
  z-index: 0;
}
.standorteContainer .standorte .bild .imageMitarbeiter {
  height: 0;
  padding-bottom: 70%;
  width: auto;
  position: relative;
  background-size: cover !important;
  display: block;
}
.standorteContainer .standorte .bild .bild-name {
  background: #333333;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  left: 0;
  padding: 20px;
}
.standorteContainer .standorte .bild .bild-name p {
  color: white;
  padding: 0;
  margin: 0 !important;
  font-size: 0.85em;
  line-height: 1.4;
}
.standorteContainer .standorte .bild .bild-name a {
  color: white !important;
}
.standorteContainer .standorte .bild .bild-name a:hover {
  color: #F5821F !important;
}
.standorteContainer .standorte .bild .bild-name strong {
  font-size: 1.2em;
}

.vr {
  width: 100vw;
  height: 100vh;
}

.icn-360 {
  width: 40px;
  height: 40px;
  border: 2px solid #F5821F;
  display: block;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  position: relative;
  transition: 300ms ease;
}
.icn-360 span {
  border: 1px solid #bbb;
  border-radius: 50%;
  display: block;
  position: absolute;
  transition: 300ms ease;
}
.icn-360 span:first-child {
  width: 50%;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%) rotateY(0);
}
.icn-360 span:first-child:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid #bbb;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  top: 50%;
  right: -3px;
  transform: translateY(-50%);
}
.icn-360 span:last-child {
  width: 100%;
  height: 50%;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotateX(0);
}
.icn-360 span:last-child:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid #bbb;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}
.icn-360:hover {
  transform: rotate(180deg);
}
.icn-360:hover span:last-child {
  transform: translateY(-50%) rotateX(180deg);
}
.icn-360:hover span:first-child {
  transform: translateX(-50%) rotateY(180deg);
}

.download {
  margin-top: 3rem;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  background: linear-gradient(to top, #EFEFEF, #f7f7f7);
  border: 1px solid #e2e2e2;
  border-top-color: white;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.01);
}
.download:before, .download:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 47%;
  border-radius: inherit;
  bottom: 0;
  height: 80%;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
}
.download:before {
  left: 10px;
  transform-origin: bottom right;
  transform: rotate(-2deg) translateY(-7px);
}
.download:after {
  right: 10px;
  transform-origin: bottom left;
  transform: rotate(2deg) translateY(-7px);
}

#sonnenbrillen-header .headerImage {
  background: url(../img/headerSonnenbrille.jpg) center 35% no-repeat;
  background-size: cover;
}
#sonnenbrillen-header .headerText {
  max-width: 750px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

#so-tool {
  margin: 0 auto;
  max-width: 1000px;
  height: 150vw;
  position: relative;
  max-height: 900px;
}
#so-tool iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#sonnenbrille {
  margin-top: 30px;
  padding: 20px;
}
#sonnenbrille .content {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}

.sonnenbrillen-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.sonnenbrillen-content .col {
  flex: 1 0 0;
  min-width: 300px;
  max-width: 100%;
  margin: 30px 10px;
  text-align: center;
}
.sonnenbrillen-content .col .inner {
  max-width: 750px;
  margin: 0 auto;
  min-width: 250px;
}
.sonnenbrillen-content .col .inner p {
  color: black !important;
}
.sonnenbrillen-content .col .inner #sonnenbrillen-schutz {
  background: url(../img/sonnenbrillen/icon-sun.svg) center no-repeat;
  background-color: #F5821F;
  background-size: auto;
  height: 250px;
}
.sonnenbrillen-content .col .inner h3 {
  margin: 0;
  font-size: 1.6em;
  line-height: 1.4;
  background: white;
  padding: 5px;
}
.sonnenbrillen-content .col .inner p {
  padding: 20px;
  margin: 0 !important;
}

.sternegeben .termin-button {
  display: none;
}

#sternegeben .title {
  text-align: center;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
}
#sternegeben .fb {
  background: #41559F;
}
#sternegeben .goo {
  background: #2585FC;
}
#sternegeben .yel {
  background: #DF0219;
}
#sternegeben i {
  margin-right: 10px;
  font-family: "Font Awesome 5 Brands";
}
#sternegeben * {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
#sternegeben h1 {
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 1.3;
  margin-bottom: 30px;
}
#sternegeben h3 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.3;
  margin-bottom: 30px;
  color: #F5821F;
}
#sternegeben h4 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 30px;
  letter-spacing: 0;
  line-height: 1.2;
}
#sternegeben p, #sternegeben a {
  font-size: 1rem;
  line-height: 1.6;
}
#sternegeben .main-wrapper {
  width: 100%;
  position: relative;
}
#sternegeben .headerSternegeben {
  width: 100%;
  height: 40em;
  background: linear-gradient(#F5821F, #F5821F);
  position: relative;
}
#sternegeben .headerSternegeben .star {
  width: 50%;
  height: 500px;
  background-image: url(../img/sternegeben/sterne.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  right: 0;
  top: 43%;
  transform: translateY(-50%);
  z-index: 0;
}
#sternegeben .headerSternegeben .headline {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  top: 42%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  left: 5%;
  z-index: 1;
}
#sternegeben .headerSternegeben .headline h2 {
  color: white;
  font-weight: 300;
  font-size: 1.6rem;
  max-width: 70%;
}
#sternegeben .headerSternegeben .headline h1 {
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  color: white;
  max-width: 70%;
}
#sternegeben nav {
  position: fixed;
  z-index: 99999999;
  width: 100%;
}
#sternegeben nav .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 80px;
  top: 0;
  position: absolute;
  z-index: 9999999;
  left: 5%;
  padding: 10px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15), 1px 1px 15px rgba(0, 0, 0, 0.2);
}
#sternegeben nav .logo svg {
  color: black;
}
#sternegeben nav .navbar {
  position: absolute;
  width: 100%;
  background: rgba(245, 130, 31, 0.9);
  height: 80px;
  left: 0px;
  z-index: 0;
  top: 0;
}
#sternegeben .content-wrapper {
  position: relative;
  background: #fff;
  z-index: 5;
}
#sternegeben .content {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 4vw 5%;
  z-index: 100;
  position: relative;
}
#sternegeben .btn {
  position: relative;
  background: gold;
  padding: 10px 25px;
  display: inline-block;
  font-weight: 600;
  color: #333;
  border-radius: 2px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
#sternegeben .btn:hover {
  background: #ffdf33;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
}
#sternegeben .grau {
  background: #ededed;
  position: relative;
}
#sternegeben .grau .content {
  padding: 80px 5%;
}
#sternegeben .grau .content h4 {
  color: #F5821F;
  text-align: center;
  text-transform: uppercase;
}
#sternegeben .bubble {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  border-top: 30px solid white;
  border-left: 30px solid #efefef;
  border-right: 30px solid #efefef;
}
#sternegeben #danke .flex-wrapper {
  align-items: center;
}
#sternegeben .flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
#sternegeben .flex-wrapper .col-1 {
  flex: 5;
  padding-right: 60px;
  min-width: 300px;
}
#sternegeben .flex-wrapper .col-2 {
  flex: 2;
  background: #efefef;
  padding: 20px;
  position: relative;
  min-width: 300px;
}
#sternegeben .flex-wrapper .col-2 p {
  margin-bottom: 15px;
}
#sternegeben .flex-wrapper .col-2 #img-sterntaler {
  width: calc(100% + 40px);
  top: -20px;
  height: 200px;
  left: -20px;
  background-image: url(../img/sternegeben/sterntaler.png), linear-gradient(#F5821F, #F5821F);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
#sternegeben .flex-wrapper .col-2 h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
#sternegeben .img {
  width: 100%;
  height: 200px;
  background: radial-gradient(#666, #333);
}
#sternegeben #img-demmin, #sternegeben #img-grimmen, #sternegeben #img-greifswald, #sternegeben #img-stralsund {
  width: 100%;
  height: 250px;
}
#sternegeben .flex-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 10px;
  min-width: 280px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
#sternegeben .text-wrap {
  padding: 30px;
  text-align: center;
}
#sternegeben .text-wrap p {
  margin-top: 30px;
  color: #666;
  font-weight: 300;
  font-size: 0.925rem;
  text-align: left;
}
#sternegeben .flex-item:first-child {
  margin: 10px 10px 10px 0;
}
#sternegeben .flex-item:first-child:last-child {
  margin-left: 10px 0 10px 10px;
}
#sternegeben .google {
  background: white;
}
#sternegeben .google .img-g-greifswald {
  background-image: url(../img/sternegeben/google_greifswald.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .google .img-g-grimmen {
  background-image: url(../img/sternegeben/google_grimmen.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .google .img-g-stralsund {
  background-image: url(../img/sternegeben/google_stralsund.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .google .img-g-demmin {
  background-image: url(../img/sternegeben/google_demmin.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .google .btn {
  background: #2585FC;
  color: white;
  font-weight: 400;
}
#sternegeben .google .btn:hover {
  background: #3e93fc;
}
#sternegeben .facebook {
  background: white;
}
#sternegeben .facebook .img-fb-grimmen {
  background-image: url(../img/sternegeben/facebook_grimmen.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .facebook .img-fb-greifswald {
  background-image: url(../img/sternegeben/facebook_greifswald.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .facebook .img-fb-stralsund {
  background-image: url(../img/sternegeben/facebook_stralsund.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .facebook .img-fb-demmin {
  background-image: url(../img/sternegeben/facebook_demmin.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .facebook .btn {
  background: #41559F;
  color: white;
  font-weight: 400;
}
#sternegeben .facebook .btn:hover {
  background: #485fb1;
}
#sternegeben .yelp {
  background: white;
}
#sternegeben .yelp .img-y-greifswald {
  background-image: url(../img/sternegeben/yelp_greifswald.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .yelp .btn {
  background: #DF0219;
  color: white;
  font-weight: 400;
}
#sternegeben .yelp .btn:hover {
  background: #f8021c;
}
#sternegeben .filiale {
  background: white;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  color: white;
}
#sternegeben .filiale p {
  color: black;
  font-size: 1.2rem;
  text-align: center;
  margin: 0 !important;
}
#sternegeben .filiale p:hover {
  color: #F5821F;
}
#sternegeben .filiale .text-wrap {
  background: white;
}
#sternegeben .filiale #img-grimmen {
  background-image: url(../img/standorte/grimmen-3.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .filiale #img-greifswald {
  background-image: url(../img/standorte/D04590-040.jpg);
  background-size: cover;
  background-position: top center;
}
#sternegeben .filiale #img-stralsund {
  background-image: url("../img/standorte/D04328-005.jpg");
  background-size: cover;
  background-position: bottom center;
}
#sternegeben .filiale #img-demmin {
  background-image: url(../img/standorte/demmin-4.jpg);
  background-size: cover;
  background-position: top center;
}
@media (max-width: 768px) {
  #sternegeben .flex-wrapper {
    display: block;
  }
  #sternegeben .flex-wrapper .flex-item {
    margin: 0 0 20px 10px;
  }
  #sternegeben .flex-wrapper .col-1 {
    padding-right: 0px !important;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  #sternegeben .flex-wrapper .col-1 h3 {
    font-size: 2em;
  }
  #sternegeben .header {
    height: 32em;
  }
  #sternegeben .header .headline {
    width: 90%;
  }
  #sternegeben .header .headline h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  #sternegeben .header .headline h1 {
    font-size: 2rem;
  }
}
#sternegeben .auswahl {
  text-align: center;
  margin: 0 auto;
}
#sternegeben .auswahl select {
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  border: none;
  background: white;
  margin: 10px;
  padding: 5px;
}
#sternegeben .auswahl select option {
  padding: 5px;
  color: grey;
}

#arbeitsplatz-header {
  background: #EFEFEF;
}
#arbeitsplatz-header .headerImage {
  background: url(../img/headerArbeitsplatz.jpg) center 20% no-repeat;
  background-size: cover;
}
@media (max-width: 1500px) {
  #arbeitsplatz-header .headerImage {
    background-position: center 35%;
  }
}
#arbeitsplatz-header .headerText {
  max-width: 750px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

.arbeitsplatzbrillen, .sehtraining {
  margin-top: 30px;
  padding: 20px;
}
.arbeitsplatzbrillen .content, .sehtraining .content {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}
.arbeitsplatzbrillen .soft-row, .sehtraining .soft-row {
  display: flex;
  flex-wrap: wrap;
}
.arbeitsplatzbrillen .soft-row .col-1, .sehtraining .soft-row .col-1 {
  flex: 1 0 0;
  padding: 10px;
  min-width: 300px;
}
.arbeitsplatzbrillen .soft-row .col-1 p, .sehtraining .soft-row .col-1 p {
  margin-top: 0 !important;
}
.arbeitsplatzbrillen .reverse, .sehtraining .reverse {
  flex-wrap: wrap-reverse;
}

.toggle {
  background: white;
  padding: 20px;
}
.toggle .soft-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 650px;
  margin: 0 auto;
}
.toggle .soft-row dl {
  border: 1px solid #EFEFEF;
  margin-bottom: 20px;
}
.toggle .soft-row dl dt {
  font-weight: bold;
  font-size: 1.2em;
  padding: 15px 20px;
  background: #EFEFEF;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 300ms ease;
}
.toggle .soft-row dl dt:hover {
  background: #e5e5e5;
}
.toggle .soft-row dl dt:after {
  content: "+";
  position: absolute;
  width: 30px;
  height: 30px;
  background: #F5821F;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-weight: 300;
  font-size: 1.4em;
}
.toggle .soft-row dl dd {
  height: 0;
  overflow: hidden;
  transition: all 300ms ease;
  padding: 0px 20px;
}
.toggle .soft-row dl dd ul {
  margin-left: 20px;
}
.toggle .soft-row dl.toggleActive {
  transition: all 300ms ease;
}
.toggle .soft-row dl.toggleActive dt {
  background: white;
}
.toggle .soft-row dl.toggleActive dt:after {
  content: "–";
  line-height: 26px;
}
.toggle .soft-row dl.toggleActive dd {
  height: 100%;
  padding: 10px 20px;
}

#sehtraining-header .menu--desktop {
  width: 100%;
  background: #333;
  height: 60px;
}
#sehtraining-header .menu--desktop ul {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}
#sehtraining-header .menu--desktop li {
  list-style: none;
  padding: 0 12px;
  height: 60px;
  display: flex;
  align-items: center;
}
#sehtraining-header .menu--desktop li a {
  color: white;
  text-align: center;
}
@media (max-width: 1100px) {
  #sehtraining-header .menu--desktop li a {
    font-size: 12px;
  }
}
#sehtraining-header .menu--desktop li:hover {
  background: #F5821F;
}
@media (max-width: 1000px) {
  #sehtraining-header .menu--desktop {
    display: none;
  }
}
#sehtraining-header .selectBox {
  display: none;
}
@media (max-width: 1000px) {
  #sehtraining-header .selectBox {
    display: block;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  #sehtraining-header .selectBox:after {
    content: "▾";
    position: absolute;
    right: 20px;
    top: 8px;
    font-size: inherit;
    transform: rotate(0deg);
    transition: all 0.3s ease;
    color: white;
  }
  #sehtraining-header .selectBox #select {
    width: 100%;
    background: white;
    border: none;
    padding: 10px 20px;
    text-align: left;
    background: #333;
    border-radius: 0;
    margin-bottom: 0px;
  }
  #sehtraining-header .selectBox .selectContent {
    display: none;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    overflow-y: auto;
    background: #eee;
    padding: 20px;
  }
  #sehtraining-header .selectBox .selectContent.active {
    display: block;
  }
  #sehtraining-header .selectBox .selectContent ul li {
    list-style-type: none;
    padding-bottom: 5px;
  }
  #sehtraining-header .selectBox .selectContent ul li a {
    color: grey;
  }
  #sehtraining-header .selectBox .selectContent ul li a:hover {
    color: black;
  }
}
#sehtraining-header .selectBox .selectBox.active:after {
  transform: rotate(-180deg);
}
#sehtraining-header .headerImage {
  width: 100%;
  height: 50vw;
  min-height: 300px;
  max-height: 500px;
  background: url(../img/sehtraining/sehtraining.jpg) center 20% no-repeat;
  background-size: cover;
}
#sehtraining-header .headerText {
  max-width: 850px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

.sehtraining .text-orange {
  color: #F5821F;
}
.sehtraining .content {
  max-width: 850px;
}
.sehtraining .soft-row {
  justify-content: center;
  align-items: center;
}
.sehtraining .soft-row .col-1 {
  padding: 10px 0;
}
.sehtraining h3, .sehtraining h2 {
  font-size: 1.9em;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 20px;
}
.sehtraining ul {
  margin-left: 17px;
}
.sehtraining #zitat {
  padding: 0;
  margin: 60px auto 0;
}
.sehtraining .section-inner {
  max-width: 850px;
  padding: 6vw 0;
}
.sehtraining .text-center {
  text-align: center;
}
.sehtraining .gutschein {
  margin-left: 10px;
}
@media (max-width: 649px) {
  .sehtraining .gutschein {
    margin-left: 0px;
  }
}
.sehtraining .gutschein img {
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.3);
}
@media (max-width: 600px) {
  .sehtraining .toggle dt {
    padding: 15px 50px 15px 20px !important;
  }
}
.sehtraining #autoplay {
  margin-right: 20px;
}
@media (max-width: 600px) {
  .sehtraining #autoplay {
    margin-right: 0px;
  }
}
.sehtraining .mitarbeiter {
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  margin-right: 20px;
}
@media (max-width: 480px) {
  .sehtraining .mitarbeiter {
    margin-right: 0px;
  }
}
.sehtraining .mitarbeiter .imageMitarbeiter.maren {
  background: url("../img/mitarbeiter/maren-urgast.jpg");
  background-position: center;
}
.sehtraining .mitarbeiter .imageMitarbeiter.angela {
  background: url(../img/mitarbeiter/angela-herzig.jpg);
  background-position: 30%;
}
.sehtraining .mitarbeiter em {
  color: white;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1rem;
  max-width: 100%;
}
.sehtraining .mitarbeiter:hover .bild-name {
  padding: 30px 10px !important;
}
.sehtraining .bild {
  transition: all 300ms ease;
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid #cdcdcd;
  transform: rotateY(0deg);
  z-index: 0;
  min-width: 300px;
}
.sehtraining .bild .imageMitarbeiter {
  height: 0;
  padding-bottom: 100%;
  width: auto;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  background-size: cover !important;
}
.sehtraining .bild .bild-name {
  background: #333333;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  min-height: 68px;
  transition: all 300ms ease;
}
.sehtraining .bild p {
  color: white;
  padding: 0;
  margin: 0 !important;
  font-size: 0.85em;
  line-height: 1.2;
  text-align: center;
}
.sehtraining .bild strong {
  font-size: 1.2em;
  text-align: center;
}
.sehtraining .red {
  color: red;
}

.toggle.sehtraining {
  padding: 6vw 20px 0;
}
.toggle.sehtraining .soft-row {
  max-width: 850px;
}
.toggle.sehtraining .soft-row .toggle {
  padding: 20px 0;
}
@media (max-width: 850px) {
  .toggle.sehtraining .soft-row .toggle {
    padding: 20px;
  }
}

.section-grid {
  padding-bottom: 0;
}
.section-grid .grid {
  padding-bottom: 0 !important;
  display: grid;
  max-width: 850px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 180px;
  grid-template-areas: "a1 a2" "content bild" "c2 c3";
}
@media (max-width: 740px) {
  .section-grid .grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a1" "content" "bild";
  }
}
.section-grid .grid .content {
  grid-area: content;
  position: relative;
  text-align: left;
}
.section-grid .grid .headline {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-end: 1;
  grid-row-start: 1;
}
.section-grid .grid img {
  max-width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-end: 4;
  grid-row-start: 1;
  align-self: end;
}
@media (max-width: 740px) {
  .section-grid .grid img {
    grid-column: 1;
    grid-row: 3;
  }
}

#sehtraining-uebungen .uebung1 {
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
}
#sehtraining-uebungen .uebung1:after {
  content: "";
  height: 2px;
  background: #F5821F;
  width: 100%;
  display: block;
  margin-top: -30px;
}
#sehtraining-uebungen .uebung1 img {
  max-width: 300px;
  animation: maus 6s forwards infinite;
}
@media (max-width: 768px) {
  #sehtraining-uebungen .uebung1 img {
    max-width: 200px;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  #sehtraining-uebungen .uebung1 img {
    max-width: 100px;
    margin-bottom: 20px;
  }
}
#sehtraining-uebungen .hakini-mudra {
  align-items: flex-start !important;
}
@media (max-width: 721px) {
  #sehtraining-uebungen .hakini-mudra {
    display: block;
  }
}
#sehtraining-uebungen .soft-row .kreis {
  background: black;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin: 5px;
  margin-top: 20px;
}
@media (max-width: 400px) {
  #sehtraining-uebungen .soft-row .kreis {
    width: 80px;
    height: 80px;
  }
}
#sehtraining-uebungen .soft-row .kreis.rot {
  background: #E30613;
}
#sehtraining-uebungen .soft-row .kreis.blau {
  background: #009FE3;
}
#sehtraining-uebungen .gehirnhaelftenintegration {
  max-width: 350px;
}
#sehtraining-uebungen .gehirnhaelftenintegration .soft-row {
  display: flex;
  max-width: 350px !important;
  justify-content: space-between;
}
#sehtraining-uebungen .gehirnhaelftenintegration .soft-row span {
  font-size: 1.9em;
  font-weight: 700;
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  #sehtraining-uebungen .gehirnhaelftenintegration .soft-row span {
    font-size: 1.5em;
  }
}
#sehtraining-uebungen .gehirnhaelftenintegration .soft-row span.rot {
  color: #ED1B23;
}
#sehtraining-uebungen .gehirnhaelftenintegration .soft-row span.blau {
  color: #00AEEF;
}
#sehtraining-uebungen .gehirnhaelftenintegration .soft-row span.orange {
  color: #F68B1E;
  background: transparent;
}
#sehtraining-uebungen .gehirnhaelftenintegration .soft-row span.violett {
  color: #2E3092;
}
#sehtraining-uebungen .gehirnhaelftenintegration .soft-row span.gruen {
  color: #40AD49;
}

.tipps .col-1 {
  padding: 0 10px !important;
}
.tipps .col-1 .background {
  background: #EFEFEF;
  height: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
}
.tipps .col-1 .background:last-child {
  margin: 10px 0 0;
}
@media (max-width: 750px) {
  .tipps .col-1 .background {
    min-height: 100px;
  }
}
.tipps .col-1 .background span {
  background: #F5821F;
  padding: 5px 12px;
  border-radius: 50%;
  color: white;
  text-align: left;
  margin-right: 10px;
}
.tipps .col-1 .background p {
  text-align: left;
  margin: 0 !important;
}

.footer--sehtraining {
  margin-bottom: 60px;
}
@media (max-width: 798px) {
  .footer--sehtraining {
    margin-bottom: 115px;
  }
}

.sticky--sehtraining {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50px auto 1fr;
  grid-template-rows: auto auto auto;
  background: #333;
  grid-template-areas: "i ausrufezeichen a b" "c ausrufezeichen text d" "e f g h";
}
@media (max-width: 850px) {
  .sticky--sehtraining {
    grid-template-columns: 10px 40px auto 10px;
  }
}
@media (max-width: 768px) {
  .sticky--sehtraining {
    grid-template-rows: auto auto 30px;
    height: 120px;
  }
}
.sticky--sehtraining .ausrufezeichen {
  grid-area: ausrufezeichen;
  font-size: 7rem;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #F5821F;
  animation: move 1s infinite;
}
.sticky--sehtraining .sticky--text {
  grid-area: text;
  color: white;
}
@media (max-width: 470px) {
  .sticky--sehtraining .sticky--text strong {
    font-size: 11px;
    line-height: 1;
  }
}
.sticky--sehtraining .background {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-end: 2;
  grid-row-start: 4;
}

@keyframes move {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 10px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes maus {
  0% {
    margin-left: -50%;
  }
  45% {
    margin-right: 60%;
  }
  46% {
    transform: scaleX(1);
  }
  47% {
    transform: scaleX(-1);
  }
  48% {
    margin-right: 60%;
  }
  95% {
    transform: scaleX(-1);
  }
  100% {
    margin-left: -50%;
    transform: scaleX(1);
  }
}
#hoerakustik-header {
  background: #EFEFEF;
  background: white;
}
#hoerakustik-header .header--slider-akustik {
  position: relative;
}
#hoerakustik-header .header--slider-akustik .slick-dots {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  display: flex;
}
#hoerakustik-header .headerImage {
  width: 100%;
  max-width: 100vw;
  height: 70vw;
  min-height: 400px;
  max-height: 850px;
  background: url(../img/hoerakustik/header_verstehen.jpg) right top no-repeat;
  background-size: cover;
}
@media (max-width: 940px) {
  #hoerakustik-header .headerImage {
    background-position: right 30% top;
    background-size: cover;
    background-attachment: initial !important;
  }
}
#hoerakustik-header .headerImage h2 strong {
  font-size: inherit !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
#hoerakustik-header .headerImage.slide6 {
  background-position: right;
}
@media (max-width: 940px) {
  #hoerakustik-header .headerImage.slide6 {
    background-position: 76%, 50% !important;
  }
}
#hoerakustik-header .headerImage.slide6:after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 30vw;
  min-width: 250px;
  max-width: 450px;
  height: 100%;
  background: url("../img/hoerakustik/resound_forsa_cta.png") bottom right no-repeat;
  background-size: contain;
}
#hoerakustik-header .headerImage.slide6 .content {
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 940px) {
  #hoerakustik-header .headerImage.slide6 .content {
    align-items: flex-start;
  }
}
#hoerakustik-header .headerImage.slide6 .content h2 {
  color: white;
}
#hoerakustik-header .headerImage.slide1 {
  display: flex;
  align-items: center;
  background-position: right top !important;
}
@media (max-width: 1200px) {
  #hoerakustik-header .headerImage.slide1 {
    background-position: right 30% top !important;
  }
}
#hoerakustik-header .headerImage.slide1 .content {
  width: 100%;
  padding-bottom: 6rem;
}
#hoerakustik-header .headerImage.slide1 .content h2 {
  width: 100%;
  color: white;
}
#hoerakustik-header .headerImage small {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  max-width: 20ch;
}
@media (max-width: 960px) {
  #hoerakustik-header .headerImage small {
    left: 1.5rem;
    right: auto;
  }
}
@media (min-width: 961px) and (max-width: 1200px) {
  #hoerakustik-header .headerImage small {
    left: 3rem;
  }
}
#hoerakustik-header .headerImage.image2 {
  background: url(../img/hoerakustik/bh-slider-strand.jpg) center no-repeat;
  background-size: auto;
  background-size: cover;
}
#hoerakustik-header .headerImage.image2 .content h2 {
  max-width: 100%;
  width: 600px;
}
#hoerakustik-header .headerImage .content {
  padding: 8rem 0 0 3rem;
}
@media (max-width: 940px) {
  #hoerakustik-header .headerImage .content {
    padding: 6rem 0 0 1rem;
  }
}
#hoerakustik-header .headerImage .content strong {
  font-weight: bold;
  font-size: 1.6rem;
  display: block;
  margin-bottom: 20px;
  text-transform: uppercase;
}
@media (max-width: 940px) {
  #hoerakustik-header .headerImage .content strong {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}
#hoerakustik-header .headerImage h2 {
  font-size: 3rem;
  width: 500px;
  max-width: 50%;
}
@media (max-width: 940px) {
  #hoerakustik-header .headerImage h2 {
    font-size: 1.6rem;
    line-height: 1.2;
  }
}
#hoerakustik-header .headerText {
  max-width: 1200px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}

.akustik_logo {
  max-width: 350px;
  margin: 30px auto;
}

.grimmerTeam {
  margin: 30px auto;
}

.headerText {
  max-width: 750px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;
}
.headerText h3 {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

#logos {
  margin-bottom: 50px;
}
#logos .soft-row div {
  max-width: 250px;
}

#romanus_bild_text {
  background-color: #EFEFEF;
  padding: 65px 0px 55px 0px;
}
#romanus_bild_text .veronique {
  max-width: 250px;
}
#romanus_bild_text .veronique div {
  background-color: #3A3A3A;
  padding: 10px;
  color: white !important;
  font-size: 13px !important;
  position: relative;
  top: -7px;
}
#romanus_bild_text .text {
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 849px) {
  #romanus_bild_text .text {
    text-align: center;
    margin-top: 20px;
  }
}
#romanus_bild_text .text .btn_orange {
  background: #F4831E;
  padding: 10px 15px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  margin-top: 20px;
  display: table;
}
@media (max-width: 849px) {
  #romanus_bild_text .text .btn_orange {
    margin: 0 auto;
  }
}

.aic {
  align-items: center;
}

.schritte--hoergeraet {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.schritte--hoergeraet .schritt {
  position: relative;
  flex: 1 0 0;
  margin: 0.5rem;
  padding: 2rem;
  cursor: pointer;
  background: #3D3D3D;
  transition: all 300ms ease;
}
@media (max-width: 800px) {
  .schritte--hoergeraet .schritt {
    flex: 1 0 40%;
  }
}
@media (max-width: 420px) {
  .schritte--hoergeraet .schritt {
    flex: 1 0 90%;
  }
}
.schritte--hoergeraet .schritt:before {
  transition: all 300ms ease;
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  border: 1px solid #F5821F;
  pointer-events: none;
}
.schritte--hoergeraet .schritt:hover {
  background: #F5821F;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}
.schritte--hoergeraet .schritt:hover:before {
  border-color: white;
  left: 0.2rem;
  top: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
}
.schritte--hoergeraet .schritt:hover .info {
  font-size: 3rem;
}
.schritte--hoergeraet .schritt img {
  margin-bottom: 1rem;
}
.schritte--hoergeraet .schritt h4 {
  line-height: 1.4;
  color: white;
}
.schritte--hoergeraet .schritt .info {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 40px;
  height: 40px;
  background: #F5821F;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  z-index: 0;
  transition: all 300ms ease;
}

#overlay.modal--akustik {
  padding: 20px;
}
#overlay.modal--akustik .popupContent {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
}
#overlay.modal--akustik .popupContent .popupText {
  padding-top: 60px;
}
#overlay.modal--akustik .popupContent .popupText, #overlay.modal--akustik .popupContent .popupImage {
  height: auto;
}
@media (max-width: 1250px) {
  #overlay.modal--akustik .popupContent .popupText, #overlay.modal--akustik .popupContent .popupImage {
    width: 100%;
  }
}
#overlay.modal--akustik .popupContent .popupText strong, #overlay.modal--akustik .popupContent .popupImage strong {
  font-size: inherit;
  font-weight: bold;
}
#overlay.modal--akustik .popupContent .popupText ul li, #overlay.modal--akustik .popupContent .popupImage ul li {
  margin-left: 20px;
}
#overlay.modal--akustik .popupContent .popupImage {
  background-position: top center;
  min-height: 360px;
}
@media (max-width: 800px) {
  #overlay.modal--akustik .popupContent .popupImage {
    min-height: 300px;
  }
}
@media (max-width: 1250px) {
  #overlay.modal--akustik .popupContent .popupImage {
    height: 50vw;
  }
}
#overlay.modal--akustik .lupe {
  z-index: 9999999999;
  position: absolute;
}

#schritt--1 .popupImage {
  background-position: center center !important;
}

.threesixty .button--container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.threesixty .button--container .btn_orange {
  margin: 2rem 1rem;
}
.threesixty .button--container .btn_orange:not(.active) {
  background: #AFAEAE;
}
.threesixty .soft--row {
  align-items: center;
  display: flex;
  max-width: 1200px;
  margin: 0 auto 3rem;
}
@media (max-width: 940px) {
  .threesixty .soft--row {
    display: block;
  }
  .threesixty .soft--row .col-1 {
    padding: 0 30px !important;
  }
  .threesixty .soft--row .col-1.order-2 {
    margin-bottom: 1rem;
  }
}
.threesixty .soft--row .col-1 {
  flex: 1 0 45%;
  position: relative;
}
.threesixty .soft--row .col-1.order-2 {
  padding-right: 30px;
}
.threesixty .soft--row .col-1 h3 {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}
.threesixty .soft--row .col-1 .interface {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: ew-resize !important;
  border: 1px solid #EFEFEF;
}
.threesixty .soft--row .col-1 .interface .sprite {
  cursor: ew-resize;
}
.threesixty .soft--row .col-1 .interface img {
  display: block;
}
.threesixty .soft--row .col-1 .interface .controllers {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.threesixty .soft--row .col-1 .interface .controllers .controller {
  pointer-events: all;
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
}
.threesixty .soft--row .col-1 .interface .controllers .controller img {
  max-width: 100%;
  max-height: 100%;
}
.threesixty .soft--row .col-1 .interface .controllers .controller.active {
  background: #F5821F;
}
.threesixty .soft--row .col-1 .interface .controllers .controller.left {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.threesixty .soft--row .col-1 .interface .controllers .controller.right {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.threesixty .soft--row .col-1 .interface .controllers .controller.female {
  bottom: 0;
  right: 0;
}
.threesixty .soft--row .col-1 .interface .controllers .controller.male {
  bottom: 0;
  right: 50px;
}
.threesixty .soft--row .col-1 .interface .controllers .controller.ido {
  bottom: 0;
  left: 0;
}
.threesixty .soft--row .col-1 .interface .controllers .controller.hdo {
  bottom: 0;
  left: 50px;
}
.threesixty .soft--row .col-1 .interface .controllers .controller.hdo-ext {
  bottom: 0;
  left: 100px;
}
.threesixty .soft--row .col-1 .interface .controllers .bottom--controls {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: block;
  background: rgba(255, 255, 255, 0.8);
}
.threesixty .soft--row .col-1 .hoergeraete {
  margin-top: 2rem;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image {
  flex: 1 0 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  background: #ccc;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image .tool-tip {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  transition: all 300ms ease;
  margin: 0 !important;
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 90%;
  padding: 5px;
  background: white;
  border-radius: 4px;
  color: black !important;
  visibility: hidden;
  opacity: 0;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image:hover .tool-tip {
  opacity: 1;
  transform: translate(-50%, 0%);
  visibility: visible;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image img {
  max-width: 100%;
  height: auto;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image.ido img {
  width: 107px;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image.hdo img {
  width: 160px;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image.hdo-ext img {
  width: 152px;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image p {
  margin-bottom: 0 !important;
  font-size: 80%;
  line-height: 1.4;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image:first-child {
  margin-left: 0;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image:last-child {
  margin-right: 0;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image.active {
  background: #F5821F;
}
.threesixty .soft--row .col-1 .hoergeraete .select--image.active p {
  color: white;
}
@media (max-width: 940px) {
  .threesixty .soft--row .col-1 .hoergeraete {
    display: flex;
  }
  .threesixty .soft--row .col-1 .hoergeraete .select--image {
    padding: 1rem;
  }
}

.hoerloesungen {
  background: #EFEFEF;
  padding: 30px;
  padding-bottom: 30px;
}
.hoerloesungen .container {
  padding: 60px 0 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.hoerloesungen .container h2 {
  margin: 0 auto;
  text-align: center;
}
.hoerloesungen .container .hoergeraete {
  margin-top: 2rem;
  display: flex;
}
.hoerloesungen .container .hoergeraete .hoergeraet {
  flex: 1 0 25%;
  background: rgba(255, 255, 255, 0.7);
  padding: 2rem;
  margin-right: 1rem;
}
.hoerloesungen .container .hoergeraete .hoergeraet ul li {
  margin-left: 20px;
}
.hoerloesungen .container .hoergeraete .hoergeraet h3 {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: bold;
}
.hoerloesungen .container .hoergeraete .hoergeraet:last-child {
  margin-right: 0;
}
.hoerloesungen .container .hoergeraete .hoergeraet .image--container {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hoerloesungen .container .hoergeraete .hoergeraet .image--container img {
  width: auto;
  max-width: 100%;
  height: auto;
}
.hoerloesungen .container .hoergeraete .hoergeraet.ido .image--container img {
  width: 107px;
  height: 58px;
}
.hoerloesungen .container .hoergeraete .hoergeraet.hdo .image--container img {
  width: 160px;
  height: 125px;
}
.hoerloesungen .container .hoergeraete .hoergeraet.hdo-ext .image--container img {
  width: 152px;
  height: 144px;
}
@media (max-width: 940px) {
  .hoerloesungen .container .hoergeraete {
    display: block;
  }
  .hoerloesungen .container .hoergeraete .hoergeraet {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    margin: 0 0 10px;
    justify-content: center;
  }
  .hoerloesungen .container .hoergeraete .hoergeraet h3 {
    font-size: 1.2rem;
    height: auto;
  }
  .hoerloesungen .container .hoergeraete .hoergeraet h3 {
    flex: 1 0 100%;
  }
  .hoerloesungen .container .hoergeraete .hoergeraet .image--container {
    flex: 0 0 20%;
    min-width: 200px;
  }
  .hoerloesungen .container .hoergeraete .hoergeraet .hoergeraete--content {
    flex: 1 0 50%;
  }
}
.hoerloesungen .hersteller--icons {
  display: flex;
  background: white;
  width: 100%;
  max-width: 1200px;
  justify-content: space-around;
  margin: 0 auto;
}
.hoerloesungen .hersteller--icons .iconWrapper {
  flex: 1 0 30%;
  max-width: 200px;
}

.orange-color {
  color: #F5821F;
  font-size: inherit;
}

.veronique {
  background: url("../img/hoerakustik/veronique_romanus.jpg") center no-repeat;
  background-size: cover;
  padding: 30px;
  height: 50vw;
  min-height: 400px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.veronique .content {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}
.veronique .half--width {
  width: 50%;
  max-width: 700px;
  color: white;
}
.veronique .half--width h2 {
  color: #F5821F;
}
.veronique .half--width small {
  margin-top: 30px;
  display: block;
  line-height: 1.6;
  font-size: 80%;
}
@media (max-width: 940px) {
  .veronique {
    height: auto;
  }
}
@media (max-width: 560px) {
  .veronique {
    display: block;
    background: url("../img/hoerakustik/veronique_romanus_mobile.jpg") bottom center no-repeat;
    background-size: contain;
    background-color: #000;
  }
  .veronique .half--width {
    width: 100%;
    margin-bottom: 70vw;
  }
}

.stellenangebote {
  display: none;
  padding: 30px;
  text-align: center;
}
.stellenangebote .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.stellenangebote .container .stelle {
  padding: 2rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  text-align: center;
}
.stellenangebote .container .stelle:last-child {
  border-bottom: none;
}
.stellenangebote .container .stelle strong {
  font-weight: bold;
  margin-bottom: 0 !important;
}
.stellenangebote .container .stelle p {
  text-align: center;
  max-width: 700px;
  display: block;
  margin: 10px auto 0 !important;
}

.hoertest {
  background: #EFEFEF !important;
  padding-top: 40px;
  padding-bottom: 40px;
}

.App {
  max-width: 1200px !important;
}
.App .main--button {
  @extends .button;
  border-radius: 100px;
  color: white !important;
}
.App .main--button:hover {
  background: #f69037;
  padding: 10px 40px !important;
}
.App h2 {
  font-weight: 600;
}
.App p {
  margin: 0 auto !important;
}
.App .progess--bar > span {
  background: white;
}
.App .range--container > div:first-of-type {
  background: white;
}
.App .back--button p {
  width: auto !important;
  margin: 0 0 0 10px !important;
}
.App .result--table-row-numbers > p,
.App .result--table-row > p {
  margin-left: 10px !important;
}
.App .result--footer-content p {
  margin-left: 10px !important;
}

.aktion {
  background: #F5821F;
  padding: 6rem 2rem 6rem;
}
.aktion .container {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.aktion .container .text__content {
  flex: 1 0 30%;
  padding-right: 2rem;
  max-width: 733px;
  margin: 0 auto 2rem;
}
.aktion .container .text__content * {
  color: white;
}
.aktion .container .text__content h2 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}
.aktion .container .text__content h3 {
  font-size: 1.5rem;
  line-height: 1.2;
}
.aktion .container .aktionscontainer {
  flex: 1 0 60%;
  margin: 0 auto;
  background: white;
  display: block;
}
@media (min-width: 760px) {
  .aktion .container .aktionscontainer {
    display: flex;
    min-width: 733px;
    max-width: 733px;
  }
}
.aktion .container .aktionscontainer .image__container {
  width: 100%;
  padding-bottom: 70%;
  position: relative;
  background-size: cover;
}
@media (min-width: 760px) {
  .aktion .container .aktionscontainer .image__container {
    padding-bottom: 0;
    width: 50%;
  }
}
.aktion .container .aktionscontainer .image__container:before {
  content: "";
  background: url(../img/hoerakustik/aktionsbild_frei.png) no-repeat;
  background-position: right bottom;
  background-size: cover;
  top: 0;
  left: 0%;
  width: 101.7%;
  height: 110.2%;
  position: absolute;
}
@media (min-width: 760px) {
  .aktion .container .aktionscontainer .image__container:before {
    background-size: contain;
    background-position: right top;
    top: 0;
    left: 0%;
    width: 102.2%;
    height: 107.2%;
  }
}
.aktion .container .aktionscontainer .aktionscontent {
  background: white;
  padding: 1.5rem;
}
@media (min-width: 760px) {
  .aktion .container .aktionscontainer .aktionscontent {
    width: 50%;
  }
}
.aktion .container .aktionscontainer .aktionscontent ul li {
  margin-left: 20px;
}
.aktion .container .aktionscontainer .aktionscontent ul li p {
  margin: 0 !important;
}
.aktion .container .aktionscontainer .aktionscontent ul ul {
  margin: 10px 0 20px;
}
.aktion .container .aktionscontainer .aktionscontent small {
  font-size: 0.8rem;
  margin-top: 1rem;
  display: block;
}
.aktion .container .aktionscontainer .aktionscontent h4 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.75rem;
}

@media (min-width: 960px) {
  .flex--row {
    display: flex;
  }
}

.flex--50 {
  flex: 1 0 50%;
}

.order--1 {
  order: 1;
}

.order--2 {
  order: 2;
}

.align--center {
  align-items: center;
}

.align--end {
  align-items: flex-end;
}
@media (min-width: 1400px) {
  .align--end {
    align-items: center;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 1400px) {
  .flex--l-0 {
    flex: 0 0 auto;
  }
}
@media (min-width: 2500px) {
  .large--absolute {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1200px;
  }
}
.container--boxed {
  padding: 2rem;
  max-width: 1000px;
}
@media (min-width: 960px) {
  .container--boxed {
    padding: 4rem 4rem 4rem 0rem;
  }
}
@media (min-width: 2000px) {
  .container--boxed {
    max-width: 1200px;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999999999;
  padding: 30px;
  overflow-y: auto;
  display: none;
  flex-direction: column;
  justify-content: center;
}
.modal.active {
  display: flex;
}
.modal--content {
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 1000px;
  background: white;
  position: relative;
  padding: 40px;
  overflow-y: auto;
}
.modal--content ul {
  margin-left: 15px;
}
.modal .lupe {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  background: #f5811f;
  transition: all 100ms ease;
}
.modal .lupe:hover {
  padding: 7px;
}

.threesixty {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.threesixty .soft--row .col-1 .sprite {
  margin-left: auto;
  margin-right: auto;
  background-image: url("../img/hoerakustik/watch_woman_model0.jpg");
}

#usercentrics-button .uc-btn-new {
  line-height: 1;
  font-size: 16px;
}

#usercentrics-button {
  font-size: 14px;
}
#usercentrics-button .uc-banner-text {
  color: #333 !important;
}
#usercentrics-button button {
  margin-bottom: 0;
}

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header {
  background-color: #AFAEAE !important;
}

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header .uc-info-modal-search-bar input {
  background-color: #fff !important;
  color: black !important;
}
#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header .uc-info-modal-search-bar input::placeholder {
  color: black !important;
}

#usercentrics-button #uc-center-modal .uc-powered-by-footer {
  display: none !important;
}

#usercentrics-button .uc-powered-by-footer {
  display: none !important;
}

#usercentrics-button .uc-save-settings-and-close-button {
  color: #AFAEAE !important;
}

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-info-modal-sidebar .uc-powered-by {
  display: none !important;
}

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-info-modal-sidebar .uc-info-modal-sidebar-nav {
  background: #EFEFEF;
}

#uc-btn-close-main-corner-modal svg use, #uc-btn-close-main-corner-modal svg #fingerprint {
  fill: #F5821F !important;
}

#uc-btn-deny-banner {
  margin-top: 10px;
  background-color: #EFEFEF !important;
}

#uc-btn-more-info-banner {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.3) !important;
  font-size: 12px !important;
}

#usercentrics-button .uc-banner-content {
  background: white !important;
}

#usercentrics-button .uc-banner-links * {
  font-size: 12px !important;
}

#usercentrics-button .uc-btn {
  box-shadow: none !important;
}

#miyosmart {
  position: relative;
}
#miyosmart h1 {
  font-size: 4.5rem;
  max-width: 1000px;
}
#miyosmart h1 span {
  font-size: 3rem;
  display: block;
  text-transform: initial;
  margin-bottom: 50px;
}
#miyosmart h2 {
  color: #F5821F;
}
#miyosmart h2 span {
  font-size: 1em;
  font-weight: 700;
  text-transform: lowercase;
}
#miyosmart .white h2, #miyosmart .white p, #miyosmart .white strong, #miyosmart .white blockquote {
  color: white;
}
#miyosmart blockquote {
  position: relative;
  font-style: italic;
  padding-right: 50px;
}
#miyosmart blockquote::before {
  content: "";
  width: 200px;
  height: 3px;
  background: white;
  position: absolute;
  display: block;
  left: -220px;
  top: 7px;
}
#miyosmart blockquote.white {
  color: white;
}
#miyosmart section:nth-child(3) {
  margin-top: 70vh;
  background: white;
}
#miyosmart section:nth-child(4) {
  background: #fef4e3;
  padding: 0;
}
#miyosmart section:nth-child(4) .col--2 {
  padding: 8% 0 8% 30%;
}
#miyosmart section:nth-child(4) .col--1 {
  padding-right: 8%;
}
#miyosmart section:nth-child(5) {
  background: #EFEFEF;
}
#miyosmart section:nth-child(6) {
  background: #F5821F;
}
#miyosmart section:nth-child(7) {
  background: #fef4e3;
}
#miyosmart section:nth-child(8) {
  background: white;
  padding: 0;
}
#miyosmart section:nth-child(8) .col--2 {
  padding: 8% 0 8% 30%;
}
#miyosmart section:nth-child(8) .col--1 {
  padding-right: 8%;
}
#miyosmart section:nth-child(9) {
  background: #E0E0DF;
}
#miyosmart section:nth-child(10) {
  background: white;
}
#miyosmart #line {
  position: fixed;
  bottom: 38vh;
  left: 25%;
  background: #F5821F;
  height: 400px;
  width: 5px;
  z-index: 2;
}
#miyosmart header {
  background: #FCE4BA;
  min-height: 70vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: -5;
}
#miyosmart header .row--1 {
  position: absolute;
  bottom: 100px;
  padding: 8% 8% 0 30%;
}
#miyosmart header .head--img {
  position: fixed;
  width: 100%;
  height: 80vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 100px 100px 400px 100px;
  z-index: -1;
  max-width: 1600px;
  margin: 0 auto;
}
#miyosmart header .head--img .img {
  background: url("../img/miyosmart/miyo-img1.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
#miyosmart #overlay-audio .popupContent {
  width: 70vw;
}
#miyosmart .video-popup {
  cursor: pointer;
  position: relative;
}
#miyosmart .video-popup::after {
  content: "";
  position: absolute;
  height: 70px;
  width: 70px;
  background: url(../img/miyosmart/i-play.svg) no-repeat;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
#miyosmart .front {
  z-index: 1;
}
#miyosmart .back {
  z-index: 3;
  position: relative;
}
#miyosmart .row--1, #miyosmart .row--2, #miyosmart .row--3 {
  display: flex;
  padding: 8% 8% 8% 30%;
}
#miyosmart .row--1 .col--1, #miyosmart .row--2 .col--1, #miyosmart .row--3 .col--1 {
  flex: 1 0 0;
}
#miyosmart .row--1 .col--2, #miyosmart .row--2 .col--2, #miyosmart .row--3 .col--2 {
  flex: 2 0 0;
  margin-right: 50px;
}
#miyosmart .row--full {
  position: relative;
}
#miyosmart .row--full img {
  display: block;
}
#miyosmart .row--full .stoerer {
  position: absolute;
  top: 8%;
  right: 8%;
  height: 200px;
  width: 200px;
}
@media (max-width: 650px) {
  #miyosmart .row--full .stoerer {
    width: 140px;
    height: 140px;
  }
}
#miyosmart .row--4 {
  display: flex;
}
#miyosmart .row--4 .col--1 {
  flex: 1 0 0;
  padding: 8% 8% 8% 0;
}
#miyosmart .row--4 .col--1.zitat {
  position: relative;
  padding: 0 0 0 30%;
  align-self: flex-end;
}
@media (max-width: 980px) {
  #miyosmart .row--4 .col--1.zitat {
    align-self: initial;
  }
}
#miyosmart .row--4 .col--1.zitat img {
  display: block;
  height: auto;
  width: 100%;
  margin-left: auto;
}
#miyosmart .row--4 .col--1.zitat img.hoya {
  position: absolute;
  height: auto;
  width: 15vw;
  bottom: -3.3vw;
  left: 18vw;
  z-index: 9;
}
@media (max-width: 980px) {
  #miyosmart .row--4 .col--1.zitat img.hoya {
    width: 200px;
    right: 10vw;
    left: initial;
  }
}
@media (max-width: 630px) {
  #miyosmart .row--4 .col--1.zitat img.hoya {
    width: 30vw;
    right: 20px;
    left: initial;
  }
}
#miyosmart .row--4 .col--1.zitat blockquote {
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-width: 300px;
}
#miyosmart .cta {
  display: inline-block;
  margin-top: 20px;
  padding: 15px 40px;
  background: #F5821F;
  border-radius: 30px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
#miyosmart .cta:hover {
  opacity: 0.8;
}
#miyosmart .row--1 {
  flex-direction: column;
  align-items: center;
}
#miyosmart .row--1 .softrow {
  margin-top: 40px;
  display: flex;
}
#miyosmart .row--1 .softrow .col--1 {
  padding: 50px;
  text-align: center;
}
@media (max-width: 650px) {
  #miyosmart .row--1 .softrow {
    flex-direction: column;
  }
  #miyosmart .row--1 .softrow .col--1 {
    padding: 20px;
  }
}
#miyosmart .row--1.row--grid {
  position: initial;
}
#miyosmart .row--1.row--grid .softrow {
  flex-wrap: wrap;
}
#miyosmart .row--1.row--grid .softrow .col--1 {
  min-width: 50%;
  text-align: left;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
#miyosmart .row--1.row--grid .softrow .col--1 img {
  max-height: 100px;
  width: 100px;
  margin-right: 30px;
}
#miyosmart .row--1.row--grid .softrow .col--1 strong {
  position: absolute;
  left: 120px;
}
#miyosmart .background--img1 {
  background: url("../img/miyosmart/miyo-img2.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
}
#miyosmart .background--img2 {
  background: url("../img/miyosmart/miyo-vid.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 8% 0 8% 0;
}
#miyosmart img.icons {
  max-height: 120px;
  width: auto;
}
#miyosmart img.circles-img {
  padding: 0 50px 30px 0;
}
@media (max-width: 1530px) {
  #miyosmart h1 {
    font-size: 4vw;
    max-width: 1000px;
  }
  #miyosmart header .row--1 .head--img {
    padding: 100px 100px 370px 100px;
    height: 100%;
  }
}
@media (max-width: 1370px) {
  #miyosmart header {
    height: 100vh;
  }
  #miyosmart header .row--1 h1 {
    text-shadow: 0 0 6px #f1d5ae;
  }
  #miyosmart header .row--1 .head--img {
    padding: 100px 100px 230px 100px;
    height: 100%;
  }
  #miyosmart section:nth-child(3) {
    margin-top: 100vh;
    background: white;
  }
  #miyosmart section:nth-child(4) {
    background: #fef4e3;
    padding: 0;
  }
  #miyosmart section:nth-child(4) .col--2 {
    padding: 8% 0 8% 30%;
  }
  #miyosmart section:nth-child(4) .col--1 {
    padding-right: 8%;
  }
  #miyosmart #line {
    bottom: 110px;
    height: 300px;
  }
}
@media (max-width: 980px) {
  #miyosmart #line {
    bottom: 38vh;
    left: 5%;
    height: 400px;
  }
  #miyosmart header {
    min-height: 70vh;
    height: 70vh;
    position: relative;
    top: 0;
    z-index: -5;
  }
  #miyosmart header .row--1 {
    position: absolute;
    bottom: 100px;
    padding: 8% 8% 0 10%;
  }
  #miyosmart header .head--img {
    position: relative;
    width: 100%;
    height: 200px !important;
    padding: 0 0 50px 0 !important;
    max-width: 1600px;
    margin: 0 auto;
  }
  #miyosmart .row--1, #miyosmart .row--2, #miyosmart .row--3, #miyosmart .row--4 {
    flex-direction: column;
    padding: 8% 8% 8% 10%;
  }
  #miyosmart .row--1 .background--img1, #miyosmart .row--1 .background--img2, #miyosmart .row--2 .background--img1, #miyosmart .row--2 .background--img2, #miyosmart .row--3 .background--img1, #miyosmart .row--3 .background--img2, #miyosmart .row--4 .background--img1, #miyosmart .row--4 .background--img2 {
    min-height: 300px !important;
  }
  #miyosmart section:nth-child(3) {
    margin-top: 0;
    background: white;
  }
  #miyosmart section:nth-child(4) {
    background: #fef4e3;
    padding: 0;
  }
  #miyosmart section:nth-child(4) .col--2 {
    padding: 8% 0 8% 10%;
  }
  #miyosmart section:nth-child(4) .col--1 {
    padding-right: 8%;
  }
  #miyosmart section:nth-child(6) .col--1:first-of-type {
    margin-bottom: 60px;
  }
  #miyosmart section:nth-child(8) {
    padding: 0;
  }
  #miyosmart section:nth-child(8) .col--2 {
    padding: 8% 0 8% 10%;
  }
  #miyosmart section:nth-child(8) .col--1 {
    padding-right: 0;
    margin: 0;
  }
  #miyosmart .row--4 {
    flex-direction: column-reverse;
    padding: 8% 8% 0 8%;
  }
  #miyosmart .row--4 .col--1 {
    padding: 0 0 8% 0;
  }
  #miyosmart .row--4 .col--1.zitat {
    padding: 0;
  }
  #miyosmart .row--4 .col--1.zitat img {
    height: 320px;
    width: auto;
    margin: 0 auto 0 0;
  }
  #miyosmart .row--4 .col--1.zitat blockquote {
    max-width: 270px;
    left: 10px;
    bottom: 10px;
  }
}

#marken .section-inner {
  max-width: 1280px;
}
#marken .section-inner .slider-nav-marken .slick-list {
  padding: 0 !important;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide {
  height: auto;
}
@media (min-width: 1000px) {
  #marken .section-inner .slider-nav-marken .slick-list .slick-track .slide {
    display: flex;
    align-items: flex-end;
    gap: 0 100px;
  }
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .preview-img {
  flex: 0 0 50%;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .preview-img img {
  height: auto;
  display: block;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text h3 {
  text-align: left;
  color: #F5821F;
  font-weight: 600;
  font-size: 35px;
  line-height: 47px;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 999px) {
  #marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text h3 {
    margin-top: 25px;
  }
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text h3 + div p {
  font-family: "Open Sans", sans-serif;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  color: #5A6749;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text .pagination {
  position: relative;
  max-width: 100px;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text .pagination .pagingInfo {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  color: #5A6749;
  font-weight: 400;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text .pagination .slick-prev {
  left: 0;
  transform: translateY(-50%);
  margin-top: unset;
  margin-bottom: 0;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text .pagination .slick-prev:before {
  content: "" !important;
  background-image: url("data:image/svg+xml,%3Csvg id='Page-1' xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Icon-Set' transform='translate(0)'%3E%3Cpath id='arrow-right-circle' d='M316.277,1102.12l5.656-5.66a1,1,0,0,1,1.414,1.42l-4.121,4.12h10.586a1,1,0,0,1,0,2H319.226l4.121,4.12a1,1,0,1,1-1.414,1.42l-5.656-5.66a1.619,1.619,0,0,1,0-1.76Z' transform='translate(-316 -1096.167)' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A");
  width: 15px;
  height: 14px;
  background-color: transparent;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text .pagination .slick-next {
  right: 0;
  transform: translateY(-50%);
  margin-top: unset;
  margin-bottom: 0;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text .pagination .slick-next:before {
  content: "" !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Page-1' transform='translate(-8 -9.167)'%3E%3Cg id='Icon-Set' transform='translate(-308 -1087)'%3E%3Cpath id='arrow-right-circle' d='M330.535,1102.12l-5.656-5.66a1,1,0,0,0-1.414,1.42l4.121,4.12H317a1,1,0,0,0,0,2h10.586l-4.121,4.12a1,1,0,0,0,1.414,1.42l5.656-5.66a1.619,1.619,0,0,0,0-1.76Z' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  width: 15px;
  height: 14px;
  background-color: transparent;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}
#marken .section-inner .slider-nav-marken .slick-list .slick-track .slide .inner-text .inner-button {
  text-align: left;
}

#marken-header {
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
  display: block;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  position: relative;
}
#marken-header .header__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  object-position: bottom center;
}
#marken-header .section-inner {
  max-width: 1600px;
  position: absolute;
  padding: 0 20px;
  height: 100%;
}
#marken-header img {
  position: absolute;
  bottom: 30px;
  height: auto;
  max-width: 30vw;
  z-index: 1;
  width: auto;
}

.flex--box .flex--50.bg,
.box .flex--50.bg {
  height: 50vh;
}
@media (min-width: 1300px) {
  .flex--box .flex--50.bg,
.box .flex--50.bg {
    height: 93vh;
  }
}
.flex--box .small--heading,
.box .small--heading {
  font-size: 20px;
  line-height: 27px;
  color: #F5821F;
  font-weight: 400;
  letter-spacing: 0;
  padding-bottom: 0;
  margin-bottom: 10px !important;
  font-family: "Montserrat", sans-serif;
}
.flex--box h1,
.box h1 {
  font-size: 28px;
  line-height: 38px;
  text-transform: none;
  font-weight: 600;
  color: #333333;
}
@media (min-width: 1000px) {
  .flex--box h1,
.box h1 {
    font-size: 45px;
    line-height: 55px;
  }
}
.flex--box h2,
.box h2 {
  color: #000000;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-transform: none;
}
@media (min-width: 1000px) {
  .flex--box h2,
.box h2 {
    font-size: 45px;
    line-height: 55px;
  }
}
.flex--box p,
.box p {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
}
.flex--box p strong,
.box p strong {
  color: #000000;
  font-size: 18px;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
}

#editorial-marken .content {
  background-color: #FDF1D5;
}

#content-2 .content {
  background-color: #333333;
}
#content-2 .content h2,
#content-2 .content p,
#content-2 .content p strong {
  color: #ffffff;
}

#brillen .section-inner,
#brillen-2 .section-inner {
  max-width: 1320px;
}
#brillen .section-inner .brillen-inner,
#brillen-2 .section-inner .brillen-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 16px;
}
#brillen .section-inner .brillen-inner img,
#brillen-2 .section-inner .brillen-inner img {
  height: auto;
}

#content-3 .content {
  background-color: #F5821F;
}
#content-3 .content h2,
#content-3 .content p,
#content-3 .content p strong {
  color: #ffffff;
}

#content-4 .content {
  background-color: #FDE5D2;
}

.brandfilter {
  margin-top: 5vw;
  position: relative;
  width: 100%;
  max-width: 600px;
}
.brandfilter input {
  font-family: inherit;
  padding: 15px 50px 15px 20px;
  font-size: 18px;
  width: 100%;
  border: 1px solid #707070;
}
.brandfilter img {
  position: absolute;
  width: 27px;
  height: 27px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.brillengrid {
  display: grid;
  flex-wrap: wrap;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .brillengrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.brillengrid .brand {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  aspect-ratio: 1/1;
}
.brillengrid .brand.brand__img {
  display: grid;
  grid-template-columns: 1fr;
  grid-column: span 2;
  aspect-ratio: unset;
  padding: 0;
}
@media (min-width: 540px) {
  .brillengrid .brand.brand__img {
    grid-template-columns: 1fr 1fr;
  }
}
.brillengrid .brand.brand__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
}
.brillengrid .brand.brand__img .logo__container {
  aspect-ratio: 2/1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 540px) {
  .brillengrid .brand.brand__img .logo__container {
    aspect-ratio: 1/0.97;
  }
}
.brillengrid .brand.brand__img .logo__container img {
  width: 100%;
  height: auto;
  max-height: 100px;
  object-fit: contain;
  object-position: center;
}

#editorial,
#hoerakustik {
  background-color: #FDF1D5;
}
#editorial .content .small--heading,
#hoerakustik .content .small--heading {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  font-weight: 400;
  letter-spacing: 0;
  padding-bottom: 0;
  margin-bottom: 10px !important;
  font-family: "Montserrat", sans-serif;
}
#editorial .content h1,
#hoerakustik .content h1 {
  font-size: 20px;
  line-height: 27px;
  text-transform: none;
  font-weight: 400;
  color: #000000;
}
#editorial .content h2,
#hoerakustik .content h2 {
  color: #F5821F;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
}
@media (min-width: 1000px) {
  #editorial .content h2,
#hoerakustik .content h2 {
    font-size: 45px;
    line-height: 55px;
  }
}
#editorial .content p,
#hoerakustik .content p {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
#editorial .content p strong,
#hoerakustik .content p strong {
  color: #000000;
  font-size: 18px;
  line-height: 28px;
}

#marken .small--heading,
#optiker .small--heading,
#akustiker .small--heading,
#news .small--heading,
#online-tools .small--heading {
  font-size: 20px;
  line-height: 27px;
  color: #F5821F;
  font-weight: 400;
  letter-spacing: 0;
  padding-bottom: 0;
  margin-bottom: 10px !important;
}
#marken h2,
#optiker h2,
#akustiker h2,
#news h2,
#online-tools h2 {
  color: #000000;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 25px;
  text-transform: none !important;
}
@media (min-width: 1000px) {
  #marken h2,
#optiker h2,
#akustiker h2,
#news h2,
#online-tools h2 {
    font-size: 45px;
    line-height: 61px;
    margin-bottom: 50px;
  }
}

#online-tools h2 {
  color: #ffffff;
}

#optiker .slider-auge .ohr {
  display: none;
}

#akustiker .slider-ohr {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}
#akustiker .slider-ohr .auge {
  display: none;
}

#optiker .section-inner,
#akustiker .section-inner {
  padding: 6vw 30px 0;
}
#optiker .slider-auge .mitarbeiter.hasContent,
#optiker .slider-ohr .mitarbeiter.hasContent,
#akustiker .slider-auge .mitarbeiter.hasContent,
#akustiker .slider-ohr .mitarbeiter.hasContent {
  position: relative;
}
#optiker .slider-auge .mitarbeiter.hasContent .hover,
#optiker .slider-ohr .mitarbeiter.hasContent .hover,
#akustiker .slider-auge .mitarbeiter.hasContent .hover,
#akustiker .slider-ohr .mitarbeiter.hasContent .hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 85px;
  color: #fff;
  opacity: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #333;
  padding: 0;
  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}
#optiker .slider-auge .mitarbeiter.hasContent .hover p,
#optiker .slider-ohr .mitarbeiter.hasContent .hover p,
#akustiker .slider-auge .mitarbeiter.hasContent .hover p,
#akustiker .slider-ohr .mitarbeiter.hasContent .hover p {
  color: #ffffff;
  z-index: 10;
  padding: 10px;
  margin: 0 !important;
  transition: all 300ms ease;
  text-align: center;
  width: 100%;
}
#optiker .slider-auge .mitarbeiter.hasContent:hover,
#optiker .slider-ohr .mitarbeiter.hasContent:hover,
#akustiker .slider-auge .mitarbeiter.hasContent:hover,
#akustiker .slider-ohr .mitarbeiter.hasContent:hover {
  cursor: pointer;
}
#optiker .slider-auge .mitarbeiter.hasContent:hover .hover,
#optiker .slider-ohr .mitarbeiter.hasContent:hover .hover,
#akustiker .slider-auge .mitarbeiter.hasContent:hover .hover,
#akustiker .slider-ohr .mitarbeiter.hasContent:hover .hover {
  visibility: visible;
  opacity: 1;
}
#optiker .slider-auge .mitarbeiter.hasContent:hover .hover p,
#optiker .slider-ohr .mitarbeiter.hasContent:hover .hover p,
#akustiker .slider-auge .mitarbeiter.hasContent:hover .hover p,
#akustiker .slider-ohr .mitarbeiter.hasContent:hover .hover p {
  padding: 30px 10px;
}

#online-tools {
  background: #333 url("../img/bg_home.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position-x: right;
  position: relative;
}
@media (max-width: 1000px) {
  #online-tools {
    background: #333;
  }
}
#online-tools .section-inner {
  max-width: 1320px;
}
#online-tools p {
  font-family: "Open Sans", sans-serif;
  max-width: 1100px;
  margin: 20px auto !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.slider-ohr {
  margin-bottom: 6vw;
}

.markenlogo__slider {
  margin-bottom: 10px;
}
.markenlogo__slider .slide {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100px;
  padding: 10px;
}
.markenlogo__slider .slide img {
  padding: 10px;
  background: white;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 1000px) {
  .image__content-container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
}
.image__content-container .image__content-container-content {
  grid-column: 1/-1;
  grid-row: 1/-1;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 60px 20px;
}
@media (min-width: 1000px) {
  .image__content-container .image__content-container-content .inner__wrap {
    width: calc(50% - 2rem);
  }
}
.image__content-container .image__content-container-image {
  height: 100%;
  grid-column: 2/-1;
  grid-row: 1/-1;
}
.image__content-container .image__content-container-image img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.orange__heading {
  color: #F5821F;
  font-size: 28px;
  line-height: 1.2;
}
@media (min-width: 1000px) {
  .orange__heading {
    font-size: 45px;
  }
}

.m0 {
  margin: 0 !important;
}

.section__layout {
  padding: 0;
}

.subtitle {
  color: white;
  display: block;
  background: #333;
  margin: 0 !important;
  padding: 10px;
  text-align: center;
  font-size: 0.825em;
  width: 100%;
}

img {
  display: block;
}

.online-tools {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 768px) {
  .online-tools {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1000px) {
  .online-tools {
    grid-template-columns: repeat(5, 1fr);
  }
}
.online-tools .tool {
  min-width: 0;
}
.online-tools .tool img {
  max-width: 130px;
  display: block;
  margin: 0 auto;
}

.parallax__container {
  position: relative;
}
.parallax__container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-position: bottom;
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

#start-optometrie {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 80px 20px;
  gap: 40px 0;
  grid-template-columns: 1fr;
  grid-template-areas: "heading" "left" "center" "right";
}
#start-optometrie h2 {
  font-size: 35px;
}
@media (min-width: 1000px) {
  #start-optometrie {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "heading . ." "left . right";
  }
  #start-optometrie h2 {
    font-size: 45px;
  }
}
#start-optometrie .heading__container {
  grid-area: heading;
  position: relative;
}
#start-optometrie .parallax__container {
  grid-area: center;
}
@media (min-width: 1000px) {
  #start-optometrie .parallax__container {
    grid-column: 1/-1;
    grid-row: 1/-1;
  }
}
#start-optometrie .left__container {
  grid-area: left;
  position: relative;
}
#start-optometrie .right__container {
  grid-area: right;
  position: relative;
}
#start-optometrie .using-image {
  z-index: 2;
}
#start-optometrie .using-image .row-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 999px) {
  #start-optometrie .using-image .row-bg {
    background-image: none !important;
    background-color: #ffffff;
  }
}
#start-optometrie .vc_col-sm-4.hidden-desktop img {
  height: auto;
}
@media (min-width: 1000px) {
  #start-optometrie .vc_col-sm-4.hidden-desktop {
    display: none;
  }
}
@media (min-width: 1000px) {
  #start-optometrie .vc_col-sm-4.vc_col-lg-offset-4 .vc_column-inner {
    padding-top: 210px;
  }
}
#start-optometrie .span_12 {
  position: relative;
  z-index: 10;
  max-width: 1366px;
  margin: 0 auto;
  padding: 6vw 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  float: none;
  justify-content: center;
}
#start-optometrie .span_12 .headline {
  font-size: 20px;
  line-height: 27px;
  color: #F5821F;
  font-weight: 400;
  letter-spacing: 0;
  padding-bottom: 0;
  margin-bottom: 10px !important;
}
#start-optometrie .span_12 h2 {
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 25px;
  text-transform: none;
}
@media (min-width: 1000px) {
  #start-optometrie .span_12 h2 {
    font-size: 45px;
    line-height: 61px;
    margin-bottom: 30px;
  }
}
#start-optometrie .span_12 .white-text {
  background-color: #F5821F;
  padding: 20px 30px 30px;
}
#start-optometrie .span_12 .white-text p {
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0;
}
#start-optometrie .span_12 .white-text p strong {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
}
#start-optometrie .span_12 .white-text .btn_orange {
  background-color: #ffffff !important;
  color: #000000 !important;
}
#start-optometrie .span_12 .bg-orange {
  background-image: url(../img/bg-orange.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  padding: 2px 0 2px 40px;
  margin-bottom: 33px;
}
#start-optometrie .span_12 .bg-orange p {
  font-size: 18px;
  position: relative;
  line-height: 28px;
  font-weight: 700;
  color: #fff;
  padding-left: 45px;
}
#start-optometrie .span_12 .bg-orange p:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25' viewBox='0 0 29.25 29.25'%3E%3Cpath id='Icon_ionic-md-checkmark-circle-outline' data-name='Icon ionic-md-checkmark-circle-outline' d='M12,15.221,9.956,17.269l6.581,6.581L31.163,9.225,29.115,7.178l-12.578,12.5ZM29.7,18A11.65,11.65,0,1,1,21.218,6.739l2.267-2.267A13.6,13.6,0,0,0,18,3.375,14.625,14.625,0,1,0,32.625,18Z' transform='translate(-3.375 -3.375)' fill='%23fff'/%3E%3C/svg%3E%0A");
  width: 29px;
  height: 29px;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 1px;
  margin-right: 20px;
}

.content__box {
  padding: 20px 20px 40px;
}
.content__box * {
  font-size: 18px;
}
.content__box.orange * {
  color: white;
}
.content__box.orange .btn_orange {
  background-color: #ffffff !important;
  color: #000000 !important;
}

ul.checklist li {
  list-style-type: none;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
ul.checklist li:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25' viewBox='0 0 29.25 29.25'%3E%3Cpath id='Icon_ionic-md-checkmark-circle-outline' data-name='Icon ionic-md-checkmark-circle-outline' d='M12,15.221,9.956,17.269l6.581,6.581L31.163,9.225,29.115,7.178l-12.578,12.5ZM29.7,18A11.65,11.65,0,1,1,21.218,6.739l2.267-2.267A13.6,13.6,0,0,0,18,3.375,14.625,14.625,0,1,0,32.625,18Z' transform='translate(-3.375 -3.375)' fill='%23fff'/%3E%3C/svg%3E%0A");
  width: 29px;
  min-width: 29px;
  height: 29px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  position: realtiove;
  margin-right: 20px;
}
ul.checklist li.bg-orange {
  background: #F5821F;
  color: white;
}
ul.checklist li.bg-orange:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform-origin: center;
  background: inherit;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%) rotate(45deg);
}
ul.checklist li p {
  font-size: 18px;
  margin: 0 !important;
  font-weight: bold;
}

.small--heading {
  font-family: "Montserrat", sans-serif;
}

#gluecklich {
  background: #333 url("../img/bg_home.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position-x: right;
  position: relative;
}
@media (max-width: 1000px) {
  #gluecklich {
    background: #333;
  }
}
#gluecklich h1, #gluecklich p {
  color: white;
}
#gluecklich p {
  max-width: 750px;
  margin: 20px auto !important;
}
#gluecklich .slick-prev:before, #gluecklich .slick-next:before {
  color: white;
}

* {
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

body {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #3D3D3D;
  font-weight: 300;
  line-height: 23px;
}

img {
  width: 100%;
}

.textCenter {
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  padding: 30px;
}

.greifswald-intro {
  margin-top: 57px;
  width: 100%;
  position: relative;
  height: 550px;
  background: url("../partials/greifswald/img/header.jpg") center top 40%;
  background-size: cover;
}

.greifswald-team {
  background-color: #EFEFEF;
  text-align: center;
}
.greifswald-team h2 {
  font-size: 2.2em;
}
.greifswald-team h5 {
  font-size: 2.2em;
}

.team-slider-greifswald {
  font-size: 1.5em;
  line-height: 1.5;
}

.orange {
  background: #F5821F;
}

.grey {
  background: #AFAEAE;
}

.home-slider {
  margin-top: 57px;
  border-bottom: 10px solid #F5821F;
  position: relative;
}
.home-slider .slick-dotted.slick-slider {
  margin-bottom: 0;
}

@media (min-width: 1000px) {
  .home-slider {
    border-bottom: 10px solid #F5821F;
    position: relative;
  }
  .home-slider .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .home-slider .slick-dots {
    bottom: 25px;
  }
  .home-slider .slick-slide img {
    width: 100%;
  }

  .short-cuts {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .short-cuts {
    display: block;
    position: absolute;
    bottom: 10%;
    width: 100%;
    z-index: 9;
  }

  .short-cuts-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shortcut {
    padding: 2%;
  }
}
.brillen-slider {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.team-slider {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.center {
  text-align: center;
}

.short-cuts-inner {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
}

.section-grey {
  background: #EFEFEF;
  overflow: hidden;
}

.section-inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 6vw 30px;
  width: 100%;
}
.section-inner .logo {
  width: 150px !important;
  margin-top: 30px;
}
.section-inner .location-inner {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.frank_borstel {
  text-align: right !important;
  right: 60px;
  position: absolute;
  bottom: 30px;
}
@media (max-width: 1000px) {
  .frank_borstel {
    display: none;
  }
}

.stralsund-intro {
  margin-top: 57px;
  width: 100%;
  position: relative;
  height: 550px;
  background: url("../partials/stralsund/img/header.jpg") center top 40%;
  background-size: cover;
}

.stralsund-location {
  position: relative;
  height: 470px;
  margin-top: -12px;
}

.stralsund-team {
  background-color: #EFEFEF;
  text-align: center;
}
.stralsund-team h2 {
  margin-top: -20px;
  font-size: 2.2em;
}
.stralsund-team h5 {
  font-size: 2.2em;
}

.team-slider-stralsund {
  font-size: 1.5em;
  line-height: 1.5;
}
.team-slider-stralsund h2 {
  margin-top: -20px;
  font-size: 2.2em;
}
.team-slider-stralsund h5 {
  font-size: 2.2em;
}

.grimmen-intro {
  margin-top: 57px;
  width: 100%;
  position: relative;
  height: 550px;
  background: url("../partials/grimmen/img/header.jpg") center top 40%;
  background-size: cover;
}

.grimmen-location {
  position: relative;
  height: 470px;
  margin-top: -12px;
}

.grimmen-team {
  background-color: #EFEFEF;
  text-align: center;
}
.grimmen-team h2 {
  margin-top: -20px;
  font-size: 2.2em;
}
.grimmen-team h5 {
  font-size: 2.2em;
}

.team-slider-grimmen {
  font-size: 1.5em;
  line-height: 1.5;
}

.news-text {
  padding-top: 100px;
  width: 35%;
  margin: 0 auto;
}

.service-intro {
  margin-top: 57px;
  width: 100%;
  position: relative;
  height: 550px;
  background: url("../partials/service/img/Service_D05506-360.jpg") center center;
  background-size: cover;
}

.service-info {
  background-color: #EFEFEF;
  text-align: center;
}

.service-icons1 {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  height: 250px;
  margin-top: 100px;
}
.service-icons1 .one {
  width: 28%;
  text-align: center;
}
.service-icons1 .one .icon-one {
  position: relative;
  width: 150px;
  height: 150px;
  background: url("../partials/service/img/icon1.png");
  background-size: cover;
  margin: 0 auto;
}
.service-icons1 .two {
  width: 28%;
  text-align: center;
}
.service-icons1 .two .icon-two {
  position: relative;
  width: 150px;
  height: 150px;
  background: url("../partials/service/img/icon2.png");
  background-size: cover;
  margin: 0 auto;
}
.service-icons1 .three {
  width: 28%;
  text-align: center;
}
.service-icons1 .three .icon-three {
  position: relative;
  width: 150px;
  height: 150px;
  background: url("../partials/service/img/icon3.png");
  background-size: cover;
  margin: 0 auto;
}

.service-icons2 {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  height: 250px;
  margin-top: 100px;
}
.service-icons2 .four {
  width: 28%;
  text-align: center;
}
.service-icons2 .four .icon-four {
  position: relative;
  width: 150px;
  height: 150px;
  background: url("../partials/service/img/icon4.png");
  background-size: cover;
  margin: 0 auto;
}
.service-icons2 .five {
  width: 28%;
  text-align: center;
}
.service-icons2 .five .icon-five {
  position: relative;
  width: 150px;
  height: 150px;
  background: url("../partials/service/img/icon5.png");
  background-size: cover;
  margin: 0 auto;
}
.service-icons2 .six {
  width: 28%;
  text-align: center;
}
.service-icons2 .six .icon-six {
  position: relative;
  width: 150px;
  height: 150px;
  background: url("../partials/service/img/icon6.png");
  background-size: cover;
  margin: 0 auto;
}

.people .section-inner {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0;
  line-height: 0;
}

/* --------------- IMAGE POPUP -----------------*/
.slider-nav img {
  max-width: 100%;
}

.slider-for img {
  width: 100%;
}

.slider-nav slick-slide:hover {
  cursor: pointer;
}

.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
}
.slick-prev:before, .slick-next:before {
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 60px;
  opacity: 1;
  width: 30px;
}
.slick-prev:hover:before, .slick-next:hover:before {
  opacity: 0.8;
}

.modal.in .modal-dialog {
  width: 90%;
}

.modal.in {
  display: flex !important;
  align-items: center;
}

#myModal {
  padding: 0 !important;
  align-items: center;
}

.modal-content {
  background-color: white;
  border: 0 none;
  border-radius: 0;
  margin-left: 1px;
}
.modal-content .modal-body {
  padding: 0;
}
.modal-content .modal-body iframe {
  margin-bottom: -5px;
}
.modal-content .modal-header {
  border: 0 none;
  height: 0;
  min-height: 0;
  padding: 0;
}
.modal-content .modal-header .close {
  background-color: #000000 !important;
  border: 2px solid #ffffff !important;
  border-radius: 13px;
  color: #ffffff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  height: 26px;
  opacity: 1;
  padding-bottom: 0;
  position: absolute;
  right: -13px;
  text-shadow: none;
  top: -13px;
  width: 26px;
  z-index: 1;
}

.soft-row.wide {
  max-width: 100% !important;
}

.gap-1 {
  gap: 10px;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 640px) {
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 960px) {
  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.standort--info {
  flex: 1 0 0;
  border-radius: 15px;
  background: white;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}
@media (max-width: 959px) {
  .standort--info {
    grid-template-columns: 100px 1fr;
  }
}
@media (min-width: 640px) and (max-width: 849px) {
  .standort--info {
    grid-template-columns: 1fr;
  }
}
.standort--info .standort--content {
  padding: 15px;
  text-align: left;
}
.standort--info .standort--header {
  background: #F5821F;
}
@media (min-width: 640px) and (max-width: 849px) {
  .standort--info .standort--header img {
    max-width: 50%;
  }
}
@media (min-width: 960px) {
  .standort--info .standort--header img {
    max-width: 70%;
  }
}
.standort--info p {
  margin: 0 !important;
  padding: 0;
}
.standort--info strong {
  font-weight: 900;
}

.heading--special {
  color: #F5821F;
  font-size: 3rem;
}
@media (max-width: 650px) {
  .heading--special {
    font-size: 1.6em;
  }
}

.ticker {
  font-size: 1.6em;
  font-weight: bold;
  background: #F5821F;
  color: white;
  padding: 10px 0;
  border-radius: 5px;
  margin: 2em 0;
  display: flex;
}
@media (max-width: 650px) {
  .ticker {
    font-size: 1.4em;
  }
}
.ticker .highlight {
  padding: 0;
  margin: 0 !important;
  white-space: nowrap;
  animation: move infinite 10s linear;
  position: relative;
  display: inline-block;
}

@keyframes move {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
@media (min-width: 960px) {
  .flex--row {
    display: flex;
    position: relative;
  }
}
.flex--row img {
  display: block;
  width: 100%;
  height: auto;
}
.flex--row .bg-column {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.flex--50 {
  flex: 0 0 50%;
  position: relative;
  display: grid;
  place-items: center;
}
.flex--50.bg {
  min-height: 80vw;
  height: auto !important;
}
@media (min-width: 960px) {
  .flex--50.bg {
    min-height: 35vw;
  }
}

.order--1 {
  order: 1;
}

.order--2 {
  order: 2;
}

.align--center {
  align-items: center;
}

.align--end {
  align-items: stretch;
}
@media (min-width: 1400px) {
  .align--end {
    align-items: stretch;
  }
}

@media (min-width: 1400px) {
  .flex--l-0 {
    flex: 0 0 auto;
  }
}
@media (min-width: 2500px) {
  .large--absolute {
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1200px;
  }
}
.container--boxed {
  padding: 30px;
  max-width: 1000px;
}
@media (min-width: 960px) {
  .container--boxed {
    padding: 30px 40px;
  }
}
@media (min-width: 1400px) {
  .container--boxed {
    padding: 60px 80px;
  }
}
@media (min-width: 2000px) {
  .container--boxed {
    max-width: 1200px;
  }
}

@media (min-width: 690px) {
  .vc_col-sm-4 {
    width: 33.33333333%;
  }
}
@media (min-width: 1300px) {
  .vc_col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
}
@media (min-width: 1000px) {
  .vc_col-md-offset-3 {
    margin-left: 25%;
  }
}
.clr--orange {
  color: #F5821F;
}

.intro__logo {
  max-width: 400px;
  margin-bottom: 60px;
}

.slider-auge, .slider-ohr {
  max-width: 1600px;
  margin: 0 auto;
}
.slider-auge .slick-next, .slider-auge .slick-prev, .slider-ohr .slick-next, .slider-ohr .slick-prev {
  width: 40px;
  height: 40px;
  margin: 0;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}
.slider-auge .slick-next:before, .slider-auge .slick-prev:before, .slider-ohr .slick-next:before, .slider-ohr .slick-prev:before {
  content: "" !important;
  background-size: 50% !important;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
}
.slider-auge .slick-next, .slider-ohr .slick-next {
  right: 10px;
}
.slider-auge .slick-next:before, .slider-ohr .slick-next:before {
  background-image: url("../img/next.svg");
}
.slider-auge .slick-prev, .slider-ohr .slick-prev {
  left: 10px;
}
.slider-auge .slick-prev:before, .slider-ohr .slick-prev:before {
  background-image: url("../img/prev.svg");
}

.header__image {
  width: 100%;
  height: auto;
  max-height: 700px;
  object-fit: cover;
}

.container {
  width: 100%;
  max-width: 1600px;
  padding: 5vw 20px;
}
.container p {
  max-width: 80ch;
  font-size: 18px;
}
.container p * {
  font-size: inherit;
}