#sehtraining-header
    //Menü Desktop
    .menu--desktop 
        width: 100%
        background: $dark-grey 
        height: 60px
        ul 
            display: flex
            align-items: center
            max-width: 1200px
            margin: 0 auto
            justify-content: center
        li 
            list-style: none
            padding: 0 12px
            height: 60px
            display: flex
            align-items: center
            a 
                color: white   
                text-align: center
                @media (max-width: 1100px)
                    font-size: 12px
            &:hover
                background: $theme-color
        @media (max-width: 1000px)
            display: none

    //Menü Mobil
    .selectBox 
        display: none
        @media (max-width: 1000px)
            display: block
            position: relative
            max-width: 1200px
            margin: 0 auto
            &:after 
                content: "\25BE"
                position: absolute
                right: 20px
                top: 8px
                font-size: inherit
                transform: rotate(0deg)
                transition: all 0.3s ease
                color: white
            #select 
                width: 100%
                background: white 
                border: none
                padding: 10px 20px
                text-align: left
                background: $dark-grey
                border-radius: 0 
                margin-bottom: 0px
            .selectContent 
                display: none
                width: 100%
                position: absolute
                left: 0
                top: 100%
                overflow-y: auto
                background: #eee
                padding: 20px
                &.active
                    display: block
                ul

                    li 
                        list-style-type: none
                        padding-bottom: 5px
                        a 
                            color: grey
                            &:hover 
                                color: black
        .selectBox.active
            &:after
                transform: rotate(-180deg) 
    // Headerbild
    .headerImage
        width: 100%
        height: 50vw
        min-height: 300px
        max-height: 500px
        background: url(../img/sehtraining/sehtraining.jpg) center 20% no-repeat
        background-size: cover
    // Einleitung
    .headerText
        max-width: 850px
        margin: 30px auto
        text-align: center
        padding: 30px

.sehtraining
    .text-orange 
        color: $theme-color
    .content 
        max-width: 850px
    .soft-row
        justify-content: center
        align-items: center
        .col-1
            padding: 10px 0
    h3, h2
        font-size: 1.9em
        font-weight: 700
        text-transform: uppercase
        line-height: 1.2
        margin-bottom: 20px
    ul 
        margin-left: 17px
    #zitat
        padding: 0
        margin: 60px auto 0
    .section-inner
        max-width: 850px
        padding: 6vw 0
    .text-center
        text-align: center
    
    .gutschein
        margin-left: 10px
        @media (max-width: 649px)
            margin-left: 0px
        img 
            box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.3)
    
    .toggle
        dt 
            @media (max-width: 600px)
                padding: 15px 50px 15px 20px!important
    #autoplay
        margin-right: 20px
        @media (max-width: 600px)
            margin-right: 0px
    .mitarbeiter
        width: 100%
        min-width: 300px
        max-width: 300px
        margin-right: 20px
        @media (max-width: 480px)
            margin-right: 0px
        .imageMitarbeiter
            &.maren
                background: url('../img/mitarbeiter/maren-urgast.jpg')
                background-position: center
            &.angela
                background: url(../img/mitarbeiter/angela-herzig.jpg)
                background-position: 30%
        em
            color: white
            text-align: center
            font-size: 0.8rem
            line-height: 1rem
            max-width: 100%
        &:hover
            .bild-name
                padding: 30px 10px!important
    .bild
        transition: all 300ms ease
        position: relative
        overflow: hidden
        border-bottom: 2px solid #cdcdcd
        transform: rotateY(0deg)
        z-index: 0
        min-width: 300px
        .imageMitarbeiter
            height: 0
            padding-bottom: 100%
            width: auto
            position: relative
            left: 50%
            transform: translate(-50%)
            background-size: cover!important
        .bild-name
            background: #333333
            width: 100%
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            position: absolute
            bottom: 0
            left: 0
            padding: 10px
            min-height: 68px
            transition: all 300ms ease
        p
            color: white 
            padding: 0
            margin: 0!important
            font-size: 0.85em
            line-height: 1.2
            text-align: center
        strong
            font-size: 1.2em
            text-align: center
    .red 
        color: red
        
.toggle.sehtraining
    padding: 6vw 20px 0
    .soft-row
        max-width: 850px
        .toggle
            padding: 20px 0
            @media (max-width: 850px)
                padding: 20px

.section-grid
    padding-bottom: 0     
    .grid
        padding-bottom: 0!important
        display: grid
        max-width: 850px
        margin: 0 auto
        grid-template-columns: 1fr 1fr
        grid-template-rows: auto auto 180px
        grid-template-areas: 'a1 a2' 'content bild' 'c2 c3'
        @media (max-width: 740px)
            grid-template-columns: 1fr
            grid-template-rows: auto auto auto
            grid-template-areas: 'a1' 'content' 'bild'
        .content
            grid-area: content
            position: relative
            text-align: left
        .headline 
            grid-column-start: 1
            grid-column-end: 3
            grid-row-end: 1
            grid-row-start: 1

        img    
            max-width: 100%
            grid-column-start: 1
            grid-column-end: 4
            grid-row-end: 4
            grid-row-start: 1
            align-self: end
            @media (max-width: 740px)
                grid-column: 1 
                grid-row: 3   
// Übungen
#sehtraining-uebungen
    .uebung1
        margin: 0 auto
        display: flex
        align-items: flex-end
        flex-direction: column
        margin-bottom: 20px
        &:after
            content: ""
            height: 2px
            background: $theme-color
            width: 100%
            display: block
            margin-top: -30px
        img 
            max-width: 300px
            animation: maus 6s forwards infinite
            @media (max-width: 768px)
                max-width: 200px
                margin-bottom: 10px
            @media (max-width: 480px)
                max-width: 100px
                margin-bottom: 20px
    .hakini-mudra
        align-items: flex-start!important
        @media (max-width: 721px)
            display: block
    .soft-row
        .kreis
            background: black
            width: 100px
            height: 100px
            border-radius: 100%
            margin: 5px
            margin-top: 20px
            @media (max-width: 400px)
                width: 80px
                height: 80px
            &.rot
                background: #E30613
            &.blau
                background: #009FE3
    .gehirnhaelftenintegration
        max-width: 350px
        .soft-row
            display: flex
            max-width: 350px!important
            justify-content: space-between
            span 
                font-size: 1.9em
                font-weight: 700
                margin-bottom: 10px
                @media (max-width: 480px)
                    font-size: 1.5em
                &.rot
                    color: #ED1B23
                &.blau
                    color: #00AEEF
                &.orange
                    color: #F68B1E
                    background: transparent
                &.violett
                    color: #2E3092
                &.gruen
                    color: #40AD49
//Basics
.tipps
    .col-1
        padding: 0 10px!important 
        .background
            background: $grey
            height: 100%
            min-height: 80px
            display: flex 
            align-items: center
            padding: 10px
            margin: 10px 0
            &:last-child
                margin: 10px 0 0
            @media (max-width: 750px)
                min-height: 100px
            span 
                background: $theme-color
                padding: 5px 12px
                border-radius: 50%
                color: white
                text-align: left
                margin-right: 10px
            p 
                text-align: left
                margin: 0!important



//Footer 
.footer--sehtraining
    margin-bottom: 60px
    @media (max-width: 798px)
        margin-bottom: 115px

// Sticky Footer
.sticky--sehtraining
    position: fixed
    bottom: 0
    width: 100%
    display: grid
    grid-template-columns: 1fr 50px auto 1fr
    grid-template-rows: auto auto auto
    background: $dark-grey
    grid-template-areas: "i ausrufezeichen a b" "c ausrufezeichen text d" "e f g h"
    @media (max-width: 850px)
       grid-template-columns: 10px 40px auto 10px 
    @media (max-width: 768px)
       grid-template-rows: auto auto 30px
       height: 120px
    .ausrufezeichen
        grid-area: ausrufezeichen
        font-size: 7rem
        color: white
        font-family: 'Montserrat', sans-serif
        font-weight: 700
        color: $theme-color
        animation: move 1s infinite
    .sticky--text
        grid-area: text
        color: white
        strong
            @media (max-width: 470px)
                font-size: 11px
                line-height: 1
    .background    
        grid-column-start: 1
        grid-column-end: 5
        grid-row-end: 2
        grid-row-start: 4


// Animationen 
@keyframes move
    0% 
        margin-top: 0px
    50%
        margin-top: 10px
    100%
        margin-top: 0px

@keyframes maus
    0% 
        margin-left: -50%
    45%
        margin-right: 60%
    46%
        transform: scaleX(1)
    47%
        transform: scaleX(-1)
    48%
        margin-right: 60% 
    95%
        transform: scaleX(-1)  
    100%
        margin-left: -50%
        transform: scaleX(1)


                


