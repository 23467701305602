#hoerakustik-header
	background: $grey
	background: white
	.header--slider-akustik
		position: relative
		.slick-dots
			position: absolute
			bottom: 0rem
			left: 50%
			transform: translateX(-50%)
			width: auto
			display: flex
	.headerImage
		width: 100%
		max-width: 100vw
		height: 70vw
		min-height: 400px
		max-height: 850px
		background: url(../img/hoerakustik/header_verstehen.jpg) right top no-repeat
		// background-size: auto
		background-size: cover
		// background-attachment: fixed
		@media (max-width: 940px)
			background-position: right 30% top
			background-size: cover
			background-attachment: initial!important
		h2
			strong
				font-size: inherit!important
				font-weight: bold
				margin-bottom: 0!important
		&.slide6
			background-position: right
			@media (max-width: 940px)
				background-position: 76%, 50% !important
			&:after
				content: ""
				position: absolute
				right: 20px
				bottom: 20px
				width: 30vw
				min-width: 250px
				max-width: 450px
				height: 100%
				background: url('../img/hoerakustik/resound_forsa_cta.png') bottom right no-repeat
				background-size: contain
			.content
				height: 100%
				display: flex
				align-items: center
				@media (max-width: 940px)
					align-items: flex-start
				h2
					color: white
		&.slide1
			display: flex
			align-items: center
			background-position: right top!important
			@media (max-width: 1200px)
				background-position: right 30% top!important
			.content
				width: 100%
				padding-bottom: 6rem
				h2
					width: 100%
					color: white

		small
			position: absolute
			bottom: 2rem
			right: 2rem
			max-width: 20ch
			@media (max-width: 960px)
				left: 1.5rem
				right: auto
			@media (min-width: 961px) and (max-width: 1200px)
				left: 3rem

		&.image2
			background: url(../img/hoerakustik/bh-slider-strand.jpg) center no-repeat
			background-size: auto
			background-size: cover
			// background-attachment: fixed
			.content
				h2
					max-width: 100%
					width: 600px
		.content  
			padding: 8rem 0 0 3rem
			@media (max-width: 940px)
				padding: 6rem 0 0 1rem
			strong
				font-weight: bold
				font-size: 1.6rem
				display: block
				margin-bottom: 20px
				text-transform: uppercase
				@media (max-width: 940px)
					font-size: 1rem
					margin-bottom: 10px
		h2
			font-size: 3rem
			width: 500px
			max-width: 50%
			@media (max-width: 940px)
				font-size: 1.6rem
				line-height: 1.2
			// color: white
			// text-shadow: 0 0 1rem rgba(black, 0.25)
	.headerText
		max-width: 1200px
		margin: 30px auto
		text-align: center
		padding: 30px
.akustik_logo
	max-width: 350px
	margin: 30px auto
.grimmerTeam
	margin: 30px auto

.headerText
	max-width: 750px
	margin: 30px auto
	text-align: center
	padding: 30px
	h3
		margin-bottom: 1rem
		font-size: 1.6rem
#logos
	margin-bottom: 50px
	.soft-row
		div
			max-width: 250px
#romanus_bild_text
	background-color: $grey
	padding: 65px 0px 55px 0px
	.veronique
		max-width: 250px
		div
			background-color: #3A3A3A
			padding: 10px
			color: white !important
			font-size: 13px !important
			position: relative
			top: -7px
	.text
		max-width: 600px
		padding-left: 20px
		padding-right: 20px
		@media (max-width: 849px)
			text-align: center
			margin-top: 20px
		.btn_orange
			background: #F4831E
			padding: 10px 15px
			border-radius: 20px
			color: white
			font-weight: bold
			margin-top: 20px
			display: table
			@media (max-width: 849px)
				margin: 0 auto
.aic
	align-items: center
.schritte--hoergeraet
	display: flex
	flex-wrap: wrap
	margin: 2rem 0
	.schritt
		position: relative
		flex: 1 0 0
		margin: 0.5rem
		padding: 2rem
		cursor: pointer
		background: $body-font-color
		transition: all 300ms ease
		@media (max-width: 800px)
			flex: 1 0 40%
		@media (max-width: 420px)
			flex: 1 0 90%
		&:before
			transition: all 300ms ease
			content: ""
			position: absolute
			left: 0.5rem
			top: 0.5rem
			right: 0.5rem
			bottom: 0.5rem
			border: 1px solid $theme-color
			pointer-events: none
		&:hover
			background: $a-color
			box-shadow: 0 0 1rem rgba(black, 0.2)
			&:before
				border-color: white
				left: 0.2rem
				top: 0.2rem
				right: 0.2rem
				bottom: 0.2rem
			.info
				font-size: 3rem
		img
			margin-bottom: 1rem
		h4
			line-height: 1.4
			color: white
		.info
			position: absolute
			right: 0.5rem
			top: 0.5rem
			width: 40px
			height: 40px
			background: $theme-color
			display: flex
			align-items: center
			justify-content: center
			color: white
			font-size: 2rem
			z-index: 0
			transition: all 300ms ease
#overlay.modal--akustik
	padding: 20px
	.popupContent
		// padding-top: 60px
		position: relative
		left: 0
		top: 0
		right: 0
		bottom: 0
		height: 100%
		overflow-y: auto
		.popupText
			padding-top: 60px
		.popupText, .popupImage
			@media (max-width: 1250px)
				width: 100%
			height: auto
			strong
				font-size: inherit
				font-weight: bold
			ul
				li
					margin-left: 20px
		.popupImage
			background-position: top center
			@media (max-width: 800px)
				min-height: 300px
			min-height: 360px
			@media (max-width: 1250px)
				height: 50vw

			// background-size: 100%!important
	.lupe
		z-index: 9999999999
		position: absolute


#schritt--1
	.popupImage
		background-position: center center!important

.threesixty
	//display: none
	.button--container
		display: flex
		align-items: center
		justify-content: center
		.btn_orange
			margin: 2rem 1rem 
		.btn_orange:not(.active)
			background: $button-grey
	.soft--row
		align-items: center
		display: flex
		max-width: 1200px
		margin: 0 auto 3rem
		@media (max-width: 940px)
			display: block
			.col-1
				padding: 0 30px!important
				&.order-2
					margin-bottom: 1rem

		.col-1
			flex: 1 0 45%
			// padding: 30px
			position: relative
			&.order-2
				padding-right: 30px
			h3
				margin-bottom: 1rem
				font-size: 1.6rem
			.interface
				position: absolute
				pointer-events: none
				left: 0
				top: 0
				width: 100%
				height: 100%
				cursor: ew-resize!important
				border: 1px solid $grey
				.sprite
					cursor: ew-resize
				img
					display: block
				.controllers
					position: absolute
					left: 0
					top: 0
					width: 100%
					height: 100%
					.controller
						pointer-events: all
						position: absolute
						width: 50px
						height: 50px
						display: flex
						align-items: center
						justify-content: center
						padding: 10px
						background: #ccc
						cursor: pointer
						img
							max-width: 100%
							max-height: 100%
						&.active
							background: $theme-color
						&.left
							left: 0
							top: 50%
							transform: translateY(-50%)
						&.right
							right: 0
							top: 50%
							transform: translateY(-50%)
						&.female
							bottom: 0
							right: 0
						&.male
							bottom: 0
							right: 50px
						&.ido
							bottom: 0
							left: 0
						&.hdo
							bottom: 0
							left: 50px
						&.hdo-ext
							bottom: 0
							left: 100px
					.bottom--controls
						position: absolute
						left: 0
						bottom: 0
						width: 100%
						height: 50px
						display: block
						background: rgba(white, 0.8)

			.hoergeraete
				margin-top: 2rem
				align-items: stretch
				flex-wrap: wrap
				margin-bottom: 0
				
				
				.select--image
					flex: 1 0 25%
					//min-width: 120px
					display: flex
					flex-direction: column
					align-items: center
					justify-content: center
					padding: 2rem
					text-align: center
					background: #ccc
					margin-right: 10px
					cursor: pointer
					position: relative
					.tool-tip
						box-shadow: 0 0 1rem rgba(black, 0.2)
						transition: all 300ms ease
						margin: 0!important
						position: absolute
						z-index: 1000
						top: 10px
						left: 50%
						transform: translate(-50%,50%)
						width: 90%
						padding: 5px
						background: white
						border-radius: 4px
						color: black!important
						visibility: hidden
						opacity: 0
					&:hover
						.tool-tip
							opacity: 1
							transform: translate(-50%,0%)
							visibility: visible

					img
						max-width: 100%
						height: auto

					&.ido
						img
							width: 107px
							// height: 58px
					&.hdo
						img
							width: 160px
							// height: 125px
					&.hdo-ext
						img
							width: 152px
							// height: 144px
					p
						margin-bottom: 0!important
						font-size: 80%
						line-height: 1.4
					&:first-child
						margin-left: 0
					&:last-child
						margin-right: 0
					&.active
						background: $theme-color
						p
							color: white
				@media (max-width: 940px)
					display: flex
					.select--image
						padding: 1rem


.hoerloesungen
	background: $grey
	padding: 30px
	padding-bottom: 30px

	.container
		padding: 60px 0 20px
		max-width: 1200px
		width: 100%
		margin: 0 auto
		h2
			margin: 0 auto
			text-align: center

		.hoergeraete
			margin-top: 2rem
			display: flex
			.hoergeraet
				flex: 1 0 25%
				background: rgba(white, 0.7)
				padding: 2rem
				margin-right: 1rem
				ul
					li
						margin-left: 20px
				h3
					font-size: 1.6rem
					line-height: 1.2
					font-weight: bold
					// height: 100px
				&:last-child
					margin-right: 0
				.image--container
					width: 100%
					height: 180px
					display: flex
					align-items: center
					justify-content: center
					img
						width: auto
						max-width: 100%
						height: auto
				&.ido
					.image--container
						img
							width: 107px
							height: 58px
				&.hdo
					.image--container
						img
							width: 160px
							height: 125px
				&.hdo-ext
					.image--container
						img
							width: 152px
							height: 144px
			@media (max-width: 940px)
				display: block
				.hoergeraet
					h3
						font-size: 1.2rem
						height: auto
					display: flex
					padding: 1rem
					flex-wrap: wrap
					margin: 0 0 10px
					justify-content: center
					h3
						flex: 1 0 100%
					.image--container
						flex: 0 0 20%
						min-width: 200px
					.hoergeraete--content
						flex: 1 0 50%
	.hersteller--icons
		display: flex
		background: white
		width: 100%
		max-width: 1200px
		justify-content: space-around
		margin: 0 auto
		.iconWrapper
			flex: 1 0 30%
			max-width: 200px

.orange-color
	color: $theme-color
	font-size: inherit

.veronique
	background: url('../img/hoerakustik/veronique_romanus.jpg') center no-repeat
	background-size: cover
	padding: 30px
	height: 50vw
	min-height: 400px
	max-height: 800px
	display: flex
	flex-direction: column
	justify-content: center
	.content
		max-width: 1200px
		margin: 0 auto
		width: 100%
	.half--width
		width: 50%
		max-width: 700px
		color: white

		h2
			color: $theme-color
		small
			margin-top: 30px
			display: block
			line-height: 1.6
			font-size: 80%
	@media (max-width: 940px)
		height: auto
	@media (max-width: 560px)
		display: block
		background: url('../img/hoerakustik/veronique_romanus_mobile.jpg') bottom center no-repeat
		background-size: contain
		background-color: #000
		.half--width
			width: 100%
			margin-bottom: 70vw


.stellenangebote
	display: none
	padding: 30px
	text-align: center
	.container
		width: 100%
		max-width: 1200px
		margin: 0 auto
		.stelle
			padding: 2rem 0
			border-bottom: 1px solid rgba(black, 0.5)
			text-align: center
			&:last-child
				border-bottom: none

			strong
				font-weight: bold
				margin-bottom: 0!important
			p
				text-align: center
				max-width: 700px
				display: block
				margin: 10px auto 0!important
.hoertest
	background: #EFEFEF!important
	padding-top: 40px
	padding-bottom: 40px
.App
	max-width: 1200px!important
	.main--button
		@extends .button
		border-radius: 100px
		color: white!important
		&:hover
			background: lighten($button-orange, 5%)
			padding: 10px 40px!important
	h2
		font-weight: 600
	p
		margin: 0 auto!important
	.progess--bar > span
		background: white
	.range--container > div
		&:first-of-type
			background: white
	.back--button
		p
			width: auto!important
			margin: 0 0 0 10px!important
	.result--table-row-numbers > p,
	.result--table-row > p
		margin-left: 10px!important
	.result--footer-content p
		margin-left: 10px!important
.aktion
	background: $theme-color
	padding: 6rem 2rem 6rem
	.container
		width: 100%
		max-width: 1160px
		margin: 0 auto
		display: flex
		flex-wrap: wrap
		align-items: flex-start
		.text__content
			flex: 1 0 30%
			padding-right: 2rem
			max-width: 733px
			margin: 0 auto 2rem
			*
				color: white
			h2
				font-size: 3rem
				margin-bottom: 0.5rem
			h3
				font-size: 1.5rem
				line-height: 1.2
		.aktionscontainer
			flex: 1 0 60%
			margin: 0 auto
			// align-items: center
			background: white
			display: block
			@media (min-width: 760px)
				// margin: 0
				display: flex
				min-width: 733px
				max-width: 733px

			.image__container
				width: 100%
				padding-bottom: 70%
				@media (min-width: 760px)
					padding-bottom: 0
					width: 50%
				// height: 100%
				// background: red
				position: relative
				background-size: cover
				&:before
					content: ""
					background: url(../img/hoerakustik/aktionsbild_frei.png) no-repeat
					background-position: right bottom
					background-size: cover
					top: 0
					left: 0%
					width: 101.7%
					height: 110.2%
					@media (min-width: 760px)
						background-size: contain
						background-position: right top
						top: 0
						left: 0%
						width: 102.2%
						height: 107.2%

					position: absolute

			.aktionscontent
				background: white
				padding: 1.5rem
				@media (min-width: 760px)
					width: 50%
				// height: 76.5%
				ul
					li
						margin-left: 20px
						p
							margin: 0!important
					ul
						margin: 10px 0 20px
				small
					font-size: 0.8rem
					margin-top: 1rem
					display: block
				h4
					font-size: 1.5rem
					line-height: 1.2
					margin-bottom: 0.75rem

.flex--row
	@media (min-width: 960px)
		display: flex


.flex--50
	flex: 1 0 50%

.order--1
	order: 1
.order--2
	order: 2
.align--center
	align-items: center
.align--end
	align-items: flex-end
	@media (min-width: 1400px)
		align-items: center

img
	display: block
	width: 100%
	height: auto

@media (min-width: 1400px)
	.flex--l-0
		flex: 0 0 auto
@media (min-width: 2500px)
	.large--absolute
		position: absolute
		left: 50%
		transform: translateX(-50%)
		max-width: 1200px

.container--boxed
	padding: 2rem
	max-width: 1000px
	@media (min-width: 960px)
		padding: 4rem 4rem 4rem 0rem
	@media (min-width: 2000px)
		max-width: 1200px