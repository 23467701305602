#kontaktlinsen-header
	background: $grey
	.headerImage
		width: 100%
		height: 50vw
		min-height: 300px
		max-height: 500px
		background: url(../img/headerKontaktlinsen.jpg) center no-repeat
		background-size: cover

	.headerText
		max-width: 750px
		margin: 30px auto
		text-align: center
		padding: 30px

#kontaktlinsen-toggle
	background: white
	padding: 20px
	.toggle
		padding: 0
	.soft-row
		display: flex
		justify-content: center
		flex-wrap: wrap
		max-width: 650px
		margin: 0 auto
		dl
			border: 1px solid $grey
			margin-bottom: 20px
			dt
				font-weight: bold
				font-size: 1.2em
				padding: 15px 20px
				background: $grey
				position: relative
				text-transform: uppercase
				cursor: pointer
				transition: all 300ms ease
				&:hover
					background: darken($grey, 4%)
				&:after
					content: "+"
					position: absolute
					width: 30px
					height: 30px
					background: $button-orange
					right: 20px
					top: 50%
					transform: translateY(-50%)
					color: white
					border-radius: 50%
					text-align: center
					line-height: 30px
					font-weight: 300
					font-size: 1.4em
			dd
				height: 0
				overflow: hidden
				transition: all 300ms ease
				padding: 0px 20px

			&.toggleActive
				transition: all 300ms ease
				dt
					background: white
					&:after
						content: "–"
						line-height: 26px
				dd
					height: 100%
					padding: 10px 20px
	.kontaktlinsenImg
		margin: 0 auto
		max-width: 650px
		img 
			max-width: 650px
			

#kontaktlinsen-partner
	.soft-row
		display: flex
		justify-content: center
		align-items: center
		flex-wrap: wrap
		max-width: 650px
		margin: 0 auto
		.kl-partner
			margin: 20px
			max-width: 180px
			min-width: 150px
			flex: 1 0 0
			height: 90px
			background: red
		#kl1
			background: url(../img/kl-logos/bl.svg) center no-repeat
			background-size: contain

		#kl2
			background: url(../img/kl-logos/alcon.svg) center no-repeat
			background-size: contain

		#kl3
			background: url(../img/kl-logos/coopervision.jpg) center no-repeat
			background-size: contain

		#kl4
			background: url(../img/kl-logos/jenalens.png) center no-repeat
			background-size: contain

		#kl5
			background: url(../img/kl-logos/galifa.png) center no-repeat
			background-size: contain

		#kl6
			background: url(../img/kl-logos/sauflon.png) center no-repeat
			background-size: contain

		#kl7
			background: url(../img/kl-logos/hecht.png) center no-repeat
			background-size: contain

		#kl8
			background: url(../img/kl-logos/johnsonjohnson.svg) center no-repeat
			background-size: contain

#mitglied
	margin: 100px 0

	p
		text-align: center
	img
		width: 100px
		margin: 0 auto
		justify-content: center
		align-items: center
		display: block
.info--box
	background: white
	margin-bottom: 2rem
	padding: 1rem
	padding-top: 2rem
	border-top: 4px solid $theme-color
	border-radius: 5px
	h3
		text-transform: uppercase
		font-weight: bold