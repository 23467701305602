#arbeitsplatz-header
  background: $grey
  .headerImage
    // width: 100%
    // height: 50vw
    // min-height: 300px
    // max-height: 500px
    background: url(../img/headerArbeitsplatz.jpg) center 20% no-repeat
    background-size: cover
    @media (max-width: 1500px)
      background-position: center 35%
  .headerText
    max-width: 750px
    margin: 30px auto
    text-align: center
    padding: 30px

.arbeitsplatzbrillen, .sehtraining
  margin-top: 30px
  padding: 20px
  .content
    max-width: 750px
    margin: 0 auto
    text-align: center

  .soft-row
    display: flex
    flex-wrap: wrap
    .col-1
      flex: 1 0 0
      padding: 10px
      min-width: 300px
      p 
        margin-top: 0!important
  .reverse
    flex-wrap: wrap-reverse