
.locationHeader
  .location
    background: #efefef
    position: relative
    // margin-top: 60px
    height: 50vw
    max-height: 650px
    min-height: 250px
    width: 100%
    .image-location
      width: 100%
      height: 100%
      position: absolute
      background-position: center
      background-size: cover
      background-repeat: no-repeat
      background-color: $theme-color
      &.stralsund.contain
        background-size: contain
        background-color: $theme-color
        @media (max-width: 1860px)
          background-size: cover
          background-position: center bottom
        @media (max-width: 1620px)
          background-position: left calc(100% - 25px)
          background-size: cover

      &.demmin1
        background: url(../img/demmin/demmin-1.jpg) center
        background-size: cover
      &.demmin2
        background: url(../img/demmin/demmin-2.jpg) center
        background-size: cover
      &.demmin3
        background: url(../img/demmin/demmin-3.jpg) center
        background-size: cover
      &.demmin4
        background: url(../img/demmin/demmin-4.jpg) center
        background-size: cover
      &.demmin5
        background: url(../img/demmin/demmin-5.jpg) center
        background-size: cover
      &.demmin6
        background: url(../img/demmin/demmin-6.jpg) center
        background-size: cover
      &.greifswald1
        background: url(../img/greifswald/greifswald-1.jpg) center
        background-size: cover
      &.greifswald2
        background: url(../img/greifswald/greifswald-2.jpg) center
        background-size: cover
      &.greifswald3
        background: url(../img/greifswald/greifswald-3.jpg) center
        background-size: cover
      &.greifswald4
        background: url(../img/greifswald/greifswald-4.jpg) center
        background-size: cover
      &.greifswald5
        background: url(../img/greifswald/greifswald-5.jpg) center
        background-size: cover
      &.greifswald6
        background: url(../img/greifswald/greifswald-6.jpg) center
        background-size: cover

      &.grimmen1
        background: url(../img/grimmen/grimmen-1.jpg) center
        background-size: cover
      &.grimmen2
        background: url(../img/grimmen/grimmen-2.jpg) center
        background-size: cover
      &.grimmen3
        background: url(../img/grimmen/grimmen-3.jpg) center
        background-size: cover
      &.grimmen4
        background: url(../img/grimmen/grimmen-4.jpg) center
        background-size: cover
      &.grimmen5
        background: url(../img/grimmen/grimmen-6.jpg) center
        background-size: cover
      &.grimmen6
        background: url(../img/grimmen/grimmen-5.jpg) center
        background-size: cover

      &.stralsund1
        background: url(../img/stralsund/stralsund-1.jpg) center
        background-size: cover
      &.stralsund2
        background: url(../img/stralsund/stralsund-2.jpg) center
        background-size: cover
      &.stralsund3
        background: url(../img/stralsund/stralsund-3.jpg) center
        background-size: cover
      &.stralsund4
        background: url(../img/stralsund/stralsund-4.jpg) center
        background-size: cover
      &.stralsund5
        background: url(../img/stralsund/stralsund-5.jpg) center
        background-size: cover
      &.stralsund6
        background: url(../img/stralsund/stralsund-6.jpg) center
        background-size: cover

      .subtitle
        color: white
        display: block
        background: #333
        margin: 0!important
        padding: 10px
        text-align: center
        font-size: 0.825em
        position: absolute
        width: 100%
        bottom: 0
        @media (max-width: 650px)
          .subtitle
            display: none
          .slick-dots
            bottom: 26%


  .text-location
    width: 100%
    z-index: 20
    background: white
    position: relative
    padding: 20px
    margin: 0
    text-align: center
    h1, h2
        margin-bottom: 20px
    h2
        font-size: 18px

    h3
        font-size: 14px
        margin: 0
    .corona-hinweis
      max-width: 500px
      margin: 20px auto!important
    p
      margin: 0 20px 20px 0!important
    button
      margin-bottom: 0
  

.corona-hinweis
  max-width: 500px
  margin: 20px auto!important

.closed-info
  font-weight: bold!important
  padding: 10px 20px
  border: 2px solid $theme-color
  display: inline-block
  position: relative
  &:before
    content: ""
    position: absolute
    width: calc(100% + 11px)
    height: calc(100% + 11px)
    border: inherit
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
.team
  text-align: center
  h2
    font-size: 2.2em
    line-height: 1.2
    margin: 0
    @media (max-width: 650px)
      font-size: 1.6em
  h3
    font-size: 2.2em
    line-height: 1.2
    text-transform: none
    margin: 0
    font-weight: 200
    margin-bottom: 40px
    @media (max-width: 650px)
      font-size: 1.6em

.team-slider
  font-size: 1.5em
  line-height: 1.5
  max-width: 600px
  margin: 0 auto



@media only screen and (min-width: 768px)
  .location-inner
    max-width: 1200px
    margin: 0 auto
    width: 100%


  .image-location
    flex: 1
    position: relative





@media only screen and (max-width: 768px)
  .location
    display: block
    height: auto

    .image-location
      width: 100%
      height: 180px
      background-position: center

  iframe
    height: 300px



.back-top
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  position: relative
  width: 200px
  margin: 10px auto
  text-decoration: none
  &:hover
    #arrow
      top: -10px


  #arrow
    position: relative
    max-width: 20px
    top: 0
    transition: all 300ms ease

  p
    margin: 10px!important
    color: $body-font-color


@media only screen and (max-width: 768px)
  .location
    .text-location
      border: 10px solid #efefef
      position: relative
      left: 0
      top: 50%
      bottom: 0
      right: 0
      width: 100%



.standort-optik-akustik,
.standort-optik
  .softrow 
    display: flex
    flex-wrap: wrap
    .col-1 
      flex: 1 0 0
      margin: 10px
      min-width: 300px
      max-width: 100%
      @media (max-width: 480px)
        min-width: 100%
        margin: 10px 0
  .hoeren-sehen 
    .image-wrapper 
      height: 200px
      display: flex
      justify-content: center
      align-items: center
      i 
        font-size: 4rem
        color: white
    strong 
      font-size: 1em
      margin: 0
      font-weight: 200
      background: $button-orange
      display: block
      color: #fff
      padding: 5px
      position: relative
      z-index: 1
      margin-bottom: 20px
  .oeffnungszeiten
    .softrow
      margin-top: 20px
      .col-1 
        background: white
        padding: 20px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
  .terminvereinbarung 
    img 
      margin-top: 20px
  .h3, h3 
    font-size: 1.9rem
    line-height: 1.2
    text-transform: none
    margin: 0
    font-weight: 200
    @media (max-width: 650px)
      font-size: 1.6rem
  h2
    font-size: 2.2rem
    line-height: 1.2
    margin: 0
    @media (max-width: 650px)
      font-size: 1.6rem
  .button 
    margin-bottom: 0
  
.standort-optik 
  .softrow 
    display: flex
    .col-1 
      flex: 1 0 0
      margin: 10px auto
      min-width: 300px
      max-width: 50%
      @media (max-width: 699px)
        flex-basis: 100%
      @media (max-width: 480px)
        min-width: 100%
        margin: 10px 0