p
	margin: 20px 0 20px!important
	font-size: 18px
	line-height: 1.5
	letter-spacing: 0.01em
	font-family: 'Open Sans', sans-serif

h1, h2, h3, h4, h5, h6
	font-family: 'Montserrat', sans-serif

h1, h2
	text-transform: uppercase
	line-height: 1.2


h1
	margin-top: 0


h1
	font-size: $h1-size
	font-weight: $bold


h2
	font-size: $h2-size
	font-weight: $bold


h3
	font-size: $h1-size
	color: #3D3D3D
	font-weight: 500


h4
	font-size: $h4-size
	color: #3D3D3D


a
	color: $a-color


@media (max-width: 650px)
	h1, h2
		font-size: 1.6em


.small--heading
	font-size: 1.6rem