.slick-prev, .slick-next
	z-index: 9
.slick-next
	right: -15px
	&:before
		content: "→"!important
.slick-prev
	&:before
		content: "←"!important


.image-slider,
.image-slider-akustik


	.slick-list
		width: 100%
		margin: 0 auto

	.slick-slide img
		padding: 0
		object-fit: cover
		height: 400px
		object-position: center
	img.lupe
		position: absolute
		width: 40px
		height: 40px!important
		top: 0
		right: 0
		cursor: pointer
		padding: 10px
		background: #f5811f
		transition: all 100ms ease
	.slick-slide
		p
			font-size: 14px
		&:hover
			img.lupe
				padding: 7px



#news-ozeaneum
	button
		margin-top: 0
	.slick-track
		display: flex
		align-items: center
		.slider-container
			width: 100%
			height: 400px
			display: flex
			justify-content: center
			align-items: center
			margin: 0 20px
			@media (max-width: 768px)
					height: auto
			img, video
				height: 400px
				width: auto
				max-width: 100%
				@media (max-width: 768px)
					height: auto
	.slick-next
		right: -15px
		@media (max-width: 768px)
			right: 5px
		&:before
			content: "→"!important
	.slick-prev
		@media (max-width: 768px)
			left: -5px
					

