/* montserrat-300 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/montserrat-v24-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/montserrat-v24-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/montserrat-v24-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/montserrat-v24-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/montserrat-v24-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/montserrat-v24-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  
  /* montserrat-regular - latin */
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/montserrat-v24-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/montserrat-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/montserrat-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/montserrat-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/montserrat-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/montserrat-v24-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  
  /* montserrat-700 - latin */
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/montserrat-v24-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/montserrat-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/montserrat-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/montserrat-v24-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/montserrat-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/montserrat-v24-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* open-sans-300 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/open-sans-v29-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v29-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v29-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  
  /* open-sans-regular - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/open-sans-v29-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v29-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

/* open-sans-600 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/open-sans-v35-latin-600.eot'); /* IE9 Compat Modes */
	src: url('../fonts/open-sans-v35-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v35-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('../fonts/open-sans-v35-latin-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('../fonts/open-sans-v35-latin-600.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('../fonts/open-sans-v35-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
  
  /* open-sans-700 - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/open-sans-v29-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v29-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v29-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* poppins-300 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  
  /* poppins-700 - latin */
  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  