#forsa-header
	img
		max-height: 700px
		object-fit: cover
		object-position: bottom center
.centered__content
	text-align: center
.grey__row
	background: $grey
	.soft-row
		@media (min-width: 840px)
			display: flex
			flex-wrap: wrap
			justify-content: space-between
		.col-2
			flex: 1 0 65%
			order: 1
			
		.col-1
			flex: 1 0 25%
			order: 2

.checklist.forsalist
	margin: 2rem 0
	li
		margin-left: 0
		list-style-type: none
		font-weight: bold
		margin-bottom: 10px
		padding: 0
		padding-left: 30px
		position: relative
		&:before
			content: ""
			position: absolute
			left: 0
			top: 2px
			width: 18px
			min-width: 18px
			height: 18px
			background: url('../img/hoerakustik/icn-check.svg') left center no-repeat
			background-size: contain

.stoerer-rot
	background: #AD2421
	border-radius: 50%
	display: flex
	align-items: center
	justify-content: center
	padding: 20px
	width: 180px
	height: 180px
	min-width: 180px
	margin: 0 0 20px auto
	@media (min-width: 1040px)
		margin: 20px
		padding: 20px
		width: 220px
		height: 220px
		min-width: 220px
	p
		color: white
		text-align: center
		font-weight: 400
		line-height: 1.4
		font-size: 18px
		@media (min-width: 1040px)
			font-size: 23px
.hoergeraete__row
	margin-top: 40px
	@media (min-width: 740px)
		display: flex
		justify-content: space-between
	.col
		margin: 10px
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		img
			max-width: 300px
