#sonnenbrillen-header
  // background: $grey
  .headerImage
    // width: 100%
    // height: 50vw
    // min-height: 300px
    // max-height: 500px
    background: url(../img/headerSonnenbrille.jpg)  center 35% no-repeat
    background-size: cover

  .headerText
    max-width: 750px
    margin: 30px auto
    text-align: center
    padding: 30px
#so-tool
  margin: 0 auto
  max-width: 1000px
  height: 150vw
  position: relative
  max-height: 900px
  iframe
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%

#sonnenbrille
  margin-top: 30px
  padding: 20px
  .content
    max-width: 750px
    margin: 0 auto
    text-align: center

.sonnenbrillen-content
  display: flex
  justify-content: center
  flex-wrap: wrap
  .col
      flex: 1 0 0
      min-width: 300px
      max-width: 100%
      margin: 30px 10px
      text-align: center
      .inner
          max-width: 750px
          margin: 0 auto
          min-width: 250px
          p
              color: black!important
          #sonnenbrillen-schutz
              background: url(../img/sonnenbrillen/icon-sun.svg) center no-repeat
                color: #F5821F
              background-size: auto
              height: 250px
          h3
              margin: 0
              font-size: 1.6em
              line-height: 1.4
              background: white
              padding: 5px
          p
              // background: #efefef
              padding: 20px
              margin: 0!important


  
          


  

