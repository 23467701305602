.headerImage
	width: 100%
	height: 50vw
	min-height: 300px
	max-height: 500px
	@media (min-width: 1800px)
		max-height: 800px
	background: url(../img/headerGleitsicht.jpg)	center 40% no-repeat
	background-size: cover
#gleitsicht-header
	background: $grey

	.headerText
		max-width: 750px
		margin: 30px auto
		text-align: center
		padding: 30px

.stichpunkt
	font-weight: 900
	font-size: 1rem



					


	

