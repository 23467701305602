#zitat
  margin: 60px auto
  background: $grey
  padding: 90px 30px
  .zitat
    max-width: 500px
    margin: 0 auto
    background: #333333
    padding: 20px
    position: relative
    &:before
      content: ""
      background: white url(../img/mitarbeiter/Zitat-8.png) center no-repeat
      // background-size: contain
      position: absolute
      width: 40px
      height: 40px
      border-radius: 50%
      left: 50%
      top: 0%
      transform: translate(-50%,-50%)
      text-align: center
      border: 2px solid $button-orange
    .soft-row
      display: flex
      justify-content: center
      flex-wrap: no-wrap
      align-items: center


      .image
        width: 100px
        height: 100px
        min-width: 100px
        min-height: 100px
        border-radius: 50%
        background: white
        border: 1px solid white
        margin-right: 20px
        margin: 0 auto
        &.zimmermann
          background: url(../img/mitarbeiter/jan-zimmermann.jpg) center
          background-size: cover
        &.alexa_zimmermann
          background: url(../img/mitarbeiter/alexa-zimmermann.jpg) center
          background-size: cover
        &.matthes
          background: url(../img/mitarbeiter/elisa-matthes.jpg) center
          background-size: cover
        &.moltzahn
          background: url(../img/mitarbeiter/tina-moltzahn.jpg) center
          background-size: cover
        &.herzig
          background: url(../img/mitarbeiter/angela-herzig.jpg) center
          background-size: cover
        &.schmidt
          background: url(../img/mitarbeiter/anne-schmidt.jpg) center
          background-size: cover
        &.korn
          background: url(../img/mitarbeiter/carola-korn.jpg) center
          background-size: cover
        &.urgast
          background: url(../img/mitarbeiter/maren-urgast.jpg) center
          background-size: cover
        &.odebrecht
          background: url(../img/mitarbeiter/claudia-odebrecht.jpg) center
          background-size: cover
        &.schur
          background: url(../img/mitarbeiter/stefan-schur.jpg) center
          background-size: cover
        &.senfft
          background: url(../img/mitarbeiter/jana-senfft-mueller.jpg) center
          background-size: cover
        &.mockschan
          background: url(../img/mitarbeiter/andrea-mockschan.jpg) center
          background-size: cover
        &.klockow
          background: url(../img/mitarbeiter/karsten-klockow.jpg) center
          background-size: cover


      .textWrapper
        max-width: 200px
        margin: 0 auto
        p
          color: white
          font-size: 0.925em
      @media (max-width: 650px)
        padding-top: 40px
        display: block
        text-align: center
        .image
          margin: 0 auto
