.standorteContainer
	display: flex
	flex-wrap: wrap
	justify-content: center
	.standort
		width: 400px
		max-width: 100%
		margin: 10px
		display: flex
		justify-content: center
		align-items: flex-end
		min-height: 150px
		max-height: 350px
		height: 20vw
		background-size: cover
		background-position: center
		transition: all 300ms ease
		h3
			background: $theme-color
			transition: all 300ms ease
			color: white
			font-size: 1em
			width: 100%
			padding: 5px 20px
		&:hover
			h3
				padding: 10px 20px
	.standorte
		width: 400px
		max-width: 100%
		margin: 10px
		display: block
		// @media (max-width: 650px)
		//	 width: auto
		//	 flex: 1 0 0
		//	 min-width: 150px
		.bild
			transition: all 300ms ease
			position: relative
			border-bottom: 2px solid #cdcdcd
			z-index: 0
	
			.imageMitarbeiter
				height: 0
				padding-bottom: 70%
				width: auto
				position: relative 
				background-size: cover!important
				display: block
			.bild-name
				background: #333333
				width: 100%
				display: flex
				align-items: center
				justify-content: center
				flex-direction: column
				bottom: 0
				left: 0
				padding: 20px
				p
					color: white 
					padding: 0
					margin: 0!important
					font-size: 0.85em
					line-height: 1.4
				a 
					color: white!important
					&:hover
							color: $button-orange!important
		
				strong
					font-size: 1.2em
		 
.vr
	width: 100vw
	height: 100vh
.icn-360
	width: 40px
	height: 40px
	border: 2px solid $theme-color
	display: block
	border-radius: 50%
	text-align: center
	margin: 0 auto
	position: relative
	transition: 300ms ease
	span
		border: 1px solid #bbb
		border-radius: 50%
		display: block
		position: absolute
		transition: 300ms ease
		&:first-child
			width: 50%
			height: 100%
			left: 50%
			top: 0
			transform: translateX(-50%) rotateY(0)
			&:after
				content: ""
				position: absolute
				width: 0
				height: 0
				border-top: 6px solid #bbb
				border-left: 3px solid transparent
				border-right: 3px solid transparent
				top: 50%
				right: -3px
				transform: translateY(-50%)


		&:last-child
			width: 100%
			height: 50%
			top: 50%
			left: 0
			transform: translateY(-50%) rotateX(0)
			&:after
				content: ""
				position: absolute
				width: 0
				height: 0
				border-left: 6px solid #bbb
				border-top: 3px solid transparent
				border-bottom: 3px solid transparent
				bottom: -3px
				left: 50%
				transform: translateX(-50%)
	&:hover
		transform: rotate(180deg)
		span
			&:last-child
				transform: translateY(-50%) rotateX(180deg)
			&:first-child
				transform: translateX(-50%) rotateY(180deg)

			

.download
	margin-top: 3rem
	width: 100%
	max-width: 400px
	margin-left: auto
	margin-right: auto
	padding: 40px 20px
	background: linear-gradient(to top, $grey, lighten($grey, 3%))
	border: 1px solid darken($grey, 5%)
	border-top-color: lighten($grey, 10%)

	border-radius: 10px
	position: relative
	box-shadow: 0 2px 5px rgba(0,0,0,0.01)
	&:before, &:after
		content: ""
		display: block
		position: absolute
		z-index: -1
		width: 47%
		border-radius: inherit
		bottom: -0
		height: 80%
		background: rgba(white, 0)
		box-shadow: 0 0 20px rgba(0,0,0,0.35)

	&:before
		left: 10px
		transform-origin: bottom right
		transform: rotate(-2deg) translateY(-7px)
	&:after
		right: 10px
		transform-origin: bottom left
		transform: rotate(2deg) translateY(-7px)
