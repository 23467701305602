.parallax__container
	position: relative
	
	img
		width: 100%
		height: 100%
		object-fit: contain
		background-position: bottom
		max-width: 600px
		margin: 0 auto
		display: block
#start-optometrie
	position: relative
	display: grid
	width: 100%
	max-width: 1600px
	margin: 0 auto
	padding: 80px 20px
	gap: 40px 0
	grid-template-columns: 1fr
	grid-template-areas: "heading" "left" "center" "right"
	h2
		font-size: 35px
	@media (min-width: 1000px)
		h2
			font-size: 45px
		grid-template-columns: 1fr 1fr 1fr
		grid-template-areas: "heading . ." "left . right"
	.heading__container
		grid-area: heading
		position: relative
	
	.parallax__container
		grid-area: center
		@media (min-width: 1000px)

			grid-column: 1 / -1
			grid-row: 1 / -1
	.left__container
		grid-area: left
		position: relative
	.right__container
		grid-area: right
		position: relative

	.using-image
		z-index: 2
		.row-bg
			@media (max-width: 999px)
				background-image: none !important
				background-color: #ffffff

			// background-attachment: fixed
			position: absolute
			z-index: 1
			top: 0
			left: 0
			background-size: cover
			width: 100%
			height: 100%

	.vc_col-sm-4.hidden-desktop
		img
			height: auto
		@media (min-width: 1000px)
			display: none

	.vc_col-sm-4.vc_col-lg-offset-4
		.vc_column-inner
			@media (min-width: 1000px)
				padding-top: 210px

	.span_12
		position: relative
		z-index: 10
		max-width: 1366px
		margin: 0 auto
		padding: 6vw 30px
		width: 100%
		display: flex
		flex-wrap: wrap
		float: none
		justify-content: center

		.headline
			font-size: 20px
			line-height: 27px
			color: #F5821F
			font-weight: 400
			letter-spacing: 0
			padding-bottom: 0
			margin-bottom: 10px !important

		h2
			font-family: 'Open Sans', sans-serif
			color: #000000
			font-weight: 600
			font-size: 28px
			line-height: 38px
			margin-bottom: 25px
			@media (min-width: 1000px)
				font-size: 45px
				line-height: 61px
				margin-bottom: 30px
			text-transform: none

		.white-text
			background-color: #F5821F
			padding: 20px 30px 30px

			p
				color: #ffffff
				font-size: 18px
				line-height: 28px
				font-weight: 400
				letter-spacing: 0

				strong
					font-weight: 700
					font-size: 18px
					line-height: 28px
					letter-spacing: 0

			.btn_orange
				background-color: #ffffff !important
				color: #000000 !important

		.bg-orange
			background-image: url(../img/bg-orange.svg)
			background-size: cover
			background-repeat: no-repeat
			background-position: left center
			padding: 2px 0 2px 40px
			margin-bottom: 33px

			p
				font-size: 18px
				position: relative
				line-height: 28px
				font-weight: 700
				color: #fff
				padding-left: 45px

				&:before
					content: ""
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25' viewBox='0 0 29.25 29.25'%3E%3Cpath id='Icon_ionic-md-checkmark-circle-outline' data-name='Icon ionic-md-checkmark-circle-outline' d='M12,15.221,9.956,17.269l6.581,6.581L31.163,9.225,29.115,7.178l-12.578,12.5ZM29.7,18A11.65,11.65,0,1,1,21.218,6.739l2.267-2.267A13.6,13.6,0,0,0,18,3.375,14.625,14.625,0,1,0,32.625,18Z' transform='translate(-3.375 -3.375)' fill='%23fff'/%3E%3C/svg%3E%0A")
					width: 29px
					height: 29px
					display: inline-block
					background-size: cover
					background-repeat: no-repeat
					position: absolute
					left: 0
					top: 1px
					margin-right: 20px

.content__box
	// margin-top: 40px
	padding: 20px 20px 40px
	*
		font-size: 18px
	&.orange
		*
			color: white
		.btn_orange
			background-color: #ffffff !important
			color: #000000 !important
ul.checklist
	li
		list-style-type: none
		position: relative
		padding: 20px
		display: flex
		align-items: flex-start
		margin-bottom: 20px
		&:before
			content: ""
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25' viewBox='0 0 29.25 29.25'%3E%3Cpath id='Icon_ionic-md-checkmark-circle-outline' data-name='Icon ionic-md-checkmark-circle-outline' d='M12,15.221,9.956,17.269l6.581,6.581L31.163,9.225,29.115,7.178l-12.578,12.5ZM29.7,18A11.65,11.65,0,1,1,21.218,6.739l2.267-2.267A13.6,13.6,0,0,0,18,3.375,14.625,14.625,0,1,0,32.625,18Z' transform='translate(-3.375 -3.375)' fill='%23fff'/%3E%3C/svg%3E%0A")
			width: 29px
			min-width: 29px
			height: 29px
			display: block
			background-size: cover
			background-repeat: no-repeat
			position: realtiove
			margin-right: 20px
		&.bg-orange
			background: $theme-color
			color: white
			&:after
				content: ""
				position: absolute
				left: 0
				top: 50%
				transform-origin: center
				background: inherit
				width: 20px
				height: 20px
				transform: translate(-50%, -50%) rotate(45deg)
		p
			font-size: 18px
			margin: 0!important
			font-weight: bold