.threesixty {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  .soft--row {
    .col-1 {
        .sprite {
          margin-left: auto;
          margin-right: auto;
          background-image: url("../img/hoerakustik/watch_woman_model0.jpg");
        }
        //.controllers{
        //  &.bottom {
        //    position: absolute;
        //    left: 0;
        //    bottom: 0;
        //  }
        //}
    }
  }
}
