#editorial,
#hoerakustik
	background-color: #FDF1D5
	.content

		.small--heading
			font-size: 20px
			line-height: 27px
			color: #000000
			font-weight: 400
			letter-spacing: 0
			padding-bottom: 0
			margin-bottom: 10px !important
			font-family: 'Montserrat', sans-serif


		h1
			font-size: 20px
			line-height: 27px
			text-transform: none
			font-weight: 400
			color: #000000

		h2
			color: #F5821F
			font-weight: 600
			font-size: 28px
			line-height: 38px
			@media (min-width: 1000px)
				font-size: 45px
				line-height: 55px

		p
			color: #000000
			font-weight: 400
			font-size: 18px
			line-height: 28px

			strong
				color: #000000
				font-size: 18px
				line-height: 28px

#marken,
#optiker,
#akustiker,
#news,
#online-tools
	.small--heading
		font-size: 20px
		line-height: 27px
		color: #F5821F
		font-weight: 400
		letter-spacing: 0
		padding-bottom: 0
		margin-bottom: 10px !important

	h2
		// font-family: 'Open Sans', sans-serif
		color: #000000
		font-weight: 600
		font-size: 28px
		line-height: 38px
		margin-bottom: 25px
		@media (min-width: 1000px)
			font-size: 45px
			line-height: 61px
			margin-bottom: 50px
		text-transform: none !important


#online-tools
	h2
		color: #ffffff

#optiker
	.slider-auge
		.ohr
			display: none

#akustiker
	.slider-ohr
		width: 100%
		max-width: 1600px
		margin: 0 auto
		.auge
			display: none

#optiker,
#akustiker
	.section-inner
		padding: 6vw 30px 0

	.slider-auge,
	.slider-ohr

		.mitarbeiter.hasContent
			position: relative

			.hover
				position: absolute
				bottom: 0
				left: 0
				width: 100%
				height: auto
				min-height: 85px
				color: #fff
				opacity: 1
				display: flex
				justify-content: flex-start
				align-items: center
				background: #333
				
				padding: 0
				// text-align: center
				/* transition effect. not necessary */
				transition: opacity .2s, visibility .2s


				// &:after
				// 	content: ''
				// 	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='13.501' viewBox='0 0 20.243 13.501'%3E%3Cpath id='Icon_ionic-ios-arrow-round-forward' data-name='Icon ionic-ios-arrow-round-forward' d='M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z' transform='translate(-7.875 -11.252)' fill='%23fff'/%3E%3C/svg%3E%0A")
				// 	width: 22px
				// 	height: 14px
				// 	position: absolute
				// 	z-index: 10
				// 	right: 25px
				// 	bottom: 35px
				// 	background-size: cover
				// 	background-repeat: no-repeat
				// 	display: block

				p
					color: #ffffff
					z-index: 10
					padding: 10px
					margin: 0!important
					transition: all 300ms ease
					text-align: center
					width: 100%

			&:hover
				cursor: pointer
				.hover
					visibility: visible
					opacity: 1
					p
						padding: 30px 10px

#online-tools
	background: $dark-grey url('../img/bg_home.jpg')
	background-size: cover
	background-attachment: fixed
	background-position-x: right
	//background-position-y: -100px
	position: relative
	@media (max-width: 1000px)
		background: $dark-grey

	.section-inner
		max-width: 1320px

	p
		font-family: 'Open Sans', sans-serif
		max-width: 1100px
		margin: 20px auto !important
		font-weight: 400
		font-size: 18px
		line-height: 28px
		color: #ffffff
.slider-ohr
	margin-bottom: 6vw

.markenlogo__slider
	margin-bottom: 10px
	.slide
		display: flex
		align-items: stretch
		justify-content: center
		height: 100px
		padding: 10px
		img
			padding: 10px
			background: white
			width: 100%
			height: 100%
			object-fit: contain
			object-position: center
				// max-width: 200px
				// height: auto
				// width: 100%
				// object-fit: contain


.image__content-container
	@media (min-width: 1000px)
		display: grid
		align-items: center
		grid-template-columns: 1fr 1fr
	.image__content-container-content
		grid-column: 1 / -1
		grid-row: 1 / -1
		width: 100%
		max-width: 1600px
		margin: 0 auto
		padding: 60px 20px
		.inner__wrap
			@media (min-width: 1000px)

				width: calc(50% - 2rem)
	.image__content-container-image
		height: 100%
		grid-column: 2 / -1
		grid-row: 1 / -1
		img
			width: 100%
			height: 100%
			display: block
			object-fit: cover
		//

.orange__heading
	color: $a-color
	font-size: 28px
	line-height: 1.2
	@media (min-width: 1000px)
		font-size: 45px

.m0
	margin: 0!important

.section__layout
	padding: 0

.subtitle
	color: white
	display: block
	background: #333
	margin: 0!important
	padding: 10px
	text-align: center
	font-size: 0.825em
	// position: absolute
	// bottom: 0
	width: 100%
img
	display: block

.online-tools
	display: grid
	grid-template-columns: repeat(1, 1fr)
	@media (min-width: 768px)
		grid-template-columns: repeat(3, 1fr)
	@media (min-width: 1000px)
		grid-template-columns: repeat(5, 1fr)
	.tool
		min-width: 0
		img
			max-width: 130px
			display: block
			margin: 0 auto

