$miyo-main: #F5821F
$miyo-side: #FCE4BA
$miyo-light: #fef4e3
$miyo-lightgrey: #EFEFEF
$miyo-grey:	#E0E0DF

	
#miyosmart
	position: relative
	h1
		font-size: 4.5rem
		max-width: 1000px
		span
			font-size: 3rem
			display: block
			text-transform: initial
			margin-bottom: 50px
	h2
		color: $miyo-main
		span
			font-size: 1em
			font-weight: 700
			text-transform: lowercase
	.white
		h2, p, strong, blockquote
			color: white
	blockquote
		position: relative
		font-style: italic
		padding-right: 50px
		&::before
			content: ""
			width: 200px
			height: 3px
			background: white
			position: absolute
			display: block
			left: -220px
			top: 7px
		&.white
			color: white
	section
		&:nth-child(3)
			margin-top: 70vh
			background: white
		&:nth-child(4)
			background: $miyo-light
			padding: 0
			.col--2
				padding: 8% 0 8% 30%
			.col--1
				padding-right: 8%
		&:nth-child(5)
			background: $miyo-lightgrey
		&:nth-child(6)
			background: $miyo-main
		&:nth-child(7)
			background: $miyo-light
		&:nth-child(8)
			background: white
			padding: 0
			.col--2
				padding: 8% 0 8% 30%
			.col--1
				padding-right: 8%
		&:nth-child(9)
			background: $miyo-grey
		&:nth-child(10)
			background: white
	#line
		position: fixed
		bottom: 38vh
		left: 25%
		background: $miyo-main
		height: 400px
		width: 5px
		z-index: 2
	header
		background: #FCE4BA
		min-height: 70vh
		width: 100%
		position: fixed
		top: 0
		z-index: -5
		.row--1
			position: absolute
			bottom: 100px
			padding: 8% 8% 0 30%
		.head--img
			position: fixed
			width: 100%
			height: 80vh
			top: 0
			left: 0
			right: 0
			bottom: 0
			padding: 100px 100px 400px 100px
			z-index: -1
			max-width: 1600px
			margin: 0 auto
			.img
				background: url('../img/miyosmart/miyo-img1.jpg') no-repeat
				background-size: cover
				background-position: center center
				width: 100%
				height: 100%
				box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2)
	#overlay-audio
		.popupContent
			width: 70vw
	.video-popup
		cursor: pointer
		position: relative
		&::after
			content: ""
			position: absolute
			height: 70px
			width: 70px
			background: url(../img/miyosmart/i-play.svg) no-repeat
			background-size: cover
			top: 50%
			left: 50%
			transform: translateX(-50%) translateY(-50%)
	.front
		z-index: 1
	.back
		z-index: 3
		position: relative
	.row--1, .row--2, .row--3
		display: flex
		padding: 8% 8% 8% 30%
		.col--1
			flex: 1 0 0
		.col--2
			flex: 2 0 0
			margin-right: 50px
	.row--full
		position: relative
		img
			display: block
		.stoerer
			position: absolute
			top: 8%
			right: 8%
			height: 200px
			width: 200px
			@media ( max-width: 650px )
				width: 140px
				height: 140px
	.row--4
		display: flex
		.col--1
			flex: 1 0 0
			padding: 8% 8% 8% 0
			&.zitat
				position: relative
				padding: 0 0 0 30%
				align-self: flex-end
				@media ( max-width: 980px )
					align-self: initial
				img
					display: block
					height: auto
					width: 100%
					margin-left: auto
					&.hoya
						position: absolute
						height: auto
						width: 15vw
						bottom: -3.3vw
						left: 18vw
						z-index: 9
						@media ( max-width: 980px )
							width: 200px
							right: 10vw
							left: initial
						@media ( max-width: 630px )
							width: 30vw
							right: 20px
							left: initial
				blockquote
					position: absolute
					bottom: 20px
					right: 20px
					max-width: 300px
	.cta
		display: inline-block
		margin-top: 20px
		padding: 15px 40px
		background: $miyo-main
		border-radius: 30px
		color: white
		font-weight: 700
		text-transform: uppercase
		opacity: 1
		transition: opacity .2s ease-in-out
		&:hover
			opacity: 0.8
	.row--1
		flex-direction: column
		align-items: center
		.softrow
			margin-top: 40px
			display: flex
			.col--1
				padding: 50px
				text-align: center
			@media ( max-width: 650px )
				flex-direction: column
				.col--1
					padding: 20px
		&.row--grid
			position: initial
			.softrow
				flex-wrap: wrap
				.col--1
					min-width: 50%
					text-align: left
					position: relative
					padding: 0
					display: flex
					align-items: center
					margin-bottom: 30px
					img
						max-height: 100px
						width: 100px
						margin-right: 30px
					strong
						position: absolute
						left: 120px
	.background--img1
		background: url('../img/miyosmart/miyo-img2.jpg') no-repeat
		background-size: cover
		background-position: center center
	.background--img2
		background: url('../img/miyosmart/miyo-vid.jpg') no-repeat
		background-size: cover
		background-position: center center
		margin: 8% 0 8% 0
	img
		&.icons
			max-height: 120px
			width: auto
		&.circles-img
			padding: 0 50px 30px 0
	@media ( max-width: 1530px )
		h1
			font-size: 4vw
			max-width: 1000px
		header
			.row--1
				.head--img
					padding: 100px 100px 370px 100px
					height: 100%
	@media ( max-width: 1370px )
		header
			height: 100vh
			.row--1
				h1
					text-shadow: 0 0 6px #f1d5ae
				.head--img
					padding: 100px 100px 230px 100px
					height: 100%
		section
			&:nth-child(3)
				margin-top: 100vh
				background: white
			&:nth-child(4)
				background: $miyo-light
				padding: 0
				.col--2
					padding: 8% 0 8% 30%
				.col--1
					padding-right: 8%
		#line
			bottom: 110px
			height: 300px
	@media ( max-width: 980px )
		#line
			bottom: 38vh
			left: 5%
			height: 400px
		header
			min-height: 70vh
			height: 70vh
			position: relative
			top: 0
			z-index: -5
			.row--1
				position: absolute
				bottom: 100px
				padding: 8% 8% 0 10%
			.head--img
				position: relative
				width: 100%
				height: 200px !important
				padding: 0 0 50px 0 !important
				max-width: 1600px
				margin: 0 auto
		.row--1, .row--2, .row--3, .row--4
			flex-direction: column
			padding: 8% 8% 8% 10%
			.background--img1, .background--img2
				min-height: 300px!important
		section
			&:nth-child(3)
				margin-top: 0
				background: white
			&:nth-child(4)
				background: $miyo-light
				padding: 0
				.col--2
					padding: 8% 0 8% 10%
				.col--1
						padding-right: 8%
			&:nth-child(6)
				.col--1
					&:first-of-type
						margin-bottom: 60px
			&:nth-child(8)
				padding: 0
				.col--2
					padding: 8% 0 8% 10%
				.col--1
					padding-right: 0
					margin: 0
		.row--4
			flex-direction: column-reverse
			padding: 8% 8% 0 8%
			.col--1
				padding: 0 0 8% 0
				&.zitat
					padding: 0
					img
						height: 320px
						width: auto
						margin: 0 auto 0 0
					blockquote
						max-width: 270px
						left: 10px
						bottom: 10px
						