#news
  .slick-dots
    display: none !important

  .section-inner
    max-width: 1280px

  h2
    margin-bottom: 60px

  .news-content
    .slick-list
      padding: 0 !important

    .inner
      @media (min-width: 1000px)
        display: flex
        align-items: center
        gap: 0 100px
      .preview-img
        flex: 0 0 50%

      .text
        .pagination
          position: relative
          max-width: 100px

          .pagingInfoNews
            font-family: 'Open Sans', sans-serif
            font-size: 18px
            line-height: 28px
            letter-spacing: 0
            color: #5A6749
            font-weight: 400

          .slick-prev
            left: 0
            transform: translateY(-50%)
            margin-top: unset
            margin-bottom: 0

            &:before
              content: '' !important
              background-image: url("data:image/svg+xml,%3Csvg id='Page-1' xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Icon-Set' transform='translate(0)'%3E%3Cpath id='arrow-right-circle' d='M316.277,1102.12l5.656-5.66a1,1,0,0,1,1.414,1.42l-4.121,4.12h10.586a1,1,0,0,1,0,2H319.226l4.121,4.12a1,1,0,1,1-1.414,1.42l-5.656-5.66a1.619,1.619,0,0,1,0-1.76Z' transform='translate(-316 -1096.167)' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A")
              width: 15px
              height: 14px
              background-color: transparent
              display: block
              background-repeat: no-repeat
              background-size: cover

          .slick-next
            right: 0
            transform: translateY(-50%)
            margin-top: unset
            margin-bottom: 0

            &:before
              content: '' !important
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.812' height='13.665' viewBox='0 0 14.812 13.665'%3E%3Cg id='Page-1' transform='translate(-8 -9.167)'%3E%3Cg id='Icon-Set' transform='translate(-308 -1087)'%3E%3Cpath id='arrow-right-circle' d='M330.535,1102.12l-5.656-5.66a1,1,0,0,0-1.414,1.42l4.121,4.12H317a1,1,0,0,0,0,2h10.586l-4.121,4.12a1,1,0,0,0,1.414,1.42l5.656-5.66a1.619,1.619,0,0,0,0-1.76Z' fill='%235a6749' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
              width: 15px
              height: 14px
              background-color: transparent
              display: block
              background-repeat: no-repeat
              background-size: cover

      p, ul
        color: black !important

      ul
        text-align: left
        max-width: 90%

        li
          margin-left: 40px

      .soft-row
        align-items: center
        background: #F5821F
        position: relative
        justify-content: flex-start
        min-height: 56px

        .col-2
          flex: 2 0 0
          text-align: right
          max-width: calc(100% - 90px)

        .col-1
          background: $dark-grey
          border-radius: 50%
          height: 70px
          width: 70px
          display: flex
          justify-content: center
          align-items: center
          position: absolute
          flex: 1 0 0
          right: 10px
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
            top: -20px

          strong
            font-size: 1.2rem
            color: white
            max-width: 90%
            line-height: 1.1

          span
            font-size: 0.8rem


      #news-1
        img
          object-fit: cover
          height: 100%
          object-position: top right
          display: block

      #news-2
        background: url(../img/news/golfturnier.jpg) center no-repeat
        background-size: cover

      #news-3
        background-color: #0c1754

        img
          object-fit: cover
          height: 100%
          display: block

      #news-4
        background: url(../img/news/Team.jpg) center no-repeat
        background-size: cover

      #news-5
        background: url(../img/news/BH-HST_MSM_Isabelle.jpg) top no-repeat
        background-size: cover

      h3
        margin: 0 0 25px
        @media (max-width: 999px)
          margin-top: 25px
        text-align: left
        font-size: 35px
        font-weight: 600
        line-height: 47px
        color: #F5821F
        font-family: 'Open Sans', sans-serif

      h4
        font-size: 1em
        margin: 0
        font-weight: 200
        background: $a-color
        display: block
        color: white
        padding: 5px
        position: relative
        z-index: 1

      .btn_orange
        margin-top: 0
        margin-bottom: 25px

      p
        font-family: 'Open Sans', sans-serif
        padding: 0
        color: #5A6749 !important
        font-size: 18px
        line-height: 28px
        text-align: left
        margin: 0 !important
        font-weight: 400

        strong
          font-family: 'Open Sans', sans-serif
          font-size: 18px
          line-height: 28px
          font-weight: 700

#news-container
  .water--content
    background: url(../img/hausmesse-meer.jpg) bottom center no-repeat
    background-size: cover
    padding: 20px

    h3
      background: none !important
      font-weight: bold !important

    .termine--container
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin-top: 20px

      .termin
        width: 200px
        margin: 10px
        display: flex
        flex-direction: column
        align-items: center

        &--image
          background: white
          display: flex
          align-items: center
          justify-content: center
          padding: 20px
          width: 160px
          height: 130px
          box-shadow: 0 0 10px rgba(black, 0.2)

          img
            display: block
            height: auto
            max-height: 100%

        &--datum
          background: $theme-color
          color: white
          font-weight: bold
          font-size: 1.2rem
          padding: 10px 20px
          width: 100%

        &--ort
          width: 100%
          font-size: 1.2rem
          padding: 10px 20px
          background: white


  .muster--content
    background: url(../img/muster-hausmesse.jpg) center no-repeat
    background-size: cover
    padding: 20px

    .stoerer
      width: 250px
      height: 250px

    small
      display: block
      font-size: 0.825rem
      max-width: 600px
      margin: 0 auto
      line-height: 1.4 !important

    h2, h1, h3
      background: none !important
      padding: 0 !important

    h2
      color: $theme-color !important
      font-size: 1.4rem !important
      font-weight: bold !important
      margin-bottom: 10px !important

    h3
      font-size: 1.2rem !important
      margin-top: 20px !important

  .news--image
    img
      display: block

  .section-inner
    padding: 0px !important
    margin: 0 auto !important

  .news-content
    #news-1
      background: url(../img/news/ozeaneum_neu.jpg) top no-repeat
      background-size: cover
      height: 30vw
      max-height: 500px
      min-height: 200px

    #news-2
      background: url(../img/news/golfturnier.jpg) center no-repeat
      background-size: cover
      height: 30vw
      max-height: 500px
      min-height: 200px

    h3
      margin: 0
      font-size: 1.6em
      line-height: 1.4
      background: #efefef
      padding: 5px

    h4
      font-size: 1em
      margin: 0
      font-weight: 200
      background: $a-color
      display: block
      color: white
      padding: 5px

    p
      padding: 20px
      margin: 0 !important

  hr
    margin: 30px auto
    border: none
    height: 1px
    background: #999


	  