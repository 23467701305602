.button, button
  margin-bottom: 50px
  font-weight: 700
  padding: 10px 60px 10px 60px
  transition: all 300ms ease
  cursor: pointer
  color: white
  border-radius: 5px
  border: none
  outline: none
  text-decoration: none
  background: $button-orange
  display: inline-block
  &:hover
    background: darken($button-orange, 10%)
  a
    color: white
    text-decoration: none

.termin-button
  position: absolute
  top: 80px
  right: 0
  z-index: 9999
  width: 160px
  transition: all 300ms ease
  &:hover
    width: 200px
  a
    background: $button-orange
    color: #ffffff
    text-decoration: none
    text-transform: uppercase
    font-weight: $bold
    font-size: 13px
    background-position: center left
    transition: all 300ms ease
    position: relative
    display: block
    line-height: 48px
    text-align: center
    &:before
      content: ""
      position: absolute
      border: 24px solid $button-orange
      border-left: 19px solid transparent 
      left: -42px
      transition: all 300ms ease
      top: 0
    &:hover
      background: darken($button-orange, 10%)

      &:before
        border: 24px solid darken($button-orange, 10%)
        border-left: 19px solid transparent
.events-button
  position: absolute
  top: 140px
  right: 0
  z-index: 9999
  transition: all 300ms ease
  width: 90px
  background: $button-orange
  &:hover
    background: darken($button-orange, 10%)
    width: 200px
    #event-dropdown
      
      position: absolute
      height: 300%
      left: -40px
    &:before
      border: 24px solid darken($button-orange, 10%)
      border-left: 19px solid transparent
  #event-dropdown
    width: 240px
    height: 0
    position: absolute
    overflow: hidden
    left: -40px
    transition: all 300ms ease
    li
      list-style: none
      margin: 0
      a
        border-bottom: 1px solid #efefef
        background: white
        padding: 10px
        color: $body-font-color
        transition: all 300ms ease
        width: 100%
        height: 100%
        display: block
        &:hover
          border-bottom: 1px solid transparent
          background: $button-orange
          color: white
    
  .sticky
    color: #ffffff
    text-decoration: none
    text-transform: uppercase
    font-weight: $bold
    font-size: 13px
    background-position: center left
    transition: all 300ms ease
    position: relative
    display: block
    line-height: 48px
    transition: all 300ms ease
    text-align: center
    margin-right: 24px

  &:before
    content: ""
    position: absolute
    border: 24px solid $button-orange
    border-left: 19px solid transparent 
    left: -42px
    transition: all 300ms ease
    top: 0
      
@media (max-width: 768px)
  .termin-button
    position: fixed
    bottom: 0px
    width: 100%
    left: 0
    top: auto
    &:hover
      width: 100%
    a
      text-align: center 
      width: 100%
      background: $button-orange
      display: block
      padding: 10px 0
      line-height: 1.5
  .events-button
    position: fixed
    bottom: 0px
    width: 50%
    right: 0
    top: auto
    &:hover
      width: 50%
      
    &:before
      content: none
    #event-dropdown
      display: none
    .sticky
      text-align: center 
      width: 100%
      display: block
      padding: 10px 0
      line-height: 1.5
.btn_orange
    background: $theme-color
    cursor: pointer
    color: white
    padding: 10px 20px
    border-radius: 100px
    font-weight: bold
    margin-top: 20px
    display: inline-block
    width: auto
    transition: all 300ms ease
    &:hover
        background: lighten($theme-color, 5%)
        padding: 10px 30px
.controllers
  .btn
    padding: 10px 30px
    margin: 20px 0
