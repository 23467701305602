.teamContainer
	display: flex
	flex-wrap: wrap
	justify-content: center
	.mitarbeiter
		&:not(.hasContent)
			.lupe
				display: none
		width: 280px
		max-width: 100%
		margin: 10px
		cursor: pointer


		@media (max-width: 650px)
			width: auto
			flex: 1 0 0
			min-width: 150px

		
		.lupe
			display: none
			position: absolute
			width: 40px
			height: 40px
			top: 0
			right: 0
			cursor: pointer
			padding: 10px
			background: #f5811f
			transition: all 100ms ease
		&.hasContent
			.lupe
				display: block
		.bild
			transition: all 300ms ease
			position: relative
			overflow: hidden
			border-bottom: 2px solid #cdcdcd
			transform: rotateY(0deg)
			z-index: 0
			.imageMitarbeiter
				height: 0
				padding-bottom: 100%
				width: auto
				position: relative
				left: 50%
				transform: translate(-50%)
				background-size: cover!important
			.bild-name
				background: #333333
				width: 100%
				display: flex
				align-items: center
				justify-content: center
				flex-direction: column
				position: absolute
				bottom: 0
				left: 0
				padding: 10px
				min-height: 68px
				transition: all 300ms ease
				p
					color: white 
					padding: 0
					margin: 0!important
					font-size: 0.85em
					line-height: 1.2
				strong
					font-size: 1.2em
		&:hover
			.lupe
				padding: 7px
			.bild-name
				padding: 30px 10px!important
			 


#overlay, #overlay-audio, #overlay-video
	display: none
	position: fixed
	width: 100%
	height: 100%
	background: rgba(0,0,0,0.5)
	left: 0
	top: 0
	z-index: 99999999999999999999999
	&.active
		display: block
	.popupContent
		position: fixed
		left: 30px
		top: 30px
		bottom: 30px
		right: 30px
		background: white
		z-index: 999999999999999
		display: flex
		flex-wrap: wrap
		display: none
		&.active
			display: flex
		.popupImage
			width: 50%
			height: 100%
		img
			position: absolute
			width: 40px
			height: 40px
			top: 0
			right: 14px
			cursor: pointer
			padding: 10px
			background: #f5811f
			transition: all 100ms ease
			&:hover
				padding: 7px
	 
		.popupText
			background: white
			z-index: 99999999999
			padding: 5%
			overflow-y: scroll
			overflow-x: hidden
			width: 50%
			position: relative
			h2 
				font-size: 2.5em
				text-transform: none
			h3
				font-size: 2em
				line-height: 1.2em
			p 
				strong
					font-size: 2em
			.telefon
				position: relative
				margin-left: 30px!important
				display: block
				&:before
					content: ''
					background: url('../img/mitarbeiter/Telefon.png') center no-repeat
					width: 24px
					height: 20px
					background-size: contain
					position: absolute
					left: -30px
					top: 50%
					transform: translateY(-50%)
				&:hover
					color: $theme-color
			.email
				position: relative
				margin-left: 30px!important
				display: block
				&:before
					content: ''
					background: url('../img/mitarbeiter/Email-8.png') center no-repeat
					background-size: contain
					width: 24px
					height: 24px
					position: absolute
					left: -30px
					top: 50%
					transform: translateY(-50%)
				&:hover
					color: $theme-color

				

		@media (max-width: 1000px)
			// display: block
			.popupImage, .popupText
				width: 100%
				height: 50%
#zitat
	display: none



//Audio Popup
#overlay-audio.audio-popup, #overlay-video.video-popup
	&.active
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
	.popupContent
			display: block!important
			position: relative
			left: auto
			top: auto
			bottom: auto
			right: auto
	.popupText
			overflow-y: hidden
			width: 100%
			text-align: center
			height: auto
			display: flex
			flex-direction: column
			align-items: center
			audio, h3 
				margin-top: 30px
			video 
				padding: 20px
				max-height: 60vh
				width: 100%
			p 
				margin-top: 10px
				font-size: 0.9rem
			a 
				word-break: break-word
				font-size: 0.9rem
				